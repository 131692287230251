import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../actions/Settings";
import $ from "jquery";
import RichTextEditor from 'react-rte';
import { EditorState, convertToRaw, ContentState, } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import "react-datepicker/dist/react-datepicker.css";

function EditEmailItem(props) {
  useEffect(() => {
    if (props.addCommonStatus === 1) {
      setTimeout(function () {
        props.setFieldValues("addCommonStatus", 0);
        props.setFieldValues("edit_email_modal_show", false);
        props.reset_common_info();
      }, 2000);
    }
  }, [props.addCommonStatus]);
  const editEmailItem = (event) => {
    event.preventDefault();
    if (validItemInfo()) {
      props.editEmailItem({
        id: props.email.id,
        email_tag: props.email.email_tag,
        email_content: props.email.email_content,
      });
    }
  };

  const validItemInfo = () => {
    let valid = true;
    let errors = props.addCommonErrors;
    if (!props.email.email_tag) {
      valid = false;
      errors.email_tag = "Cannot be Empty";
    } else {
      errors.email_tag = "";
    }
    if (!props.email.email_content) {
      valid = false;
      errors.email_content = "Cannot be Empty";
    } else {
      errors.email_content = "";
    }
    props.updateCommonSettingErrors(errors);
    return valid;
  };

  const [email, setEmail] = useState('')

  useEffect(() => {
    if (props.email.email_content) {
      let change = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.email.email_content)))
      setEmail(change);
    }
  }, [props.email.email_tag])

  const onChange = (value) => {
    setEmail(value);
    props.setFieldValues("email", {
      ...props.email,
      email_content: draftToHtml(convertToRaw(value.getCurrentContent())),
    });
  };

  return (
    <Modal
      show={props.show}
      className="modal bootstrap-dialog type-primary size-normal "
      role="dialog"
      aria-hidden="true"
      id="newcurrency"
      aria-labelledby="newcurrency"
      tabIndex={-1}
      size="lg"
      onHide={() => props.setFieldValues("edit_email_modal_show", false)}
    >
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div
              className="bootstrap-dialog-title"
              id="eada5b06-8dfc-4517-81e1-fc0db3d7a94c_title"
            >
              Information
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div className="bootstrap-dialog-body">
                <form
                  method="POST"
                  action="https://crm2.gbsc.geneasol-apps.info/dashboard/setting/1/emailtemplate/1"
                  acceptCharset="UTF-8"
                  className="form-horizontal"
                  id="form"
                >
                  <input name="_method" type="hidden" defaultValue="PUT" />
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="F3AwN3mnzZZGeheR5gRty4f9fSSfjanNtr2WbpNg"
                  />
                  {props.addCommonStatus ? (
                    <div
                      className={`alert ${props.addCommonStatus === 1
                        ? "alert-success"
                        : "alert-danger"
                        }`}
                      role="alert"
                    >
                      {props.addCommonStatus === 1
                        ? "Item updated successfully"
                        : props.message}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label
                      htmlFor="description"
                      className="control-label col-sm-2 required"
                    >
                      Email Tag
                    </label>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        required="required"
                        name="description"
                        type="text"
                        id="description"
                        value={props.email.email_tag}
                        onChange={(e) => {
                          props.setFieldValues("email", {
                            ...props.email,
                            email_tag: e.target.value,
                          });
                        }}
                      />
                      {props.addCommonErrors.email_tag &&
                        props.addCommonErrors.email_tag.length > 0 ? (
                        <span className="text-danger">
                          {props.addCommonErrors.email_tag}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="emailtemplate"
                      className="control-label col-sm-2 required"
                    >
                      Email Content
                    </label>
                    <div className="col-sm-10">
                      <Editor
                        editorState={email}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        editorStyle={{ border: "1px solid",height: "250px" }} 
                        onEditorStateChange={onChange}
                      />
                      {props.addCommonErrors.email_content &&
                        props.addCommonErrors.email_content.length > 0 ? (
                        <span className="text-danger">
                          {props.addCommonErrors.email_content}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    className="form-control"
                    type="button"
                    value="update"
                    name="action"
                    onClick={(e) => {
                      editEmailItem(e);
                    }}
                  >
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              <button
                className="btn form-control"
                id="2917e275-605b-4fed-85e2-ea807a18848a"
                data-dismiss="modal"
                onClick={() =>
                  props.setFieldValues("edit_email_modal_show", false)
                }
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    show: state.Settings.edit_email_modal_show,
    email: state.Settings.email,
    addCommonErrors: state.Settings.addCommonErrors,
    addCommonStatus: state.Settings.addCommonStatus,
    message: state.Settings.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFieldValues: (f, v) => dispatch(actions.settings_data(f, v)),
    editEmailItem: (data) => dispatch(actions.editEmailItem(data)),
    updateCommonSettingErrors: (data) =>
      dispatch(actions.updateCommonSettingErrors(data)),
    reset_common_info: () => dispatch(actions.reset_common_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailItem);
