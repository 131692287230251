import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Document";
import * as settings from "../../../actions/Settings";
import { changeClientInfo } from "../../../actions/Client";

function AcceptDocument(props) {
  useEffect(() => {}, []);

  const acceptFunction = (e) => {
    e.preventDefault();
    props.acceptDocuments({
      document_id: props.document_id,
    },props.tabSelected);
  };

  return (
    <Modal
      show={props.acceptDocumentModal}
      id="myModal"
      className="modal fade in"
      style={{ display: "block" }}
    >
      <div className="modal-content wd-100">
        <div className="modal-header text-center">
          {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> */}
          <h4 className="modal-title blue-text">Accept Document</h4>
        </div>
        <div className="modal-body wd-100">
          {props.clientApiStatus ? (
            <div
              className={`alert ${
                props.clientApiStatus === 1 ? "alert-success" : "alert-danger"
              }`}
              role="alert"
            >
              {props.message}
            </div>
          ) : (
            ""
          )}
          <div className="wd-80 m-auto">
            {/* <div className="form-group">
              <label htmlFor="Document" className="control-label required">
                Please input the reason to reject the document
              </label>
              <textarea
                className="form-control"
                rows={3}
                name="reason"
                cols={50}
                id="reason"
                defaultValue={""}
                value={props.reject_reason}
                onChange={(e) => {
                  props.sendValues("reject_reason", e.target.value);
                }}
              />
            </div> */}
            <div className="button-wrapper mt-20 wd-100">
              <button
                className="btn gray-border-btn"
                onClick={() => props.sendValues("acceptDocumentModal", false)}
              >
                Cancel
              </button>
              <button
                className="btn primary-btn"
                style={{ background: "green", color: "white" }}
                onClick={(e) => {
                  acceptFunction(e);
                }}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    
  );
}

const mapStateToProps = (state, ownProps) => {
  const { acceptDocumentModal, document_id } = state.Document;

  return {
    acceptDocumentModal,
    document_id,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    tabSelected: state.Document.tabSelected,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentTypeList: () => dispatch(actions.getDocumentTypeList()),
    acceptDocuments: (value,tab) => dispatch(actions.documentAccept(value,tab)),
    sendDocTypeAndGetDocument: (type) =>
      dispatch(actions.sendDocTypeAndGetDocument(type)),
    getDefaultPlaceHolder: (type) =>
      dispatch(actions.getDefaultPlaceHolder(type)),
    changeClientInfo: (k, d) => dispatch(changeClientInfo(k, d)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptDocument);
