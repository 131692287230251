import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Document";
import { useState } from "react";

function EditDocumentDate(props) {

  const [updatedDate, setUpdatedDate] = useState(props.created_on);

  const handleEditClick = () => {
    props.editResDate(props.document_id, updatedDate);
  };

  return (
    <Modal
      show={props.editDocumentModalDate}
      className="modal bootstrap-dialog type-primary fade size-normal in"
      role="dialog"
      aria-hidden="true"
      id="Confirmpersonal"
      aria-labelledby="Confirmpersonal"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="3113c4e9-48b3-4ff7-9296-8d60c502dc72_title"
              >
                Confirm Edit
              </div>
            </div>
          </div>
          <div className="modal-body">
            {props.clientApiStatus ? (
              <div
                className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                  }`}
                role="alert"
              >
                {props.clientApiStatus === 1 ? "" : props.message}
              </div>
            ) : (
              ""
            )}

            <div className="bootstrap-dialog-body" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="bootstrap-dialog-message" style={{ marginRight: '10px' }}>
                Confirm to Edit?
              </div>
              <input type="date" className="form-control" id="dateInput" style={{ width: '150px' }} defaultValue={props.created_on} onChange={(e) => setUpdatedDate(e.target.value)} />
            </div>


          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control btn-danger"
                  id="bac1d4e1-aded-4b83-b6da-85088ce2ae55"
                  onClick={handleEditClick}
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-check" />
                  Update Resolution Date
                </button>
                <button
                  className="btn form-control"
                  id="d3d45ab4-88e6-44b8-b897-f967887fdaf2"
                  onClick={() => {
                    props.sendValues("editDocumentModalDate", false);
                    props.sendValues("created_on", '');
                  }}
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    editDocumentModalDate: state.Document.editDocumentModalDate,
    tabSelected: state.Document.tabSelected,
    document_id: state.Document.document_id,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    created_on: state.Document.created_on
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (f, v) => dispatch(actions.documentFunction(f, v)),
    editResDate: (id, date) => dispatch(actions.editDate(id, date)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentDate);
