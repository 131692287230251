import config from "../common/Api_Links";
import { headers } from "../common/Headers";
import { ifTokenExpire } from "../common/TokenValidation";
import { s3_file_upload } from "../common/S3_Upload";
import history from "../../stores/history";
import { changeClientInfo } from "../Client";

export const personal_list = (data) => {
  return {
    type: "PERSONAL_LIST",
    data,
  };
};


export const personal_data = (field, value) => {
  return {
    type: "PERSONAL_DATA",
    field,
    value,
  };
};

export const getIdentificationType = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.identificationType, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(personal_data("idList", responseData.data));
          }
        }
      });
  };
};

export const getDesignation = () => {
  var header = headers();

  return (dispatch) => {
    return fetch(config.getDesignationList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(personal_data("designationList", responseData.data));
          }
        }
      });
  };
};

export const getSalutation = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.getSalutationList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(personal_data("salutationList", responseData.data));
          }
        }
      });
  };
};

export const getNationalities = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.getNationalitiesList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(personal_data("nationalitiesList", responseData.data));
          }
        }
      });
  };
};

export const getFlag = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.getFlagList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(personal_data("flagList", responseData.data));
          }
        }
      });
  };
};

export const addPersonal = (data) => {
  var header = headers();

  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    // console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_personal, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              window.location.reload();
              dispatch(personal_data("addPersonal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                if (window.location.pathname == "/client_individual") {
                  dispatch(getPersonalList(sessionStorage.getItem("personalId")));
                  dispatch(personal_data("documentLink", ""));
                } else {
                  dispatch(
                    getPersonalList()
                  );
                  dispatch(personal_data("documentLink", ""));
                }
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const editPersonal = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.Edit_personal, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(personal_data("editPersonal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                if (window.location.pathname == "/share_holder") {
                  dispatch(getPersonalList());
                } else {
                  dispatch(
                    getPersonalList(sessionStorage.getItem("personalId"))
                    
                  );
                  dispatch(personal_data("attachment", ''));
                  dispatch(personal_data('personal_id',''));
                }
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const getPersonalList = (input) => {
  var header = headers();
  let data;
  if (input != undefined) {
    data = {
      client_id: input,
    };
  }
  return (dispatch) => {
    dispatch(personal_list([]));
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.getPersonalList, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            if(sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(18)){
            let add = [...responseData.data];
            responseData.data.map((val, idx) => {
              val.check = false;
            });
            dispatch(personal_list(add));
            dispatch(changeClientInfo("processModal", "none"));
          }
          } else {
            dispatch(personal_list([]));
          }
        }
      });
  };
};

export const setPrimary = (obj) => {
  var header = headers();
  let data = {
    identification_no: obj.identification_no
  }
  return (dispatch) => {
    let url = `${config.setPrimary}${obj.id}`
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status == 1) {
          dispatch(getPersonalList())
        }
      });
  };
};

export const setMerge = (obj) => {
  var header = headers();
  // let data = {
  //   identification_no: obj.identification_no
  // }
  return (dispatch) => {
    let url = `${config.setMergeUrl}${obj.id}`
    return fetch(url, {
      method: "PUT",
      // body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status == 1) {
          dispatch(getPersonalList())
        }
      });
  };
};

export const deletePersonal = (val) => {
  var header = headers();
  let value = { id: val };

  return (dispatch) => {
    return fetch(config.delete_Personal, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(personal_data("deletePersonal", false));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(getPersonalList(sessionStorage.getItem("personalId")));
              dispatch(personal_data('personal_id',''));
            }, 2500);
          } else {
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const approvePersonal = (val) => {
  var header = headers();
  let value = { office_bearer_id: val };

  return (dispatch) => {
    return fetch(config.approve_Personal, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status === 1) {
          ifTokenExpire(responseData);
          dispatch(personal_data("approve_modal", false));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(getPersonalList(sessionStorage.getItem("personalId")));
            dispatch(personal_data('personal_id',''));
          }, 2500);
        } else {
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};
