import React, { Fragment, useEffect } from "react";
import "./LeftSideBar.css";
import { Link } from "react-router-dom";
import loadjs from "loadjs";
// import "../../public/assets/js/app.min.js";
import $ from "jquery";

export default function Leftsidebar(props) {
  useEffect(() => {
    loadjs("/assets/js/app.min.js");
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });
  }, []);

  let path = window.location.pathname;

  return (
    <Fragment>
      <div className="main-sidebar">
        {/* sidebar: style can be found in sidebar.less */}
        <nav className="sidebar">
          {/* sidebar menu: : style can be found in sidebar.less */}
          <ul className="sidebar-menu">
            {/*<li class="header">MAIN NAVIGATION</li>*/}
            {/* <li className>
              <Link to="/">
                <i className="fa fa-dashboard" /> <span>Dashboard</span>
              </Link>
            </li> */}

            {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(11) ? (
              <li className={path == "/client_list" || path == "/share_holder" || path == "/client_individual" || path == "/deadline_summary" || path == "/manage_document" ? (" treeview active") : (" treeview")} >
                <Link to="#">
                  <i className="fa fa-group" />
                  <span>Client</span>
                  <i className="fa fa-angle-left pull-right" />
                </Link>
                <ul className={path == "/client_list" || path == "/share_holder" || path == "/client_individual" || path == "/deadline_summary" || path == "/manage_document" ? ("treeview-menu menu-open") : ("treeview-menu")}
                  style={{ display: path == "/client_list" || path == "/share_holder" || path == "/client_individual" || path == "/deadline_summary" || path == "/manage_document" ? ("block") : "none" }} >
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(12) ? (
                    <li >
                      <Link to="/client_list" className={path == "/client_list" || path == "/client_individual" ? "active" : ''} >
                        <i className="fa fa-minus" /> Clients List
                  </Link>
                    </li>
                  ) : null}
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(46) ? (
                    <li className={path == "/share_holder" ? "active" : ''} >
                      <Link to="/share_holder" className={path == "/share_holder" ? "active" : ''} >
                        <i className="fa fa-minus" /> Office Bearer Summary
                  </Link>
                    </li>
                  ) : null}
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(50) ? (
                    <li >
                      <Link to="/deadline_summary" className={path == "/deadline_summary" ? "active" : ''} >
                        <i className="fa fa-minus" /> Deadline Summary
                  </Link>
                    </li>
                  ) : null}
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(56) ? (
                    <li>
                      <Link to="/manage_document" className={path == "/manage_document" ? "active" : ''}>
                        <i className="fa fa-minus" /> Document Management{" "}
                      </Link>
                    </li>
                  ) : null}
                  {/* <li>
                    <Link to="/upload_statement" className={path == "/upload_statement" ? "active" : ''}>
                      <i className="fa fa-minus" /> Upload Statement
                    </Link>
                  </li>
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(56) ? (
                    <li>
                      <Link to="/ocr-upload" className={path == "/ocr-upload" ? "active" : ''}>
                        <i className="fa fa-minus" /> Document Upload (OCR){" "}
                      </Link>
                    </li>
                  ) : null} */}
                </ul>
              </li>
            ) : null}
            {/* <li className="treeview">
              <Link to="#">
                <i className="fa fa-money" />
                <span>Billing</span>
                <i className="fa fa-angle-left pull-right" />
              </Link>
              <ul className="treeview-menu">
                <li>
                  <Link to="/billing/invoice">
                    <i className="fa fa-minus" /> Invoice{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/billing/setting">
                    <i className="fa fa-minus" /> Setting
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* <li className="treeview">
              <Link to="#">
                <i className="fa fa-star" />
                <span>Employee</span>
                <i className="fa fa-angle-left pull-right" />
              </Link>
              <ul className="treeview-menu">
                <li>
                  <Link to="/employee/timesheets">
                    <i className="fa fa-minus" />
                    Timesheets
                  </Link>
                </li>
                <li>
                  <Link to="/employee/timesheetedit">
                    <i className="fa fa-minus" />
                    Timesheets Edit
                  </Link>
                </li>
                <li>
                  <Link to="/employee/task">
                    <i className="fa fa-minus" />
                    Task
                  </Link>
                </li>
                <li>
                  <Link to="/employee/calendar">
                    <i className="fa fa-minus" />
                    Calendar
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* <li className="treeview">
              <Link to="#">
                <i className="fa fa-calendar" />
                <span>Organizer</span>
                <i className="fa fa-angle-left pull-right" />
              </Link>
              <ul className="treeview-menu">
                <li>
                  <Link to="/organizer/message">
                    <i className="fa fa-minus" /> <span>Message</span>
                    <small className="label pull-right bg-yellow" />
                  </Link>
                </li>
                <li>
                  <Link to="/organizer/todo">
                    <i className="fa fa-minus" /> <span>Todo</span>
                    <small className="label pull-right bg-green">12</small>
                  </Link>
                </li>
              </ul>
            </li> */}
            {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(59) ? (
              <li className={path == "/company_setup" || path == "/user_management"  || path == "/document_templates"   || path == "/document_by_id/" ? (" treeview active") : (" treeview")}>
                <Link to="#">
                  <i className="fa fa-adn" />
                  <span>Administration</span>
                  <i className="fa fa-angle-left pull-right" />
                </Link>
                <ul className={path == "/company_setup" || path == "/user_management"  || path == "/global_documents" || path == "/document_templates"   || path == "/document_by_id/" ? ("treeview-menu menu-open") : ("treeview-menu")}
                  style={{ display: path == "/company_setup" || path == "/pending_document" || path == "/completed_document" || path == "/user_management" || path == "/global_documents"  || path == "/document_templates"   || path == "/document_by_id/" ? ("block") : "none" }}>
                  {/* {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(60) ? (
                    <li >
                      <Link to="/company_setup" className={path == "/company_setup" ? "active" : ''} >
                        <i className="fa fa-minus" /> Company Setup
                  </Link>
                    </li>
                    
                  ) : null} */}
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(60) ? (
                    <li >
                      <Link to="/document_templates" className={path == "/document_templates" ? "active" : ''} >
                        <i className="fa fa-minus" /> Document Templates
                  </Link>
                    </li>
                    
                  ) : null}
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(60) ? (
                    <li >
                      <Link to="/global_documents" className={path == "/global_documents" ? "active" : ''} >
                        <i className="fa fa-minus" /> Global Document
                  </Link>
                    </li>
                  ) : null}
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(63) ? (
                    <li >
                      <Link to="/user_management" className={path == "/user_management" ? "active" : ''}>
                        <i className="fa fa-minus" /> User Management
                  </Link>
                    </li>
                  ) : null}
                   {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(63) ? (
                    <li >
                      <Link to="/completed_document" className={path == "/completed_document" ? "active" : ''}>
                        <i className="fa fa-minus" /> Completed Document
                  </Link>
                    </li>
                  ) : null}
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(63) ? (
                    <li >
                      <Link to="/pending_document" className={path == "/pending_document" ? "active" : ''}>
                        <i className="fa fa-minus" /> Pending Document
                  </Link>
                    </li>
                  ) : null}
                  {/* <li>
                  <Link to="/admin/undo">
                    <i className="fa fa-minus" /> Undo
                  </Link>
                </li> */}
                </ul>
              </li>
            ) : null}

              {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(78) ? (
              <li className={path == "/completed_docs_user" || path == "/signdocument" ||  path == "/pending_docs_user" ? (" treeview active") : (" treeview")}>
                <Link to="#">
                  <i className="fa fa-adn" />
                  <span>Documents</span>
                  <i className="fa fa-angle-left pull-right" />
                </Link>
                <ul className={path == "/pending_docs_user" || path == "/completed_docs_user" || path == "/signdocument"  ? ("treeview-menu menu-open") : ("treeview-menu")}
                  style={{ display: path == "/pending_docs_user" || path == "/signdocument"  || path == "/completed_docs_user" ? ("block") : "none" }}>
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(78) ? (
                    <li >
                      <Link to="/pending_docs_user" className={path == "/pending_docs_user" ? "active" : ''} >
                        <i className="fa fa-minus" />Pending Documents
                  </Link>
                    </li>
                  ) : null}
                  {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(79) ? (
                    <li >
                      <Link to="/completed_docs_user" className={path == "/completed_docs_user" ? "active" : ''} >
                        <i className="fa fa-minus" />Completed Documents
                  </Link>
                    </li>
                  ) : null}
                 
                  {/* <li>
                  <Link to="/admin/undo">
                    <i className="fa fa-minus" /> Undo
                  </Link>
                </li> */}
                </ul>
              </li>
            ) : null}
          </ul>
        </nav>
        {/* /.sidebar */}
      </div>

      {/* ./wrapper */}
    </Fragment>
  );
}
