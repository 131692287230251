let datas = {
  pageSelected: "User",
  addUserShow: false,
  editUserShow: false,
  removeCompanyShow: false,
  changeRoleShow: false,
  deleteUserShow: false,
  usersList: [],
  usersLists: [],
  name: "",
  userName: "",
  email: "",
  changePassword: "",
  hourlyRate: "",
  accountStatus: 0,
  assignedCompany: "",
  role: "",
  user_id: "",
  userApiStatus: "",
  message: "",
  staticColumns: "",
  userPermission: [],
  permissionList: [],
  assignedCompanyList: [],
  company_id: "",
  removeCompanyErrors: {},
  showPermissionModal:'',
  userRoleId:'',
  sepUserPermission:[],
  userId:'',
  unAssigned:false,
  
};

export const UserDatas = (state = datas, action) => {
  switch (action.type) {
    case "USER_DATA":
      return { ...state, [action.field]: action.value };
    case "UPDATE_COMMON_ERRORS":
      return {
        ...state,
        removeCompanyErrors: { ...state.removeCompanyErrors, ...action.data },
      };
    case "RESET_REMOVE_INFO":
      return {
        ...state,
        assignedCompany: "",
        removeCompanyErrors: {},
        company_id: "",
        user_id: "",
        message: "",
        userApiStatus: "",
      };
    default:
      return state;
  }
};
