import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "../../../styles/CommenStyle.css";
import { Modal } from 'react-bootstrap';
import * as actions from "../../../actions/clientDetails/ShareRegister";
import * as client from "../../../actions/Client";
import DatePicker from "react-datepicker";
import moment from "moment";
import { isNumber } from "lodash";



function SplitShare(props) {

  useEffect(() => {
    props.sendValues("splitDocLink", '')
    props.allListDocuments(sessionStorage.getItem("personalId"))
  }, [])
  
  const getfilename=(e)=>{
    var splitedval=(e.target.value).split("_")
   
    props.sendValues("splitDocLink",e.target.value)
    var index = e.nativeEvent.target.selectedIndex;

    if(e.target.value != 0 && e.target.value != ''){
      props.sendValues("splitDocLink",e.target.value)
      props.alldocumentclientList && props.alldocumentclientList.map((filepathresultnn,indexvalnn)=>{
      
        if(splitedval[0] == filepathresultnn.id){
          document.getElementById("viewdocument").style.display="block";
          document.getElementById("viewdocument").href=filepathresultnn.file_path;
        }
      })
    }else{
      document.getElementById("viewdocument").style.display="none";
    }
    
  }
  const addSplitFunc = (e) => {
    var userData = JSON.parse(localStorage.getItem("userData"));
    e.preventDefault();
    let document_date = '';
    if (props.splitDocResolutionDate != '') {
      document_date = moment(props.splitDocResolutionDate).format('YYYY-MM-DD')
    }
    let input = {
      company_id: userData.company_id,
      resolution_date: moment(props.splitResolutionDate).format('YYYY-MM-DD'),
      acrafileddate:moment(props.acrafileddate).format("YYYY-MM-DD"),
      share_type: props.splitShareType,
      client_id: sessionStorage.getItem("personalId"),
      split_value: props.splitValues,
      share_after_split: props.splitTotalShare,
      remarks: props.splitRemark,
      document_link: props.splitDocLink,
      document_id: props.splitDocId,
      attachment: props.splitAttachment,
      attachment_keyword: props.splitAttachmentkey,
      document_type: props.splitDocType,
      document_date: document_date,
      notes: props.splitNotes,
      description: props.description,

    };
    props.addSplitShare(input)
  };
  console.log("props.splitAttachment",props.splitAttachment)
  return (
    <Modal show={props.splitShares}
      onHide={(e) => console.log(e)} 
      className="modal bootstrap-dialog type-primary fade size-normal in" role="dialog" aria-hidden="true" id="splitshares" aria-labelledby="splitshares&quot;&quot;" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div className="bootstrap-dialog-close-button" style={{ display: 'none' }}><button className="close" aria-label="close">×</button></div>
              <div className="bootstrap-dialog-title" id="5d5f8a36-2514-4b00-b2a9-b2d167df3b7c_title">Split Shares
                </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div>
                  <form onSubmit={addSplitFunc} id="form" className="form-horizontal"><input name="_method" type="hidden" defaultValue="PUT" /><input name="_token" type="hidden" defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446" />
                    <div className="form-group">
                      <label htmlFor="recorddate" className="control-label col-sm-4 required">Resolution
                          Date</label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.splitResolutionDate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          required="required"
                          onChange={(Date) => {
                            props.sendValues("splitResolutionDate", Date);
                            let obj = {
                              share_type_id: props.splitShareType,
                              resolution_date: moment(Date).format("YYYY-MM-DD")
                            }
                            props.splitShareBasedApi(obj)
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() =>
                              props.sendValues("splitResolutionDate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="recorddate" className="control-label col-sm-4 required">Acra Filed Date</label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.acrafileddate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          required="required"
                          onChange={(Date) => {
                            props.sendValues("acrafileddate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() =>
                              props.sendValues("acrafileddate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="sharetype_id" className="control-label col-sm-4 required">Share Type</label>
                      <div className="col-sm-8">
                        <select className="form-control" onChange={(e) => {
                          let obj = {
                            share_type_id: e.target.value,
                            resolution_date: moment(props.splitResolutionDate).format("YYYY-MM-DD")
                          }
                          props.splitShareBasedApi(obj)
                          props.sendValues("splitShareType", e.target.value)
                        }} style={{ backgroundColor: '#fff' }} required="required" id="sharetype_id" name="sharetype_id">
                          <option value selected="selected">=== Please Select ===</option>
                          {props.ShareTypeList.map((shar) => {
                            return (
                              <option value={shar.id}>{shar.Name}</option>
                            )
                          })
                          }
                        </select>
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="valuepershare" className="control-label col-sm-4 required">Split
                          Value</label>
                      <div className="col-sm-8">
                        <input className="form-control" value={props.splitValues} onChange={(e) => {
                          props.sendValues("splitValues", e.target.value)
                          if (e.target.value != '' && e.target.value != undefined && e.target.value != null) {
                            let setval = (props.dummy / e.target.value)
                            props.sendValues("splitTotalShare", setval != "Infinity" ? setval : 0 )
                          } else {
                            props.sendValues("splitTotalShare", props.dummy)
                          }
                        }} required="required" name="valuepershare" type="number" id="valuepershare" />
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="shareaftersplit" className="control-label col-sm-4">Total Share After
                          Split</label>
                      <div className="col-sm-8">
                        <input className="form-control" disabled="disabled" value={props.splitTotalShare} onChange={(e) => { props.sendValues("splitTotalShare", e.target.value) }} name="shareaftersplit" type="text" id="shareaftersplit" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="total_value" className="control-label col-sm-4">Total Value</label>
                      <div className="col-sm-8">
                        <input className="form-control" disabled="disabled" value={props.splitTotalValue} onChange={(e) => { props.sendValues("splitTotalValue", e.target.value) }} name="total_value" type="text" id="total_value" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="remarks" className="control-label col-sm-4">Remarks</label>
                      <div className="col-sm-8">
                        <textarea className="form-control" onChange={(e) => { props.sendValues("splitRemark", e.target.value) }} rows={3} name="remarks" cols={50} id="remarks" defaultValue={""} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="document_link" className="control-label col-sm-4">Document Link</label>
                      <div className="col-sm-8">
                      {/* onChange={(e) => { props.sendValues("splitDocLink", e.target.value) }} */}
                        <select className="form-control" 
                         onChange={(e) => getfilename(e)
                         }
                        id="document_link" name="document_link">
                          <option value='' selected="selected">View from the drop-down list / Select New Doc to upload new</option>
                          <option value={0}>New Document</option>
                          {
                            props.alldocumentclientList && props.alldocumentclientList.map((filepathresult,index)=>{
                              return(<>
                             <option value= {filepathresult.id+"_"+filepathresult.document_type+"_"+filepathresult.indexno}> {filepathresult.file_path.split('/').pop().split('?')[0].split('#')[0].replace(/[/\\?%*:|"<>]/g, '-') }</option>
                              </>);
                            })
                          }
                        </select>
                        <a href="" id="viewdocument" style={{display:"none"}} target="_blank">View Selected Document</a>
                      </div>
                    </div>
                    {props.splitDocLink == '0' ? (
                      <div>
                        <div className="form-group ">
                          <label htmlFor="document_id" className="control-label col-sm-4">Document ID</label>
                          <div className="col-sm-8">
                            <input className="form-control" onChange={(e) => { props.sendValues("splitDocId", e.target.value) }} name="document_id" type="text" id="document_id" />
                            <p className="help-block">
                              Must be unique ID with this client </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="original_filename" className="control-label col-sm-4">Attachment</label>
                          <div className="col-sm-8">
                            <input className="form-control" onChange={(e) => {
                              if (e.target.files[0].size <= 2000000) {
                                props.sendValues("splitAttachment", e.target.files[0])
                              } else {
                                props.sendValues("splitAttachment", '');
                                e.target.value = '';
                                props.sendError("clientApiStatus", 2);
                                props.sendError("message", "File size limit only 2Mb");
                                setTimeout(() => {
                                  props.sendError("clientApiStatus", 0);
                                  props.sendError("message", "");
                                }, 2500);
                              }
                            }
                            } maxLength={4096} accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif" name="original_filename" type="file" id="original_filename" />
                            <p className="help-block">
                              * Uploading of file limited to 2MB only. </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="document_tag" className="control-label col-sm-4">Description</label>
                          <div className="col-sm-8">
                            <textarea className="form-control" rows={3} onChange={(e) => { props.sendValues("description", e.target.value) }} name="document_tag" cols={50} id="document_tag" defaultValue={""} />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="keywords" className="control-label col-sm-4">Attachment Keyword for
                          Searching Purposes</label>
                          <div className="col-sm-8">
                            <textarea className="form-control" onChange={(e) => { props.sendValues("splitAttachmentkey", e.target.value) }} rows={3} name="keywords" cols={50} id="keywords" defaultValue={""} />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="document_type_id" className="control-label col-sm-4">Document
                          Type</label>
                          <div className="col-sm-8">
                            <select className="form-control" id="document_type_id" onChange={(e) => { props.sendValues("splitDocType", e.target.value) }} name="document_type_id">
                              <option value='' >=== Select ===</option>
                              {props.DocumentTypeList.map((val) => {
                                return (
                                  <option value={val.id}>{val.Name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="document_date" className="control-label col-sm-4">Resolution/Document
                          Date</label>
                          <div className="col-sm-8">
                            <DatePicker
                              showPopperArrow={false}
                              dateFormat={"dd/MM/yyyy"}
                              selected={props.splitDocResolutionDate}
                              locale={"en"}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "0px, 0px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                              onChange={(Date) => {
                                props.sendValues("splitDocResolutionDate", Date);
                              }}
                            >
                              <div
                                class="trash-icon"
                                onClick
                                ={() =>
                                  props.sendValues("splitDocResolutionDate", "")
                                }
                              >
                                <span class="glyphicon glyphicon-trash"></span>{" "}
                              </div>
                            </DatePicker>
                            <p className="help-block">
                              DD/MM/YYYY </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="document_tag" className="control-label col-sm-4">Notes</label>
                          <div className="col-sm-8">
                            <textarea className="form-control" rows={3} onChange={(e) => { props.sendValues("splitNotes", e.target.value) }} name="document_tag" cols={50} id="document_tag" defaultValue={""} />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-8">
                      <input className="form-control" name="client_id_doc" type="hidden" defaultValue={489} />
                    </div>
                    <div className="form-group">
                      <div className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12" id="errortext" />
                    </div>
                    <div className="col-sm-8">
                      <input className="form-control" name="client_id" type="hidden" defaultValue={489} />
                    </div>
                    <div className="col-sm-8">
                      <input className="form-control" name="operationtype" type="hidden" defaultValue="S" />
                    </div>
                    <div className="col-sm-12" >
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                            }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button className="btn form-control btn-success" type="submit" value="S" name="action" id="action"><span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" /> Split
                        Share</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons"><button className="btn form-control" id="0a719518-4611-4af8-acdb-f286a38360a7" onClick={() => {
                props.sendValues('splitShares', false)
                props.sendValues("splitDocLink", '')
                props.sendValues("splitResolutionDate", '');
                props.sendValues("acrafileddate", '');
              }}><span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
};

const mapStateToProps = (state, ownProps) => {
  const {
    splitShares,
    splitResolutionDate,
    acrafileddate,
    splitShareType,
    splitValues,
    splitTotalShare,
    splitTotalValue,
    splitRemark,
    splitDocLink,
    splitDocId,
    splitAttachment,
    splitAttachmentkey,
    splitDocType,
    splitDocResolutionDate,
    splitNotes,
    description,
    ShareTypeList,
    DocumentTypeList,
    dummy,
  } = state.ShareReg
  return {
    splitShares,
    splitResolutionDate,
    acrafileddate,
    splitShareType,
    splitValues,
    splitTotalShare,
    splitTotalValue,
    splitRemark,
    splitDocLink,
    splitDocId,
    splitAttachment,
    splitAttachmentkey,
    splitDocType,
    splitDocResolutionDate,
    splitNotes,
    description,
    ShareTypeList,
    DocumentTypeList,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    personalList: state.Personal.personalList,
    alldocumentclientList: state.Client.alldocumentclientList,
    dummy
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    allListDocuments: (data) => dispatch(client.allListDocuments(data)),
    sendValues: (k, v) => dispatch(actions.share_register(k, v)),
    sendError: (f, v) => dispatch(client.changeClientInfo(f, v)),
    splitShareBasedApi: (val) => dispatch(actions.splitShareBasedApi(val)),
    addSplitShare: (val) => dispatch(actions.addSplitShare(val)),
  }

};

export default connect(mapStateToProps, mapDispatchToProps)(SplitShare);