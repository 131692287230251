import React, { Fragment, useEffect } from "react";
import Header from "./Header";
import Leftsidebar from "./LeftSideBar";
import Footer from "./Footer";
import "../styles/CommenStyle.css";
import History from "../stores/history";
import { connect } from "react-redux";
import { loginDetails, sendData } from "../actions/Login";

const Login = (props) => {
  const { history, location = {} } = props;
  useEffect(() => {
    console.log("in");
    let stringValue = window.localStorage.getItem("token");
    let userData = window.localStorage.getItem("userData");
    if (stringValue !== null && userData !== null) {
      let value = JSON.parse(stringValue);
      let user = JSON.parse(userData);
      let expirationDate = new Date(value.expirationDate);
      console.log("expiration", expirationDate, new Date());
      if (expirationDate > new Date() && Object.keys(user).length != 0) {
        if (props.fromPathName && props.fromPathName != "/") {
          console.log("props");
          history.push(props.fromPathName);
        } else {
          history.push("/client_list");
        }
      }
    }
  }, []);
  const loginFormSubmit = (e) => {
    e.preventDefault();
    let v = {
      username: props.user_name,
      password: props.password,
    };
    props.sendData(v);
  };

  return (
    <Fragment>
      <header className="navbar-default navbar-fixed-top">
        <div className="container-fluid">
          {/* Flash Message */}
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle Navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a className="navbar-brand" href="/">
              GBS Secretarial Software
            </a>
          </div>
        </div>
      </header>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n        body {\n            padding-top: 60px;\n        }\n    ",
        }}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="panel panel-default">
              <div className="panel-heading">Sign In</div>
              <div className="panel-body">
                <form
                  className="form-horizontal"
                  role="form"
                  onSubmit={(e) => loginFormSubmit(e)}
                >
                  {props.logInStatus ? (
                    <div
                      className={`alert ${
                        props.logInStatus === 2 ? "alert-danger" : null
                      }`}
                      role="alert"
                    >
                      {props.logInStatus === 2 ? props.errorMessage : null}
                    </div>
                  ) : (
                    ""
                  )}
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="IwDOQj2EbfrvkC10wf9TUSFZlFirhU0wPdmvQVOc"
                  />
                  <div className="form-group">
                    <label className="col-md-4 control-label">
                      E-Mail Address
                    </label>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        autoComplete="off"
                        onChange={(e) => {
                          props.sendLogin("user_name", e.target.value);
                        }}
                        placeholder="Please enter your email address"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4 control-label">Password</label>
                    <div className="col-md-6">
                      <input
                        type="password"
                        autoComplete="off"
                        onChange={(e) => {
                          props.sendLogin("password", e.target.value);
                        }}
                        className="form-control"
                        name="password"
                        placeholder="Please enter your password"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-md-6 col-md-offset-4">
                      <button
                        id="loginid"
                        name="Login"
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginRight: "15px" }}
                      >
                        Login
                      </button>
                      <a href="/forgetpassword">Forgot Your Password?</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="navbar-default navbar-fixed-bottom">
        <div className="container-fluid">
          Powered by Smitiv Mobile Technologies Opc Pvt Ltd
        </div>
      </footer>
      {/* jQuery (necessary for Bootstrap's JavaScript plugins) */}
      {/* Latest compiled and minified JavaScript */}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user_name: state.Login.user_name,
    password: state.Login.password,
    logInStatus: state.Login.logInStatus,
    errorMessage: state.Login.errorMessage,
    pathName: state.Login.pathName,
    fromPathName: state.Login.fromPathName,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendLogin: (f, v) => dispatch(loginDetails(f, v)),
    sendData: (v) => dispatch(sendData(v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
