import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Document";
import * as settings from "../../../actions/Settings";
import { changeClientInfo } from "../../../actions/Client";

function RejectDocument(props) {
  useEffect(() => {}, []);

  const rejectFunction = () => {
    if (props.reject_reason != "") {
      props.rejectDocuments({
        document_id: props.document_id,
        reject_reason: props.reject_reason,
      },props.tabSelected);
    } else {
      props.changeClientInfo("clientApiStatus", 2);
      props.changeClientInfo("message", "Please Select Reject reason");
      setTimeout(() => {
        props.changeClientInfo("clientApiStatus", 0);
        props.changeClientInfo("message", "");
      }, 2500);
    }
  };

  return (
    <Modal
      show={props.rejectDocumentModal}
      id="myModal"
      className="modal fade in"
      style={{ display: "block" }}
    >
      <div className="modal-content wd-100">
        <div className="modal-header text-center">
          {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> */}
          <h4 className="modal-title blue-text">Reject Document</h4>
        </div>
        <div className="modal-body wd-100">
          {props.clientApiStatus ? (
            <div
              className={`alert ${
                props.clientApiStatus === 1 ? "alert-success" : "alert-danger"
              }`}
              role="alert"
            >
              {props.message}
            </div>
          ) : (
            ""
          )}
          <div className="wd-80 m-auto">
            <div className="form-group">
              <label htmlFor="Document" className="control-label required">
                Please input the reason to reject the document
              </label>
              <textarea
                className="form-control"
                rows={3}
                name="reason"
                cols={50}
                id="reason"
                defaultValue={""}
                value={props.reject_reason}
                onChange={(e) => {
                  props.sendValues("reject_reason", e.target.value);
                }}
              />
            </div>
            <div className="button-wrapper mt-20 wd-100">
              <button
                className="btn gray-border-btn"
                onClick={() => props.sendValues("rejectDocumentModal", false)}
              >
                Cancel
              </button>
              <button
                className="btn danger-btn"
                onClick={(e) => {
                  rejectFunction(e);
                }}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { rejectDocumentModal, document_id, reject_reason } = state.Document;

  return {
    rejectDocumentModal,
    document_id,
    reject_reason,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    tabSelected: state.Document.tabSelected,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentTypeList: () => dispatch(actions.getDocumentTypeList()),
    rejectDocuments: (value,tab) => dispatch(actions.documentRejection(value,tab)),
    sendDocTypeAndGetDocument: (type) =>
      dispatch(actions.sendDocTypeAndGetDocument(type)),
    getDefaultPlaceHolder: (type) =>
      dispatch(actions.getDefaultPlaceHolder(type)),
    changeClientInfo: (k, d) => dispatch(changeClientInfo(k, d)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectDocument);
