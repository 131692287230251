const log = {
  show: false,
  user_name: "",
  password: "",
  logInStatus: "",
  errorMessage: "",
  fromPathName: "",
  pathName: "",
};

export const Login = (state = log, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
