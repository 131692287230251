import config from "./common/Api_Links";
import { headers } from "./common/Headers";
import { ifTokenExpire } from "./common/TokenValidation";
import history from "../stores/history";
import { changeClientInfo } from "./Client";

export const updateCompanyErrors = (data) => {
  return {
    type: "UPDATE_COMPANY_ERRORS",
    data,
  };
};

export const changeCompanyInfo = (field, value) => {
  return {
    type: "CHANGE_COMPANY_INFO",
    field,
    value,
  };
};

export const company_list = (data) => {
  return {
    type: "COMPANY_LIST",
    data,
  };
};

export const reset_company_info = () => {
  return {
    type: "RESET_COMPANY_INFO",
  };
};

export const adddocumentcat = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.add_category, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
        
        }
      });
  };
};
export const addCompany = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.add_company, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(getCompanyList());
            dispatch(changeCompanyInfo("company_modal_show", false));
            dispatch(changeCompanyInfo("message", responseData.message));
            dispatch(changeCompanyInfo("addCompanyStatus", 1));
            setTimeout(() => {
              dispatch(changeCompanyInfo("addCompanyStatus", 0));
              dispatch(reset_company_info());
            }, 1500);
          } else {
            dispatch(changeCompanyInfo("addCompanyStatus", 2));
            dispatch(changeCompanyInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeCompanyInfo("addCompanyStatus", 0));
              dispatch(changeCompanyInfo("message", ""));
            }, 1500);
          }
        }
      });
  };
};

export const getCompanyList = () => {
  var header = headers();
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.company_list, {
      method: "POST",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(company_list(responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          }
        }
      });
  };
};
export const getGlobaldocumentList = () => {
  var header = headers();
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.global_document_list, {
      method: "POST",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(company_list(responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          }
        }
      });
  };
};

export const getdocumentManagementList = (data) => {
  var header = headers();
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.document_management_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(company_list(responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          }
        }
      });
  };
};
export const getdocumentpendingList = (data) => {
  var header = headers();
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.pending_docs_user, {
      method: "POST",
      headers: header,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(company_list(responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          }
        }
      });
  };
};

export const getCompleteddocumentList = (data) => {
  var header = headers();
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.completed_docs, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(company_list(responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          }
        }
      });
  };
};
export const getPendingdocumentList = (data) => {
  var header = headers();
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.pending_docs, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(company_list(responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          }
        }
      });
  };
};