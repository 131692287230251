import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../actions/Settings";

import "react-datepicker/dist/react-datepicker.css";

function EditServiceItem(props) {
  useEffect(() => {
    if (props.addCommonStatus === 1) {
      setTimeout(function () {
        props.setFieldValues("addCommonStatus", 0);
        props.setFieldValues("edit_service_modal_show", false);
        props.reset_common_info();
      }, 2000);
    }
  }, [props.addCommonStatus]);
  const editServiceItem = (event) => {
    event.preventDefault();
    if (validItemInfo()) {
      props.editServiceItem({
        service_id: props.service.id,
        service_code: props.service.description,
        hourly_rate: props.service.hourly_rate,
        fixed_rate: props.service.fixed_rate,
        estimated_rate: props.service.estimated_rate,
      });
    }
  };

  const validItemInfo = () => {
    let valid = true;
    let errors = props.addCommonErrors;
    if (!props.service.description) {
      valid = false;
      errors.description = "Cannot be Empty";
    } else {
      errors.description = "";
    }
    props.updateCommonSettingErrors(errors);
    return valid;
  };

  return (
    <Modal
      show={props.show}
      className="modal bootstrap-dialog type-primary size-normal "
      role="dialog"
      aria-hidden="true"
      id="newcurrency"
      aria-labelledby="newcurrency"
      tabIndex={-1}
      onHide={() => props.setFieldValues("edit_service_modal_show", false)}
    >
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div
              className="bootstrap-dialog-title"
              id="106028d4-3de2-4702-91b6-7833821502e6_title"
            >
              Information
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div className="bootstrap-dialog-body">
                <form
                  method="POST"
                  action="https://crm2.gbsc.geneasol-apps.info/dashboard/setting/1/service/1"
                  acceptCharset="UTF-8"
                  id="form"
                  className="form-horizontal"
                >
                  <input name="_method" type="hidden" defaultValue="PUT" />
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="F3AwN3mnzZZGeheR5gRty4f9fSSfjanNtr2WbpNg"
                  />
                  {props.addCommonStatus ? (
                    <div
                      className={`alert ${
                        props.addCommonStatus === 1
                          ? "alert-success"
                          : "alert-danger"
                      }`}
                      role="alert"
                    >
                      {props.addCommonStatus === 1
                        ? "Item updated successfully"
                        : props.message}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label
                      htmlFor="description"
                      className="control-label col-sm-4 required"
                    >
                      Description
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="description"
                        type="text"
                        id="description"
                        value={props.service.description}
                        onChange={(e) => {
                          props.setFieldValues("service", {
                            ...props.service,
                            description: e.target.value,
                          });
                        }}
                      />
                      {props.addCommonErrors.description &&
                      props.addCommonErrors.description.length > 0 ? (
                        <span className="text-danger">
                          {props.addCommonErrors.description}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="hourlyrate"
                      className="control-label col-sm-4 required"
                    >
                      Hourly Rate
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="hourlyrate"
                        type="text"
                        id="hourlyrate"
                        value={props.service.hourly_rate}
                        onChange={(e) => {
                          props.setFieldValues("service", {
                            ...props.service,
                            hourly_rate: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="fixedrate"
                      className="control-label col-sm-4 required"
                    >
                      Fixed Rate
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="fixedrate"
                        type="text"
                        id="fixedrate"
                        value={props.service.fixed_rate}
                        onChange={(e) => {
                          props.setFieldValues("service", {
                            ...props.service,
                            fixed_rate: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="estimatedrate"
                      className="control-label col-sm-4 required"
                    >
                      Estimated Rate
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="estimatedrate"
                        type="text"
                        id="estimatedrate"
                        value={props.service.estimated_rate}
                        onChange={(e) => {
                          props.setFieldValues("service", {
                            ...props.service,
                            estimated_rate: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <button
                    className="btn form-control btn-success"
                    type="button"
                    value="update"
                    name="action"
                    onClick={(e) => {
                      editServiceItem(e);
                    }}
                  >
                    <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              <button
                className="btn form-control"
                id="332e58a8-7317-43a0-8876-0d801752f0e1"
                data-dismiss="modal"
                onClick={() =>
                  props.setFieldValues("edit_service_modal_show", false)
                }
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    show: state.Settings.edit_service_modal_show,
    service: state.Settings.service,
    addCommonErrors: state.Settings.addCommonErrors,
    addCommonStatus: state.Settings.addCommonStatus,
    message: state.Settings.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFieldValues: (f, v) => dispatch(actions.settings_data(f, v)),
    editServiceItem: (data) => dispatch(actions.editServiceItem(data)),
    updateCommonSettingErrors: (data) =>
      dispatch(actions.updateCommonSettingErrors(data)),
    reset_common_info: () => dispatch(actions.reset_common_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditServiceItem);
