import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import BaseApp from "./App";
import { Router } from "react-router-dom";
import history from "./stores/history";

ReactDOM.render(
  <Router history={history}>
    {/* <React.StrictMode> */}

    <BaseApp />
    {/* </React.StrictMode> */}
  </Router>,
  document.getElementById("root")
);
