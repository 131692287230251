import React, { Fragment, useEffect,useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import { connect } from "react-redux";
import * as actions from "../../actions/CompanySetups";
import "../administration/CompanySetups.css";
import moment from "moment";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function ShareHolder(props) {
  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getdocumentManagementList();
   

    return () => {
      table.destroy(true);
    };
  }, []);

  console.log("deep", props.companyList)

  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");

  useEffect(() => {
    function format(d) {
      console.log("did", d);
      return (
        `<tr id="detail-view-${d?.id}"><td colspan="7" style="padding:0px;"><table id="detail-view-${d?.id}"   >` +
        "<tr>" +
        "<td>Remark:</td>" +
        "<td>" +
        `${(() => {
          if (d.remarks != undefined) {
            return d?.remarks
          } else {
            return ''
          }
        })()}`
        +
        "</td>" +
        "</tr>" +
        `</table></td></tr>`
      );
    }

    $(document).ready(function () {

      table = $("#resolution").DataTable({
        pageLength:25,
        lengthMenu: [ 25, 50 ],
        paging: true,

        destroy: true,

        data: props.companyList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
          },
          {
            targets: 0,
            orderable: true,
            searchable: true,
            className: " col-xs-12 col-sm-12 col-md-12 col-lg-12",
            render: function (data, type, row) {
              return `<span/>${row.date}</span>`;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#0075b0");
          }
        },
        createdRow: function (row, data, dataIndex) {
          $("td:eq(0)", row).attr("colspan", 6).css("color", "white");
          $("td:eq(1)", row).css("display", "none").css("width", "75px");
          $("td:eq(2)", row).css("display", "none").css("width", "75px");
          $("td:eq(3)", row).css("display", "none").css("width", "75px");
          $("td:eq(4)", row).css("display", "none").css("width", "75px");
          $("td:eq(5)", row).css("display", "none").css("width", "75px");
          $("td:eq(6)", row).css("display", "none").css("width", "75px");
         
       
        },
      });

      table.rows().every(function () {
        var rowdata = this.data();
        var dataChild = rowdata.list;
        dataChild && 
        this.child(
          dataChild.map((item) => {
            let clr = '';
            if (item.is_approved == 0) {
              clr = '#f44242'
            } else {
              clr = '<span style="margin-left: 30px;">---</span>';
            }
            return $(
              `<tr style="background-color: ${clr}" >` +
              "<td class=''>" +
              `${item.docs_classification}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {
                if (item.attachment != '' && item.attachment != null && item.attachment != undefined) {
                  return item.attachment.split('/').pop().split('?')[0].split('#')[0].replace(/[/\\?%*:|"<>]/g, '-');
                }

              })()}
              ${(() => {
                if (item.attachment != '' && item.attachment != null && item.attachment != undefined) {
                  return'<a href="'+item.attachment+'"><span class="document glyphicon glyphicon-paperclip" id="'+item.docids+'" /></a>';
                } 
              })()}` +
              "</td>" +
              "<td class=''>" +
              `${moment(item.created_on).format("DD/MM/YYYY")}` +
              "</td>" +
              "</td>" +
              "<td class=''>" +
              `${item.notes}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {
                if (item.flag == 1 ) {
                  return '<span class="glyphicon glyphicon glyphicon-check text-success fa-lg" aria-hidden="true"></span>';
                }else{
                  return '<span class="glyphicon glyphicon-exclamation-sign text-danger fa-lg" aria-hidden="true"></span>';
                }

              })()}
              ` +
              "</td>" +
              // `<td class=''><button class="btn btn-primary btn-xs" title="Delete"><span class="glyphicon glyphicon-trash"></span></button></td>` +
              "</tr>"
            );
          })
        ).show();
      });

      

     

     
    });
  }, [props.companyList]);

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div className="content-wrapper" id="content-window">
          <section className="content">
      <div id="share-resolution-tab" className="tab-pane fade active in">
       
            <div className="box box-primary box_align_left" boxHeight>
            <div className="box-header documents-header"> 
            <i className="glyphicon glyphicon-folder-open" />
              <h3 className="box-title">Documents</h3>
              {/* <div>
                <nav className="navbar navbar-default" role="navigation">
                  <div className="container-fluid" id="navfluid">
                    <div className="navbar-header">
                      <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navigationbar" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                      </button>
                      <a className="navbar-brand" href="#" />
                    </div>
                    <div className="collapse navbar-collapse" id="navigationbar">
                      <ul className="nav navbar-nav">
                        <ul className="nav navbar-nav">
                          <li>
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Documents<span className="caret" /></a>
                            <ul className="dropdown-menu">
                              <li><a href="javascript:void(0)" role="menuitem" tabIndex={-1} onclick="menuRow('documents/create', '', '', 'Upload Documents', '', '', '', true )">Upload</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </nav>
                <div className="box-tools pull-right" />
              </div> */}
              <div />
            </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="share-action_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
              
                      <table
                        className="table table-striped table-bordered col-md-12 dataTable no-footer"
                        role="grid"
                        style={{ marginLeft: 0,padding:0 }}
                        id="resolution"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="details-control col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "56px" }}
                              aria-label="Details"
                            >
                              Doc. Type 
                            </th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Filename
                            </th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Doc. Date
                            </th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Notes
                            </th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              	Flag
                            </th>
                            {/* <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              	Actions
                            </th> */}
                            
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    
                <div
                  id="share-action_processing"
                  className="dataTables_processing panel panel-default"
                  style={{ display: props.processModal }}
                >
                  Processing...
                </div>
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>
      </section></div>
      <Footer />
      </div>

      {/* ./wrapper */}
    
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    shareResolutionList,
    shareHolderList,
    ShareTypeList,
    shareId,
  } = state.ShareReg;
  return {
    shareResolutionList,
    shareHolderList,
    ShareTypeList,
    shareId,
    deleteModal: state.Deadline.deleteModal,
    processModal:state.Client.processModal,
    companyList: state.CompanySetups.companyList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  
    getdocumentManagementList: () => dispatch(actions.getdocumentManagementList()),
    changeCompanyInfo: (f, e) => dispatch(actions.changeCompanyInfo(f, e)),
    addCompany: (data) => dispatch(actions.addCompany(data)),
    updateCompanyErrors: (data) => dispatch(actions.updateCompanyErrors(data)),
    reset_company_info: () => dispatch(actions.reset_company_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareHolder);
