const init = {
  addCharges: false,
  deleteCharges: false,
  confirmation_modal_show: false,
  nothing_select: false,
  approve_modal: false,
  approveStatus: "",
  chargeNo: "",
  dateRegistered: "",
  dateDischarge: "",
  currency: "",
  amountSecured: "",
  chargee: "",
  remark: "",
  docLink: "",
  docId: "",
  attachment: "",
  attachmentKey: "",
  docType: "",
  docDate: "",
  note: "",
  addChargesStatus: "",
  chargeList: [],
  charges_id: "",
  deleteChargeStatus: 0,
  editChargesStatus: "",
  currencyList: [],
};

export const Charges = (state = init, action) => {
  switch (action.type) {
    case "CHARGES_DATA":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
