import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/Client";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

function CommentUpdate(props) {
  
  const UpdateClient = (event) => {
    event.preventDefault();
    var notes =  props.updated_comment;
    props.saveDocumentNotes(props.updated_status,notes,props.updated_doc_id);
    props.setFieldValues("updated_doc_id", 0);
    props.setFieldValues("document_comment_modal_show", false);
  };
  useEffect(() => {
    var notes =  props.updated_comment;
    props.fetchDocumentNotes(props.updated_status,notes,props.updated_doc_id);
  }, [props.updated_doc_id]);
  return (
    <Modal
      show={props.show}
      className="modal bootstrap-dialog type-primary size-normal "
      role="dialog"
      aria-hidden="true"
      id="newclient"
      aria-labelledby="newclient"
      tabIndex={-1}
      onHide={() => {
        props.setFieldValues("document_comment_modal_show", false)
        props.setFieldValues("company_id", '');
      }}
    >
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div
              className="bootstrap-dialog-title"
              id="6515262a-6027-4e02-a065-90e1c9e04a75_title"
            >
              Update Comments
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div>
                <form
                  method="POST"
                  action="https://crm2.gbsc.geneasol-apps.info/client/clients?"
                  acceptCharset="UTF-8"
                  encType="multipart/form-data"
                  className="form-horizontal"
                  id="form"
                >
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="wQUZACCGN0G9CyyGsQ55geMLv6LTTQDh3sskflCL"
                  />
           
                     <div className="form-group">
                        <label
                          htmlFor="document_tag"
                          className="control-label col-sm-4"
                        >
                          Comments
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control"
                            rows={3}
                            name="document_tag"
                            cols={50}
                            id="document_tag"
                            value={props.updated_comment}
                            onChange={(e) =>
                              props.setFieldValues("updated_comment", e.target.value)
                            }
                          />
                        </div>
                      </div>
                  
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      name="client_id_doc"
                      type="hidden"
                    />
                  </div>
                  <div className="col-sm-12" >
                    {props.clientApiStatus ? (
                      <div
                        className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                          }`}
                        role="alert"
                      >
                        {props.clientApiStatus === 1 ? '' : props.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    className="btn form-control btn-success"
                    id="addnew"
                    value="new"
                    name="action"
                    type="button"
                    onClick={(e) => {
                      // props.changeCompanyInfo("addCompanyStatus", 1);
                      UpdateClient(e);
                    }}
                  >
                    <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              <button
                onClick={() => {
                  props.setFieldValues("document_comment_modal_show", false);
                }}
                className="btn form-control"
                id="f86f3f7f-ac8a-417d-9cba-2410e5baee15"
                data-dismiss="modal"
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    show: state.Client.document_comment_modal_show,
    company_name: state.Client.company_name,
    registration_no: state.Client.registration_no,
    incorporation_date: state.Client.incorporation_date,
    currency: state.Client.currency,
    address: state.Client.address,
    referral: state.Client.referral,
    remarks: state.Client.remarks,
    flag: state.Client.flag,
    document_id: state.Client.document_id,
    attachment: state.Client.attachment,
    attachment_keyword: state.Client.attachment_keyword,
    document_type: state.Client.document_type,
    document_date: state.Client.document_date,
    notes: state.Client.notes,
    addClientErrors: state.Client.addClientErrors,
    addClientStatus: state.Client.addClientStatus,
    document_link: state.Client.document_link,
    company_id: state.Client.company_id,
    EditClientStatus: state.Client.EditClientStatus,
    currencyList: state.Client.currencyList,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeList: state.Settings.DocumentTypeList,
    updated_comment: state.Client.updated_comment,
    updated_status: state.Client.updated_status,
    updated_doc_id: state.Client.updated_doc_id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFieldValues: (f, v) => dispatch(actions.changeClientInfo(f, v)),
    editClient: (data) => dispatch(actions.editClient(data)),
    updateClientErrors: (data) => dispatch(actions.updateClientErrors(data)),
    reset_client_info: () => dispatch(actions.reset_client_info()),
    getCurrencyList: () => dispatch(actions.getCurrencyList()),
    saveDocumentNotes: (status,notes,documentid) => dispatch(actions.saveDocumentNotes(status,notes,documentid)),
    fetchDocumentNotes: (status,notes,documentid) => dispatch(actions.fetchDocumentNotes(status,notes,documentid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentUpdate);
