import config from "./common/Api_Links";
import history from "../stores/history";
import { sessionSet } from "./common/TokenValidation";

export const loginDetails = (field, value) => {
  return {
    type: "LOGIN",
    field,
    value,
  };
};

export const sendData_forgetpassword = (values) => {
  const { username, password } = values;
  return (dispatch) => {
    return fetch(config.forgetpassword, {
      method: "post",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Basic YWRtaW46RmxleGlBZG1pbg==",
      },
      body: JSON.stringify({
        user_name: username,
      }),
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then(async (responseData) => {
        if (responseData) {
          if (responseData.status == 1) {
            dispatch(loginDetails("logInStatus", 3));
            dispatch(loginDetails("errorMessage", responseData.message));
            setTimeout(() => {
              dispatch(loginDetails("logInStatus", 1));
              history.push("/");
            }, 2500);
            
          } else {
           
            dispatch(loginDetails("logInStatus", 2));
            dispatch(loginDetails("errorMessage", responseData.message));
            setTimeout(() => {
              dispatch(loginDetails("logInStatus", 1));
            }, 2500);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};


export const sendData = (values) => {
  const { username, password } = values;
  return (dispatch) => {
    return fetch(config.login, {
      method: "post",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Basic YWRtaW46RmxleGlBZG1pbg==",
      },
      body: JSON.stringify({
        user_name: username,
        password,
      }),
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then(async (responseData) => {
        if (responseData) {
          if (responseData.status == 1) {
            if (responseData.userdetails.company_id != '' && responseData.userdetails.company_id != undefined && responseData.userdetails.company_id != null
              && responseData.userdetails.role != '' && responseData.userdetails.role != undefined && responseData.userdetails.role != null
              && (JSON.parse(responseData.userdetails.permissions)).length !=0 && responseData.userdetails.permissions != '' && responseData.userdetails.permissions != undefined && responseData.userdetails.permissions != null) {
              await sessionSet("token", responseData.userdetails.token, 360);
              await localStorage.setItem(
                "userData",
                JSON.stringify(responseData.userdetails)
              );
              sessionStorage.setItem("user_name", responseData.userdetails.name);
              sessionStorage.setItem("login_userid", responseData.userdetails.userid);
              sessionStorage.setItem("all_company", responseData.userdetails.allcompany_id);
              sessionStorage.setItem("office_bearer_ids", responseData.userdetails.office_bearer_ids);
              sessionStorage.setItem("permissions", JSON.parse(responseData.userdetails.permissions));
              sessionStorage.setItem(
                "user_role",
                responseData.userdetails.user_name
              );
              sessionStorage.setItem(
                "join_date",
                responseData.userdetails.created_at
              );
              sessionStorage.getItem("permissions").includes(78) ?  history.push("/pending_docs_user"):
              history.push("/client_list");
              
            } else {
              sessionStorage.clear()

              dispatch(loginDetails("logInStatus", 2));
              dispatch(loginDetails("errorMessage", "Unauthorized User"));
              setTimeout(() => {
                dispatch(loginDetails("logInStatus", 1));
              }, 2500);

            }
          } else {
            sessionStorage.clear()

            dispatch(loginDetails("logInStatus", 2));
            dispatch(loginDetails("errorMessage", responseData.message));
            setTimeout(() => {
              dispatch(loginDetails("logInStatus", 1));
            }, 2500);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};
