import React, { Fragment, useEffect,useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/UserManagement";
import * as companyActions from "../../actions/CompanySetups";
import Addnew from "./modals/CreateNewUser";
import Edit from "./modals/EditUser";
import Delete from "./modals/DeleteUser";
import Remove from "./modals/RemoveCompany";
import RoleChange from "./modals/ChangeRole";
import moment from "moment";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function User(props) {
  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getUsersList();
    props.getCompanyList();

    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(66)) {
      setEdit("inline-block")
    };

    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(67)) {
      setDelet("inline-block")
    };

  }, []);

  const [edit,setEdit] = useState ("none");
  const [delet,setDelet] = useState ("none");
  useEffect(() => {
  function format(d) {
    console.log("d", d);
    // `d` is the original data object for the row
    return (
      '<table cellpadding="5" cellspacing="0" border="0" >' +
      "<tr>" +
      "<td>Remarks:</td>" +
      "<td>" +
      d.remarks +
      "</td>" +
      "</tr>" +
      "<tr>" +
      "<td>Referral:</td>" +
      "<td>" +
      d.referral +
      "</td>" +
      "</tr>" +
      "<tr>" +
      "<td>Address:</td>" +
      "<td>" +
      d.address +
      "</td>" +
      "</tr>" +
      "</table>"
    );
  }

  $(document).on( 'init.dt', function ( e, settings ) {
    var api = new $.fn.dataTable.Api( settings );
    window.setTimeout(function () {
            api.table().columns.adjust().draw();
        },1);
} );

  $(document).ready(function () {
    table = $("#user").DataTable({
      initComplete: function (settings, json) {  
        $("#DataTableID").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");            
      },
    
      pageLength:25,
      lengthMenu: [ 25, 50 ],
      paging: true,
      destroy: true,
    

      data: props.usersList,

      columnDefs: [
        {
          targets: "_all",
          defaultContent: "",
        },
        {
          targets: 0,
          orderable: true,
          searchable: true,

          className: "col-xs-2 col-md-1",
          render: function (data, type, row) {
            if (row.list.length > 0) {
              return `<span/>${row.rolename}</span>`;
            }
          },
        },
      ],

      rowCallback: function (row, data, index) {
        if (!data.is_approved) {
          $(row).css("background-color", "#0075b0");
        }
      },
      createdRow: function (row, data, dataIndex) {
        $("td:eq(0)", row).attr("colspan", 6).css("color", "white");
        $("td:eq(1)", row).css("display", "none").css("width", "75px");
        $("td:eq(2)", row).css("display", "none").css("width", "75px");
        $("td:eq(3)", row).css("display", "none").css("width", "75px");
        $("td:eq(4)", row).css("display", "none").css("width", "75px");
        $("td:eq(5)", row).css("display", "none").css("width", "75px");
        $("td:eq(6)", row).css("display", "none").css("width", "75px");
      },
    });

    table.rows().every(function () {
      var rowdata = this.data();
      var dataChild = rowdata.list;
      this.child(
        dataChild.map((item) => {
          let role = ''
          if (item.role != '' && item.role != null && item.role != undefined) {
            role = item.role
          }
          return $(
            "<tr class=''>" +
            "<td class=''>" +
            "</td>" +
            `<td >` +
            item.name +
            "</td>" +
            "<td class=''>" +
            rowdata.rolename +
            "</td>" +
            "<td class=''>" +
            moment(item.created_at).format("YYYY-MM-DD hh:mm:ss") +
            "</td>" +
            "<td class=''>" +
            moment(item.role_created_at).format("YYYY-MM-DD hh:mm:ss") +
            "</td>" +
            "<td >" +
            `<button class='btnEdit btn btn-primary btn-xs' style="display:${edit}" id=edit${item.id} title="Edit" name=${rowdata.rolename} value=${item.userid}><span class='glyphicon glyphicon-pencil' /></button> 
              <button class='btnrem btn btn-primary btn-xs'id=remove${item.id} title="Remove Company" name=${item.userid} value=${item.company_id} ><span class='glyphicon glyphicon-briefcase' /></button> 
              <button class='btnchg  btn btn-primary btn-xs'id=change${item.id} title="Change Role" name=${rowdata.rolename} value=${item.userid}><span class='glyphicon glyphicon-user' /></button> 
              <button class='btnDel btn btn-primary btn-xs' style="display:${delet}" id=delete${item.id} title="Delete" name=${item.userid} ><span class='glyphicon glyphicon-trash' /></button>
              <button class="btnUser btn btn-primary btn-xs" id=permission${item.role} name=${item.role} title="Permissions" value=${item.userid}  ><span class="fa fa-shield  "></span></button>` +
            "</td>" +
            "</tr>"
          );
        })
      ).show();
    });

    $("#user tbody").off("click");
    $("#user").on("click", ".btnUser", function (e) {
      var data = table.row(this).data();
      console.log("katori",this.value)
      if (this.name !== "undefined" && this.name != null && this.name != 0) {
        props.setValues("showPermissionModal", true);
        props.setValues("userRoleId", this.name);
        props.setValues("userId", this.value);
        props.usersList.map((use) => {
          if (use.roleid == this.name) {
            use.list.map((lis) => {
              if (lis.userid == this.value) {
                props.setValues("sepUserPermission", JSON.parse(lis.permissions));
              }
            })
          }
        })
      } else {
        props.setValues("unAssigned", true);
        props.setValues("userApiStatus", 2);
        props.setValues("message", "Please Assign Role First");
        setTimeout(() => {
          props.setValues("unAssigned", false);
          props.setValues("userApiStatus", 0);
          props.setValues("message", '');
        }, 2500);
      }
    });

    $("#user").on("click", ".btnEdit", function () {
      var data = table.row(this).data();
      props.usersList &&
        props.usersList.map((val, idx) => {
          if (val.rolename == this.name) {
            val.list.map((lis) => {
              if(lis.userid ==this.value){
              props.setValues("name", lis.name);
              props.setValues("userName", lis.user_name);
              props.setValues("email", lis.email);
              props.setValues("changePassword", lis.password);
              props.setValues("hourlyRate", lis.hourly_rate);
              props.setValues("accountStatus", lis.account_status);
              props.setValues("assignedCompany", lis.company_name);
              props.setValues("user_id", lis.userid);
              props.setValues("editUserShow", true);
              }
            });
          }
        });
    });

    $("#user").on("click", ".btnDel", function () {
      var data = table.row(this).data();
      props.setValues("user_id", this.name);
      props.setValues("deleteUserShow", true);
    });

    $("#user").on("click", ".btnrem", async function () {
      var data = table.row(this).data();
      props.setValues("user_id", this.name);
      // var assignCompany = await props.usersList.map((user) => {
      //   var companyAssinged = user.list.filter((item) => {
      //     return item.userid == this.name;
      //   });
      //   return companyAssinged;
      // });
      var assignedList = [];
      // if (assignCompany.length > 0 && assignCompany[0].length > 0) {
        assignedList = props.companyList.filter(
          (comp) => comp.id == this.value
        );
        console.log("assignCompany",this.value)
      // }
      props.setValues("assignedCompanyList", assignedList);
      props.setValues(
        "assignedCompany",
        this.value !=null
          ? this.value
          : ""
      );
      props.setValues("removeCompanyShow", true);
    });

    $("#user").on("click", ".btnchg", function () {
      var data = table.row(this).data();
      if(this.name !="undefined"){
      props.usersList &&
        props.usersList.map((val, idx) => {
          if (val.rolename == this.name) {
            val.list.map((lis) => {
              props.setValues("user_id", this.value);
              props.setValues("role", lis.role);
              props.setValues("changeRoleShow", true);
            });
          }
        });
      }else{
        props.setValues("role", '');
      }
    });
  });
  },[props.usersList])
  return (
    <Fragment>
      <div className="tab-pane fade in active">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navigationbar"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#" />
                  </div>
                  <div className="collapse navbar-collapse" id="navigationbar">
                    <ul className="nav navbar-nav">
                      <ul className="nav navbar-nav">
                        <li>
                          <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="true"
                          >
                            User Management
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu">
                          {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(64)?( 
                            <li>
                              <a
                                onClick={() =>
                                  props.setValues("addUserShow", true)
                                }
                              >
                                User
                              </a>
                            </li>
                          ):null}
                          </ul>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className="box-tools pull-right" />
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="role-users_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                <div className="row">
                  <div className="col-sm-6" />
                  <div className="col-sm-6" />
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="dataTables_scroll">
                      <div
                        className="dataTables_scrollHead"
                        style={{
                          overflow: "hidden",
                          position: "relative",
                          border: "0px",
                          width: "100%",
                        }}
                      >
                        <div
                          className="dataTables_scrollHeadInner"
                          style={{
                            boxSizing: "content-box",
                          }}
                        >
                          <table
                            id="user"
                            className="table table-striped table-bordered col-md-12  dataTable no-footer"
                            role="grid"
                            style={{ marginLeft: 0 ,padding:0}}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="col-xs-2 col-md-1 sorting_disabled"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "58px" }}
                                  aria-label
                                />
                                <th
                                  className="col-xs-2 col-md-2 sorting"
                                  tabIndex={0}
                                  aria-controls="role-users"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "157px" }}
                                  aria-label="Name: activate to sort column ascending"
                                >
                                  Name
                                </th>
                                <th
                                  className="col-xs-2 col-md-1 sorting"
                                  tabIndex={0}
                                  aria-controls="role-users"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "58px" }}
                                  aria-label="Level: activate to sort column ascending"
                                >
                                  Level
                                </th>
                                <th
                                  className="col-xs-2 col-md-2 sorting"
                                  tabIndex={0}
                                  aria-controls="role-users"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "158px" }}
                                  aria-label="Account Created At: activate to sort column ascending"
                                >
                                  Account Created At
                                </th>
                                <th
                                  className="col-xs-2 col-md-2 sorting"
                                  tabIndex={0}
                                  aria-controls="role-users"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "158px" }}
                                  aria-label="Role Created At: activate to sort column ascending"
                                >
                                  Role Created At
                                </th>
                                <th
                                  className="col-xs-2 col-md-2 sorting"
                                  tabIndex={0}
                                  aria-controls="role-users"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "159px" }}
                                  aria-label="Actions: activate to sort column ascending"
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      id="role-users_processing"
                      className="dataTables_processing panel panel-default"
                      style={{ display: props.processModal }}
                    >
                      Processing...
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-sm-5">
                    <div
                      className="dataTables_info"
                      id="role-users_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 1 to 17 of 17 entries
                    </div>
                  </div>
                  <div className="col-sm-7" />
                </div> */}
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>

      <Addnew />
      <Edit />
      <Delete />
      <Remove />
      <RoleChange />
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    usersList: state.UserDatas.usersList,
    companyList: state.CompanySetups.companyList,
    processModal:state.Client.processModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setValues: (f, v) => dispatch(actions.changeUserInfo(f, v)),
    getUsersList: () => dispatch(actions.getUsersList()),
    getCompanyList: () => dispatch(companyActions.getCompanyList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
