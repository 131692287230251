import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "../../../styles/CommenStyle.css";
import { Modal } from 'react-bootstrap'; 
import * as actions from "../../../actions/clientDetails/Personal"; 


function ConfirmationApprove(props) {

    useEffect(() => {

    }, [])

    return (
      <Modal show={props.approve_modal} className="modal bootstrap-dialog type-primary fade size-normal " role="dialog" aria-hidden="true" id="confirmapprove" aria-labelledby="confirmapprove" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="bootstrap-dialog-header">
              <div className="bootstrap-dialog-close-button" style={{display: 'none'}}><button className="close" aria-label="close">×</button></div>
              <div className="bootstrap-dialog-title" id="104fed43-4c83-4895-add8-9df518a9f9c3_title">Confirmation
              </div>
            </div>
          </div>
          <div className="modal-body">
          {props.clientApiStatus ? (
              <div
                className={`alert ${
                  props.clientApiStatus === 1 ? "" : "alert-danger"
                }`}
                role="alert"
              >
                {props.clientApiStatus === 1 ? '' : props.message}
              </div>
            ) : (
              ""
            )}
         
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">Proceed with Approval?</div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons"><button className="btn btn-default" id="4997aa0e-751a-455d-8bac-07e55d9d7e2c" onClick ={()=>props.setFieldValues("approve_modal",false)}>Close</button><button className="btn btn-default" id="btn-confirm" onClick={()=>props.approvePersonal(props.personal_id)}>Confirm</button></div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    )
};

const mapStateToProps = (state, ownProps) => {
  return{
  approve_modal:state.Personal.approve_modal,
  personal_id :state.Personal.personal_id,
  approveStatus :state.Personal. approveStatus,
  clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return{
    setFieldValues : (f,v)=>{dispatch(actions.personal_data(f,v))},
    approvePersonal : (id)=>{dispatch(actions.approvePersonal(id))},
}
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationApprove);