const init = {
  pageSelect: "Currency",
  List: [],
  DocumentTypeList:[],
  DocumentTypeListnew:[],
  DocumentTypeListcount:[],
  common_modal_show: false,
  common_edit_modal_show: false,
  service_modal_show: false,
  service_edit_modal_show: false,
  email_modal_show: false,
  email_edit_modal_show: false,
  description: "",
  id: "",
  service: {},
  email: {},
  document: {},
  addCommonErrors: {},
  addCommonStatus: 0,
  setting_type: "",
  message:'',
};

export const Settings = (state = init, action) => {
  switch (action.type) {
    case "SETTINGS_DATA":
      return { ...state, [action.field]: action.value };
    case "UPDATE_COMMON_ERRORS":
      return {
        ...state,
        addCommonErrors: { ...state.addCommonErrors, ...action.data },
      };
    case "RESET_COMMON_INFO":
      return {
        ...state,
        description: "",
        addCommonErrors: {},
        addCommonStatus: 0,
        id: "",
        service: {},
        email: {},
        document: {},
      };
    default:
      return state;
  }
};
