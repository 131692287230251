let init ={

};





export const CompleteDeadline = (state = init, action) => {
    switch (action.type) {
      case "DEADLINE_COMPLETE_DATA":
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  };