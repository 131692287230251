import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions/UserManagement";
import $ from "jquery";
import moment from "moment";
import { Modal } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";


function IndividualPermission(props) {

    var table;

    useEffect(() => {
        const { getRolesList, getUserPermissionList, getPermissionList } = props;
        getRolesList();
        getUserPermissionList();
        getPermissionList();
    }, []);

    const updateSelection = key => e => {
        const newRole = { ...selectedRole };
        newRole[key] = e.target.checked;
        setSelectedRole(newRole);
    };

    const addParamsValues = (e) => {
        e.preventDefault();
        // key: 1_1 1_2
        let obj = {};
        obj.permissions_list = [];
        props.usersLists.map((sts, idx) => {
            if (props.userRoleId == sts.id) {
                const role_name = sts.id; // 1
                const arr = [];
                const permission = props.permissionList.map(permission => permission.id);
                permission.map(permissionId => {
                    // const arr=[];
                    const key = role_name + "_" + permissionId;
                    if (selectedRole[key]) {
                        arr.push(permissionId);
                    }
                });
                let result = {
                    user_id: props.userId,
                    role: role_name,
                    permissions: arr
                }
                obj = { ...result }
            }
        });
        console.log("rig", obj)
        props.updatePermission(obj)
    };

    useEffect(() => {
        console.log(props.permissionList);
        let obj = {};
        props.permissionList.map((val, idx) => {
            props.usersLists.map((tab, idx) => {
                let permissions = props.sepUserPermission;
                // for (let i = 0; i < props.userPermission.length; i++) {
                //     if (props.userPermission[i].role_name === tab.name) {
                //         permissions = props.userPermission[i].permissions; // [2, 3]
                //         break;
                //     }
                // }
                console.log("permis", permissions)
                let checked = permissions.includes(val.id);
                const key = `${tab.id}_${val.id}`;
                obj[key] = checked || false;
            })
        });
        setSelectedRole(obj);
        console.log(obj);
    }, [props.usersLists, props.permissionList, props.sepUserPermission])

    const [selectedRole, setSelectedRole] = useState({});

    console.log("props", props.usersLists, selectedRole)

    return (
        <Modal show={props.showPermissionModal}  onHide={() => props.setValues('showPermissionModal', false)} className="modal bootstrap-dialog type-primary fade size-normal in" role="dialog" aria-hidden="true" id="f6f05434-ca06-4d28-a795-a540e5958e1b" aria-labelledby="f6f05434-ca06-4d28-a795-a540e5958e1b_title" tabIndex={-1} style={{ zIndex: 1050, display: 'block' }}>
            <div className="modal-content">
                <div className="modal-header bootstrap-dialog-draggable">
                    <div className="bootstrap-dialog-header">
                        <div className="bootstrap-dialog-close-button" ><button onClick={() => props.setValues('showPermissionModal', false)} className="close" aria-label="close">X</button></div>
                        <div className="bootstrap-dialog-title" id="f6f05434-ca06-4d28-a795-a540e5958e1b_title">Update Permission
                  </div>
                    </div>
                </div>
                <div className="modal-body">
                    {props.userApiStatus ? (
                        <div
                            className={`alert ${props.userApiStatus === 1 ? "" : "alert-danger"
                                }`}
                            role="alert"
                        >
                            {props.userApiStatus === 1 ? '' : props.message}
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="bootstrap-dialog-body">
                        <div className="bootstrap-dialog-message">
                            <div className="bootstrap-dialog-body">
                                <form method="post" id="rolespermissionform" action="/admin/rolespermission">
                                    <div className="form-group">
                                        <label htmlFor="confirmed" className="control-label col-sm-2">User Role</label>
                                        <div className="col-sm-4">
                                            <select disabled className="form-control" id="confirmed" value={props.userRoleId} name="confirmed" >
                                            {props.usersLists.map((tab, idx) => {
                                                return(
                                                <option value={tab.id} selected="selected">{tab.name}</option>
                                                )
                                            })}
                                            </select>
                                        </div>
                                    </div>
                                    <table className="table table-bordered table-striped scrollable" id="permission">
                                        <input type="hidden" name="_token" defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446" />
                                        <thead>
                                            <tr>
                                                <th width={100} />
                                                <th width={100} />
                                                {/* {props.usersLists.map((tab, idx) => {
                                                    if (tab.id == props.userRoleId) {
                                                        return (
                                                            <th className="rotate-45" width={100}><strong>{tab.name}</strong></th>
                                                        )
                                                    }
                                                })} */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.permissionList.map((val) => {

                                                return (
                                                    <tr>
                                                        <td>{val.name}</td>
                                                        {props.usersLists.map((tab, idx) => {
                                                            if (tab.id == props.userRoleId) {
                                                                // props.permissionStatus.map((sts, ind) => {
                                                                // if (tab.id === sts.role_id) {
                                                                // JSON.parse(sts.permissions).map((per) => {
                                                                //   if (per === idx+1) {
                                                                // // name + user id
                                                                // let permissions = [];
                                                                // for (let i =0; i<props.permissionStatus.length; i++) {
                                                                //     if (props.permissionStatus[i].role_name === tab.name) {
                                                                //       permissions = props.permissionStatus[i].permissions; // [2, 3]
                                                                //       break;
                                                                //     }
                                                                // }         
                                                                // let checked = permissions.includes(val.id);
                                                                const key = `${tab.id}_${val.id}`;

                                                                return (
                                                                    <td><input type="checkbox" checked={selectedRole[key]} onChange={updateSelection(key)} /></td>
                                                                )
                                                            }
                                                            //   }
                                                            // })
                                                            // }
                                                            // })
                                                        })}
                                                    </tr>
                                                )

                                            })}

                                        </tbody>
                                        <tfoot>
                                        </tfoot>
                                    </table>
                                    <button className="buttons" onClick={addParamsValues}  >Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

    )
};



const mapStateToProps = (state, ownProps) => {
    const { usersLists, staticColumns, permissionList, userPermission, showPermissionModal, userRoleId, sepUserPermission, userId } = state.UserDatas
    return {
        usersLists,
        staticColumns,
        permissionList,
        userPermission,
        showPermissionModal,
        userRoleId,
        sepUserPermission,
        userId
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setValues: (f, v) => dispatch(actions.changeUserInfo(f, v)),
        getRolesList: () => dispatch(actions.getRolesList()),
        getUserPermissionList: () => dispatch(actions.getUserPermissionList()),
        getPermissionList: () => dispatch(actions.getPermissionList()),
        updatePermission: (data) => dispatch(actions.updatePermission(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualPermission);