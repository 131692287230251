import history from "../../stores/history";

// add into session
export const sessionSet = (key, value, expirationInMin = 1) => {
  let expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
  let newValue = {
    value: value,
    expirationDate: expirationDate.toISOString(),
  };
  console.log("value", newValue);
  localStorage.setItem(key, JSON.stringify(newValue));
};

// get from session (if the value expired it is destroyed)
export const sessionGet = (key) => {
  let stringValue = window.localStorage.getItem(key);
  let userData = window.localStorage.getItem("userData");

  if (stringValue !== null && userData !== null) {
    let value = JSON.parse(stringValue);
    let user = JSON.parse(userData);
    let expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date() && Object.keys(user).length != 0) {
      return value.value;
    } else {
      window.localStorage.removeItem(key);
    }
  }
  if(window.location.href.includes("otp_screen") || window.location.href.includes("sign_document") || window.location.href.includes("listing_document") ){

  }else{
    history.push("/");
  }
 
};

// get from session (if the value expired it is destroyed)
export const ifTokenExpire = (data) => {
  if (
    data.message === "Missing Token" ||
    data.message === "Invalid Token" ||
    data.message === "Session Expired" ||
    data.message === "Unauthorized"
  ) {
    history.push("/");
  }
};
