const values ={
   remainder_list:[],
    remainderType:[{id:1,Name:"ACRA &ECI Filing-1st reminder"},
    {id:2,Name:"IRAS Filing"},
    {id:3,Name:"TEST TEMPLATE"},
    {id:4,Name:"ACRA & ECI Filing-5th reminder"},
    {id:5,Name:"ACRA &ECI Filing-2nd reminder"},
    {id:6,Name:"ACRA &ECI Filing-3rd reminder"},
    {id:7,Name:"ACRA &ECI Filing-4th reminder"},
    {id:8,Name:"GST FILING"}                 ],
    frequency_list:[{id:1,Name:"Daily"},{id:2,Name:"Weekly"},{id:3,Name:"Monthly"},{id:4,Name:"Yearly"}],

};


export const Remainder = (state = values, action) => {
    switch (action.type) {
      case "REMAINDER_DATA":
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  };