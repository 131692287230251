import config from "./common/Api_Links";

export const profileDetails = (field, value) => {
  return {
    type: "PROFILE",
    field,
    value,
  };
};

export const changePassword = (v) => {
  return (dispatch) => {
    return fetch(config.changepassword, {
      method: "post",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Basic YWRtaW46RmxleGlBZG1pbg==",
      },
      body: JSON.stringify(v),
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then(async (responseData) => {
        if (responseData) {
          if (responseData.status == 1) {
            dispatch(profileDetails("cpStatus", 2));
            dispatch(profileDetails("errorMessage", responseData.message));
            setTimeout(() => {
              dispatch(profileDetails("cpStatus", false));
              dispatch(profileDetails("oldPassword", ""));
              dispatch(profileDetails("newPassword", ""));
              dispatch(profileDetails("confirmPassword", ""));
            }, 3000);
            
          } else {
           
            dispatch(profileDetails("cpStatus", 1));
            dispatch(profileDetails("errorMessage", responseData.message));
            setTimeout(() => {
              dispatch(profileDetails("cpStatus", false));
            }, 3000);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};