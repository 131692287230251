import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Deadline";
import { MultiSelect } from "react-multi-select-component";

function ApproveDeadline(props) {
  const [selectedTasks, setSelectedTasks] = useState([]);
  useEffect(() => {
    let pList = [];
    props.setFieldValues("selectedTasks", []);
    props.clientdeadline.map((p) =>
      pList.push({ label: p.Name, value: p.id })
    );
    setSelectedTasks(pList);
  }, [props.clientdeadline]);

  return (
    <Modal
      show={props.exportByDateModal}
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="confirmExportByDateModal"
      aria-labelledby="confirmExportByDateModal"
      tabIndex={-1}
      onHide={() => props.setFieldValues("exportByDateModal", false)}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="104fed43-4c83-4895-add8-9df518a9f9c3_title"
              >
                Export By Date
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="row">
                <div className="form-group">
                  <label
                    htmlFor="Document"
                    className="control-label col-sm-4 required"
                  >
                    Export List
                  </label>
                  <div className="col-sm-8">
                    <MultiSelect
                      options={selectedTasks}
                      value={props.selectedTasks}
                      onChange={(to) => props.setFieldValues("selectedTasks", to)}
                      labelledBy="Select"
                    ></MultiSelect>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn btn-default"
                  id="4997aa0e-751a-455d-8bac-07e55d9d7e2c"
                  onClick={() => props.setFieldValues("exportByDateModal", false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-default"
                  id="btn-confirm"
                  onClick={() => props.exportByCompany({
                    "client_ids": [],
                    "task_ids": props.selectedTasks.map(o => o.value),
                    "is_company":false
                  })}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    exportByDateModal: state.Deadline.exportByDateModal,
    selectedTasks: state.Deadline.selectedTasks,
    deadline_id: state.Deadline.deadline_id,
    approveStatus: state.Deadline.approveStatus,
    clientdeadline: state.Deadline.clientdeadline,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFieldValues: (f, v) => dispatch(actions.deadline(f, v)),
    exportByCompany: (ids) => dispatch(actions.exportByCompany(ids)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveDeadline);
