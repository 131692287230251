import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import { s3_file_upload } from "../../../actions/common/S3_Upload";
import * as actions from "../../../actions/clientDetails/Document";
import { changeClientInfo } from "../../../actions/Client";
import * as settings from "../../../actions/Settings";

function AddDocument(props) {
  useEffect(() => {
    props.getDocumentList();


  }, []);

  const [description, setDescription] = useState("");
  const [directornumber, setDirectornumber] = useState("");
  const [secretarynumber, setSecretarynumber] = useState("");
  const [corporaterepresentativenumber, setCorporaterepresentativenumber] = useState("0"); 
  const [shareholdernumber, setShareholdernumber] = useState("");
  const [signatureFields, setSignatureFields] = useState("");
  const [agreeFields, setagreeFields] = useState("");
  const [documentCategoryField, setdocumentCategoryField] = useState("");
  const [file, setFile] = useState("");
  const [isSign, setIsSign] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const AddNewFunc = (e) => {
    let input = {
      template: file,
      placeholders: description,
      directorcount : directornumber,
      secratorycount : secretarynumber,
      corporaterepresentativecount:corporaterepresentativenumber,
      shareholdercount : shareholdernumber,
      documentCategoryField: documentCategoryField,
      isAgree: isAgree,
      agreeFields: agreeFields,
      isSign,
      signatureFields,
    };
    
    console.log("🚀 ~ AddNewFunc ~ input:", input)

    props.addDocument(input, props.tabSelected);
  };

  const setData = (e) => {
    e.preventDefault();
    setCorporaterepresentativenumber(e.target.value); 
  };

  return (
    <Modal
      show={props.addNewDocumentModal}
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="editdocument"
      aria-labelledby="editdocument"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="ffad18d1-befc-40ca-a130-15d770e0261c_title"
              >
                Add Document
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div className="bootstrap-dialog-body">
                  <form
                    id="form"
                    className="form-horizontal"
                    encType="multipart/form-data"
                    onSubmit={AddNewFunc}
                  >
                    <input name="_method" type="hidden" defaultValue="PUT" />
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="2snHFaIu0AEDvSYdYoSPpVWSIKoRTkK65F5qad9h"
                    />
                    <div className="form-group ">
                      <label
                        htmlFor="original_filename"
                        className="control-label col-sm-4"
                      >
                        Document
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                          }}
                          maxLength={4096}
                          accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                          name="original_filename"
                          type="file"
                          id="original_filename"
                        />
                      </div>
                    </div>
                    <div className="form-group ">
                      <label
                        htmlFor="document_category"
                        className="control-label col-sm-4"
                      >
                        Document category
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          name="document_category"
                          id="document_category"
                          onChange={(e) => {
                            setdocumentCategoryField(e.target.value);
                          }}
                        >
                          <option value="">Please Select Type</option>
                          {
                            props.DocumentTypeListnew && props.DocumentTypeListnew.map((res, index) => {
                              return (
                                <>
                                  <option value={res.id}>{res.name}</option>
                                </>
                              )
                            })

                          }
                        </select>

                      </div>
                    </div>
                    <div className="form-group ">
                      <label
                        htmlFor="directorcount"
                        className="control-label col-sm-4"
                      >
                        Number of Director
                      </label>
                      <div className="col-sm-8">
                      <select className="form-control" value={directornumber}
                          name="directorcount" id="directorcount" onChange={e => 
                            setDirectornumber(e.target.value)
                          }>
                          <option >0</option>
                          <option selected>1</option>
                          <option >2</option>
                          <option >3</option>
                          <option >4</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group ">
                      <label
                        htmlFor="shareholdercount"
                        className="control-label col-sm-4"
                      >
                        Number of ShareHolder
                      </label>
                      <div className="col-sm-8">
                      <select className="form-control"value={shareholdernumber}
                          name="shareholdercount" id="shareholdercount" onChange={e => 
                            setShareholdernumber(e.target.value)
                          }>
                          <option >0</option>
                          <option selected>1</option>
                          <option >2</option>
                          <option >3</option>
                          <option >4</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
      <label htmlFor="corporaterepresentativecount" className="control-label col-sm-4">
        Number of Corporate Representatives
      </label>
      <div className="col-sm-8">
        <select
          className="form-control"
          value={corporaterepresentativenumber}
          name="corporaterepresentativecount"
          id="corporaterepresentativecount"
          onChange={setData} 
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
        </select>
      </div>
    </div>
                    <div className="form-group ">
                      <label
                        htmlFor="secretarycount"
                        className="control-label col-sm-4"
                      >
                        Number of Secretary
                      </label>
                      <div className="col-sm-8">
                      <select className="form-control"value={secretarynumber}
                          name="secretarycount" id="secretarycount" onChange={e => 
                            setSecretarynumber(e.target.value)
                          }>
                          <option >0</option>
                          <option selected>1</option>
                          <option >2</option>
                          <option >3</option>
                          <option >4</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="notes"
                        className="control-label col-sm-4"
                      >
                        Description
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          rows={3}
                          name="notes"
                          cols={50}
                          id="notes"
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        />
                        <p className="help-block">Given values with comma </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <div
                        className="col-sm-5 align-center justify-center"
                        style={{ justifyContent: "center" }}
                      >
                        <input
                          type="checkbox"
                          className="check-box-active"
                          id="isSign"
                          name="isSign"
                          value={isSign}
                          onClick={(e) => {
                            setIsSign(e.target.checked);
                          }}
                        />
                        <label
                          htmlFor="isSign"
                          className="control-label pl-10 mb-0 required"
                        >
                          Is Sign
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="signature_tag"
                        className="control-label col-sm-4"
                      >
                        Signature fields
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          rows={3}
                          name="signature_tag"
                          cols={50}
                          id="signature_tag"
                          onChange={(e) => {
                            setSignatureFields(e.target.value);
                          }}
                        />
                        <p className="help-block">Given values with comma </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <div
                        className="col-sm-5 align-center justify-center"
                        style={{ justifyContent: "center" }}
                      >
                        <input
                          type="checkbox"
                          className="check-box-active"
                          id="isAgree"
                          name="isAgree"
                          value={isAgree}
                          onClick={(e) => {
                            setIsAgree(e.target.checked);
                          }}
                        />
                        <label
                          htmlFor="isAgree"
                          className="control-label pl-10 mb-0 required"
                        >
                          Is Agreeable
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="agree_tag"
                        className="control-label col-sm-4"
                      >
                        Agree fields
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          rows={3}
                          name="agree_tag"
                          cols={50}
                          id="agree_tag"
                          onChange={(e) => {
                            setagreeFields(e.target.value);
                          }}
                        />
                        <p className="help-block">Given values with comma </p>
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label
                        htmlFor="document_tag"
                        className="control-label col-sm-4"
                      >
                        Sign1
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          name="sign1"
                          id="sign1"
                        />
                      </div>
                    </div> */}
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                        defaultValue={0}
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="category"
                        type="hidden"
                        defaultValue
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_employee_id"
                        type="hidden"
                        defaultValue={0}
                      />
                    </div>
                    <div className="col-sm-12">
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                            }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      id="addnew"
                      value="new"
                      name="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  onClick={() => props.sendValues("addNewDocumentModal", false)}
                  className="btn form-control"
                  id="6be9f1ec-774d-44ef-8447-91e40c727fba"
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    addNewDocumentModal: state.Document.addNewDocumentModal,
    tabSelected: state.Document.tabSelected,
    pageSelected: state.Client.pageSelected,
    document_id: state.Document.document_id,
    attachment_key: state.Document.attachment_key,
    document_type: state.Document.document_type,
    docDate: state.Document.docDate,
    notes: state.Document.notes,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeListnew: state.Settings.DocumentTypeListnew,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (f, v) => dispatch(actions.documentFunction(f, v)),
    getDocumentList: () => dispatch(settings.getDocumentClassification_new()),
    changeClientInfo: (k, v) => dispatch(changeClientInfo(k, v)),
    addDocument: (input, tab) => dispatch(actions.addDocument(input, tab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocument);
