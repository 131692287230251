/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux';
import DocumentEditor from "../../documentEditor/DocumentEditor";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Container, Modal } from "react-bootstrap";
import "file-viewer";
import * as share from "../../../actions/clientDetails/ShareRegister";
import * as actions from "../../../actions/clientDetails/Document";
import { changeClientInfo } from "../../../actions/Client";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledcEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// import "../../../../node_modules/@ckeditor/ckeditor5-build-decoupled-document/build/ckeditor.js"
import { ColorRing } from 'react-loader-spinner'
import Draggable from "react-draggable";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { headers } from "../../../actions/common/Headers";

import { Viewer } from '@react-pdf-viewer/core';

import { Worker } from "@react-pdf-viewer/core";

import { PDFDocument, rgb } from "pdf-lib";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import MarkerImg01 from "../../../assets/close.png";
import config from "../../../actions/common/Api_Links";

import { documentFunction } from "../../../actions/clientDetails/Document";



var _ = require("lodash");

function TransferShares(props) {
  const iframeRef = useRef(0);
  const [random, setRandom] = useState(0);
  const [url, setUrl] = useState("");
  const [url2, setUrl2] = useState("");
  const [obj, setObj] = useState({});
  const [signObj, setSignObj] = useState({});
  const [signName, setSignName] = useState({});
  const [email, setEmail] = useState([]);


  const [receivers, setReceivers] = useState([]);
  const [transferor, setTransferor] = useState({});
  const [shareHolder, setShareHolder] = useState([{ shareholder_name: "" }]);
  const [templateData, setTemplateData] = useState();
  const [rendr, setRendr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [temName, setTemName] = useState("");
  const [shareHolderList, setShareHolderList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [secratoryList, setSecratoryList] = useState([]);

  // const frame = document.getElementById("myFrame");
  const [selectEmail, setSelectEmail] = useState("");
  const [edited, setEdited] = useState("");
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  


{/* ============================================== ==============================================  ==============================================  */}

const dispatch = useDispatch();
const [document, setDocument] = useState(null);
const [markers, setMarkers] = useState([]);
const [persons, setPersons] = useState([]);
const [fileUrl, setFileUrl] = useState("");
const [isAddingMarker, setIsAddingMarker] = useState(false);
const [selectedDesignation, setSelectedDesignation] = useState("");
const [newMarkerLabel, setNewMarkerLabel] = useState("");
const [selectedOption, setSelectedOption] = useState("");
const [selectedName, setSelectedName] = useState("");
const [selectedSign, setSelectedSign] = useState("");
const [finalFile, setFinalFile] = useState(null);
const [optionsNames, setOptionsNames] = useState([]);
const defaultLayoutPluginInstance = defaultLayoutPlugin();
const [currentMarker, setCurrentMarker] = useState(null);
const [pageIndex, setPageIndex] = useState(0);
const [draggingMarker, setDraggingMarker] = useState(null);
const [liveX, setLiveX] = useState(null);
const [liveY, setLiveY] = useState(null);
const viewerRef = useRef();

const designations = ["Director", "Shareholder"];

const optionsByDesignation = {
  Director: [
    { name: "Director1", value: "director_name1", sign: "director_sign1" },
    { name: "Director2", value: "director_name2", sign: "director_sign2" },
    { name: "Director3", value: "director_name3", sign: "director_sign3" },
  ],
  Shareholder: [
    { name: "Shareholder1", value: "shareholder_name1", sign: "shareholder_sign1" },
    { name: "Shareholder2", value: "shareholder_name2", sign: "shareholder_sign2" },
    { name: "Shareholder3", value: "shareholder_name3", sign: "shareholder_sign3" },
  ],
};



{/* ============================================== ==============================================  ==============================================  */}









  useEffect(() => {
    props.getShareHolderList();
    setRandom(random + 1000);
    setUrl(`https://docs.google.com/viewer?url=https://api-gbs.smitiv.co${props.docPreview}&embedded=true`);
    iframeRef.current = random;
  }, []);

  // useEffect(() => {
  //   if(props.transferOfShareModal){
  //     previewFunc();
  //   }
  // },[props.transferOfShareModal])

  const assignDefaultFieldValues = () => {
    if (props.editDocumentModal == true) {
      props.documentList &&
        props.documentList.map((data) => {
          // setObj({});
          data.list.map((lis) => {
            if (lis.id == props.document_id) {
              let arr = lis.placeholder_values.split(",");
              let signArr = lis.signature_placeholder_values?.split(",");
              if (props.defaultFields) {
                if (
                  props.defaultFields.placeholderList &&
                  props.defaultFields.placeholderList?.length > 0
                ) {
                  props.defaultFields.placeholderList.map((fie, ind) => {
                    arr.map((val, idx) => {
                      if (ind == idx) {
                        // let ass = {...obj}
                        obj[fie.Name] = val;
                        console.log("objec", obj);
                        setObj((prevObj) => {
                          return { ...prevObj, ...obj };
                        });
                      }
                    });
                  });
                }
                if (signArr == undefined) {
                  signArr = [];
                }
                if (
                  props.defaultFields.signaturePlaceholderList &&
                  props.defaultFields.signaturePlaceholderList?.length > 0 &&
                  signArr?.length > 0
                ) {
                  props.defaultFields.signaturePlaceholderList.map(
                    (fie, ind) => {
                      signArr.map((val, idx) => {
                        if (ind == idx) {
                          // let ass = {...obj}
                          signObj[fie.Name] = val;
                          console.log("objec", signObj);
                          setSignObj((prevObj) => {
                            return { ...prevObj, ...signObj };
                          });
                        }
                      });
                    }
                  );
                }
              }
            }
          });
        });
    }
  };

  useEffect(() => {
    var company;
    if (props.defaultFields) {
      if (
        props.defaultFields.placeholderList &&
        props.defaultFields.placeholderList?.length > 0
      ) {
        props.defaultFields.placeholderList.map((key) => {
          obj[key.Name] = "";
          setObj({ ...obj });
        });
      }
      if (
        props.defaultFields.placeholderList &&
        props.defaultFields.signaturePlaceholderList?.length > 0
      ) {
        props.defaultFields.signaturePlaceholderList.map((key) => {
          signObj[key.Name] = "";
          setSignObj({ ...signObj });
        });

        props.defaultFields.signaturePlaceholderList.map((key) => {
          signName[key.Name] = "";
          setSignName({ ...signName });
        });

        props.defaultFields.signaturePlaceholderList.map((key) => {
          email[key.email] = "";
          setEmail({ ...email });
        });
      }
      

      if (
        props.defaultFields.clientdata &&
        props.defaultFields.clientdata?.length > 0 
      ) {
        sessionStorage.setItem(
          "companyname",
          props.defaultFields.clientdata[0].company_name
        );
        sessionStorage.setItem(
          "registration_no",
          props.defaultFields.clientdata[0].registration_no
        );
        sessionStorage.setItem("current_date", moment().format("DD/MM/YYYY"));
        sessionStorage.setItem(
          "address",
          props.defaultFields.clientdata[0].address
        );
        changeFunc(sessionStorage.getItem("companyname"), "company_name");
        changeFunc(
          sessionStorage.getItem("registration_no"),
          "registration_no"
        );
        changeFunc(sessionStorage.getItem("address"), "address");
        changeFunc(sessionStorage.getItem("current_date"), "current_date");
        if (sessionStorage.getItem("director_name1") != null) {
          changeFunc(
            sessionStorage.getItem("director_name1"),
            "director_name1"
          );
        }
        if (sessionStorage.getItem("director_name2") != null) {
          changeFunc(
            sessionStorage.getItem("director_name2"),
            "director_name2"
          );
        }
        if (sessionStorage.getItem("director_name3") != null) {
          changeFunc(
            sessionStorage.getItem("director_name3"),
            "director_name3"
          );
        }
        if (sessionStorage.getItem("director_name4") != null) {
          changeFunc(
            sessionStorage.getItem("director_name4"),
            "director_name4"
          );
        }
        if (sessionStorage.getItem("shareholder_name1") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name1"),
            "shareholder_name1"
          );
        }
        if (sessionStorage.getItem("shareholder_name2") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name2"),
            "shareholder_name2"
          );
        }
        if (sessionStorage.getItem("shareholder_name3") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name3"),
            "shareholder_name3"
          );
        }
        if (sessionStorage.getItem("shareholder_name4") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name4"),
            "shareholder_name4"
          );
        }

        if (sessionStorage.getItem("secratory_name1") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name1"),
            "secratory_name1"
          );
        }

        if (sessionStorage.getItem("secratory_name2") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name2"),
            "secratory_name2"
          );
        }

        if (sessionStorage.getItem("secratory_name3") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name3"),
            "secratory_name3"
          );
        }

        if (sessionStorage.getItem("secratory_name4") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name4"),
            "secratory_name4"
          );
        }
      }


      if (
        props.defaultFields.officebearerdata &&
        props.defaultFields.officebearerdata?.length > 0
      ) {
        setDirectorList(props.defaultFields.officebearerdata);
        props.defaultFields.officebearerdata.map((key, inx) => {
          var jinx = inx + 1;
          var directorfield = "director_name" + jinx;
          sessionStorage.setItem(directorfield, key.name);
        });
      }
      if (
        props.defaultFields.officebearerdata_shareholder &&
        props.defaultFields.officebearerdata_shareholder?.length > 0
      ) {
        console.log(
          "officebearerdata_shareholder",
          props.defaultFields.officebearerdata_shareholder
        );
        setShareHolderList(props.defaultFields.officebearerdata_shareholder);
        props.defaultFields.officebearerdata_shareholder.map((key, inx) => {
          var jinx = inx + 1;
          var directorfield = "shareholder_name" + jinx;
          sessionStorage.setItem(directorfield, key.name);
        });
      }
      if (
        props.defaultFields.officebearerdata_secratory &&
        props.defaultFields.officebearerdata_secratory?.length > 0
      ) {
        setSecratoryList(props.defaultFields.officebearerdata_secratory)
        props.defaultFields.officebearerdata_secratory.map((key, inx) => {
          var jinx = inx + 1;
          var directorfield = "secratory_name" + jinx;
          sessionStorage.setItem(directorfield, key.name);
        });
      }
    }

    if (props.editDocumentModal == true) {
      console.log("chinnu props.defaultFields ")
      assignDefaultFieldValues();
    }
  }, [props.defaultFields]);

  const changeFunc = (e, val) => {
    let placeholder_list = {};
    console.log("change-->", e, val);
    if (val != undefined && val != null) {
      obj[val] = e;
      setObj({ ...obj });
    }
  };

  const changeSignFunc = (e, val) => {
    let placeholder_list = {};
    if (val != undefined && val != null) {
      signObj[val] = e;
      setSignObj({ ...signObj });
    }
  };

  const saveChangesFunc = (e) => {
    e.preventDefault();
    console.log("object", obj);
    let input = {
      document_type: props.selectDocumentType,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 1,
      htmldata: edited,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      
      selectedid: props.selectedid,

    };

    let editInput = {
      document_id: props.document_id,
      document_type: props.selectDocumentType,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      attachment_keyword: "",
      notes: "",
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 1,
      htmldata: edited,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      selectedid: props.selectedid,
    };

    console.log("insert pa", input);
    
    if (props.editDocumentModal == true) {
      console.log("edit pa", editInput);
      props.editDocument(editInput, "draft", props.tabSelected);
    } else {
      console.log("insert pa", input);
      props.sendAndSaveDocument(
        input,
        "draft",
        props.tabSelected,
        templateData,
        temName
      );
    }
  };
  // console.log(props.selectedid, "selectedid EGM");
  const previewFunc = (e) => {
    // e.preventDefault();
    setLoading(true);
    let input = {
      document_type: props.selectDocumentType,
      generate_doc: true,
      file_url: props.docPreview,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
    };
    if(props.selectDocumentType !== ""){
      props.sendDocTypeAndGetDocument(props.selectDocumentType, setRendr);
      setUrl2("");
    }else{
      setLoading(false);
    }

    // props.getDocumentPreview(input, setRendr);
  };
  // useEffect(() => {
  //   if(props.selectDocumentType !== ""){
  //     props.sendDocTypeAndGetDocument(props.selectDocumentType, setRendr);
  //     setUrl2("");
  //   }
  //   //previewFunc2();
  // }, [obj]);

  
  useEffect(() => {
    if (url2 == "" && props.selectDocumentType !== "" && !_.isEmpty(obj)) {
      previewFunc2();
      setUrl2("URLUpdated");
    }
  }, [props.docPreview]);
  useEffect(() => {
    if (url2 == "" && props.selectDocumentType !== "" && !_.isEmpty(obj)) {
      previewFunc2();
      setUrl2("URLUpdated");
    }
  }, [signObj]);
  const previewFunc2 = () => {
    if(props.docPreview){
    setLoading(true);
    let input = {
      document_type: props.selectDocumentType,
      generate_doc: true,
      file_url: props.docPreview,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
    };
    props.getDocumentPreview(input, setRendr, setLoading);
  }
  };
  const sendNewFuncMultipledocument = () => {
    setTimeout(() => {
      let ass = Object.values(obj);
      let allids = props.to;
      allids.forEach((element) => {
        let id = element.value;
        let check = ass.find((ele) => {
          return ele === "";
        });
        console.log("transerro", transferor);

        let check2 = false;
        if (signObj) {
          let ass1 = Object.values(signObj);
          let findErr = ass1.find((ele) => {
            return ele === "";
          });
          check2 = findErr ? false : true;
        } else {
          check2 = true;
        }
        console.log("check", check, "check2", check2);
        // if (check == undefined) {
        let input = {
          document_type: id,
          user_id: sessionStorage.getItem("login_userid"),
          client_id: sessionStorage.getItem("personalId"),
          status: 5,
          placeholder_list: obj,
          sign_placeholder_list: signObj,
          transferor_list: transferor,
          receiver_list: receivers,
          shareholdername_list: shareHolder,
          selectedid: props.selectedid,
        };
        props.sendAndSaveDocument_multiple(input, "save", props.tabSelected);
      });
    }, 1000);
  };

  const sendNewFunc = (e) => {
    
    console.log("🚀 ~ handleNameChange ~ email:", email)

    
    e.preventDefault();

    let ass = Object.values(obj);

    console.log("obj", obj);
    let check = ass.find((ele) => {
      return ele === "";
    });
    console.log("transerro", transferor);

    let check2 = false;
    if (signObj) {
      let ass1 = Object.values(signObj);
      let findErr = ass1.find((ele) => {
        return ele === "";
      });
      check2 = findErr ? false : true;
    } else {
      check2 = true;
    }
    console.log("check", check, "check2", check2);
    // if (check == undefined) {
    let input = {
      document_type: props.selectDocumentType,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 6,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      selectedid: props.selectedid,
      isCreateTemplate: isCreateTemplate,
      
      
    };


    if (props.selectDocumentType === 937) {

    let other_input = {
      document_type: props.selectDocumentType,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 6,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      selectedid: props.selectedid,
      isCreateTemplate: isCreateTemplate,
      sign_name_list: markers,
      email:email,
      file:document, }

    console.log("🚀 ~ sendNewFunc ~ other_input:", other_input)

      
    
       // Create a FormData object 

        let originalFileName = "";

        const formData = new FormData();
    
         originalFileName = document?.name;

         if (!(finalFile instanceof Blob)) {
          alert("Preview the File After Submitting");
          return; 
      }
    
      
      formData.append("file", finalFile, originalFileName);
      formData.append("document_type", other_input.document_type);
      formData.append("user_id", other_input.user_id);
      formData.append("client_id", other_input.client_id);
      formData.append("status", other_input.status);
      formData.append("emails", JSON.stringify(other_input.email));
      formData.append("placeholder_list", JSON.stringify(other_input.placeholder_list));
      formData.append("sign_placeholder_list", JSON.stringify(other_input.sign_placeholder_list));
      formData.append("transferor_list", JSON.stringify(other_input.transferor_list));
      formData.append("receiver_list", JSON.stringify(other_input.receiver_list));
      formData.append("shareholdername_list", JSON.stringify(other_input.shareholdername_list));
      formData.append("selectedid", other_input.selectedid);
      formData.append("isCreateTemplate", other_input.isCreateTemplate);
      formData.append("sign_name_list", JSON.stringify(other_input.sign_name_list));
      formData.append("save", "save");
      formData.append("tabSelected", props.tabSelected);
      formData.append("temName", temName);
    
const dummy = "save";
      // val, dummy, tab, templateData, temName

     
        fetch(config.otherSendAndSaveDocument, {
          method: "POST",
          body: formData,
         
        })
          .then((response) => response.json())
          .then((responseData) => {
            if (responseData.status === 1) {
              
              dispatch(documentFunction("transferOfShareModal", false));
          dispatch(documentFunction("document_id", responseData.document_id));
          dispatch(actions.multipleidArray( responseData.document_id));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
            if (dummy == "save") {
              dispatch(documentFunction("sendRequestModal", true));
            }
            if (props.tabSelected == "ALL") {
              dispatch(actions.getDocumentList());
            } else if (props.tabSelected == "Pending") {
              dispatch(actions.getDocumentList(1));
            } else if (props.tabSelected == "Received") {
              dispatch(actions.getDocumentList(2));
            }
          }, 2500);

            }
          })



          // .then((responseData) => {
          //   if (responseData) {
             
          //     setTimeout(() => {
          //       if (dummy == "save") {
          //         dispatch(documentFunction("sendRequestModal", true));
          //       }
          //       if (props.tabSelected == "ALL") {
          //         dispatch(getDocumentList());
          //       } else if (props.tabSelected == "Pending") {
          //         dispatch(getDocumentList(1));
          //       } else if (props.tabSelected == "Received") {
          //         dispatch(getDocumentList(2));
          //       }
          //     }, 2500);
          //     // dispatch(documentFunction("docPreview", responseData.url));
          //     // dispatch(documentFunction("selectDocumentModal", false));
          //     // dispatch(documentFunction("transferOfShareModal", true));
          //   } else {
          //     // dispatch(documentFunction("selectDocumentModal", false));
          //     // dispatch(documentFunction("transferOfShareModal", true));
          //     dispatch(changeClientInfo("clientApiStatus", 2));
          //     dispatch(changeClientInfo("message", "Add file"));
          //     setTimeout(() => {
          //       dispatch(changeClientInfo("clientApiStatus", 0));
          //       dispatch(changeClientInfo("message", ""));
          //     }, 2500);
          //   }
          // })


          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
  props.sendAndSaveDocument(
    input,
    "save",
    props.tabSelected,
    templateData,
    temName
  );
}

  

   
    // props.sendValues("sendRequestModal", true)
    // } else {
    //   props.changeClientInfo("clientApiStatus", 2);
    //   props.changeClientInfo("message", "Please Fill All Required Field");
    //   setTimeout(() => {
    //     props.changeClientInfo("clientApiStatus", 0);
    //     props.changeClientInfo("message", "");
    //   }, 2500);
    // }
  };
  
  const nextFunction = (e) => {
    e.preventDefault();
    let allids = props.to;
    let currentindex = props.nextValue - 1;
    let newIndex = currentindex + 1;
    let increaseIndex = currentindex + 2;
    let documentid = allids[currentindex].value;
    let ass = Object.values(obj);
    console.log("obj", obj);
    let check = ass.find((ele) => {
      return ele === "";
    });
    console.log("transerro", transferor);

    let check2 = false;
    if (signObj) {
      let ass1 = Object.values(signObj);
      let findErr = ass1.find((ele) => {
        return ele === "";
      });
      check2 = findErr ? false : true;
    } else {
      check2 = true;
    }
    console.log("check", check, "check2", check2);
    // if (check == undefined) {
    let input = {
      document_type: documentid,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 6,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      selectedid: props.selectedid,
    };
    props.sendValues("nextValue", increaseIndex);

    props.sendAndSaveDocumentNext(
      input,
      "save",
      props.tabSelected,
      templateData,
      temName
    );
    if (props.nextValue < props.to?.length) {
      props.getDefaultPlaceHolder(allids[newIndex].value);
      props.sendValues("selectDocumentType", allids[newIndex].value);
      previewFunc2();
    }
  };

  const receiversAddFunc = () => {
    let arr = [...receivers];
    let add = { receiver_name: "", no_of_shares_transfered: "" };
    arr.push(add);
    setReceivers([...arr]);
  };

  const removeReceiverFunc = (ind) => {
    let arr = [...receivers];
    if (arr?.length > 1) {
      arr.splice(ind, 1);
      setReceivers([...arr]);
    }
  };

  const addReceiverDetails = (e, idx) => {
    let arr = [...receivers];
    arr[idx][e.target.name] = e.target.value;
    setReceivers([...arr]);
  };

  const addTransferorDetails = (e) => {
    let obj = { ...transferor };
    obj[e.target.name] = e.target.value;
    setTransferor({ ...obj });
  };

  const addShareHolder = () => {
    let arr = [...shareHolder];
    let add = { shareholder_name: "" };
    arr.push(add);
    setShareHolder([...arr]);
  };

  const addShareHolderDetails = (e, idx) => {
    let arr = [...shareHolder];
    arr[idx][e.target.name] = e.target.value;
    setShareHolder([...arr]);
  };

  const removeShareHoderFunc = (idx) => {
    let arr = [...shareHolder];
    if (arr?.length > 1) {
      arr.splice(idx, 1);
      setShareHolder([...arr]);
    }
  };




{/* ============================================== ==============================================  ==============================================  */}

// const handleFileUpload = async (event) => {
//   const file = event.target.files[0];
//   if (file && file.type === "application/pdf") {
//     const fileUrl = URL.createObjectURL(file);
//     setFileUrl(fileUrl);
//     setDocument(file);
//   } else {
//     alert("Please upload a PDF document.");
//   }
// };

// const handleDesignationChange = (event) => {
//   const newDesignation = event.target.value;
//   setSelectedDesignation(newDesignation);
//   setSelectedOption(""); 
//   setSelectedName(""); 
//   setNewMarkerLabel("");

//   // Automatically update the name dropdown options based on the selected designation
//   if (newDesignation === "Director") {
//     setOptionsNames(directorList);
//   } else if (newDesignation === "Shareholder") {
//     setOptionsNames(shareHolderList);
//   } else if (newDesignation === "Secretary") {
//     setOptionsNames(secratoryList);
//   }
// };


//   const handleOptionChange = (event) => {
//     const selectedOptionValue = event.target.value;
//     setSelectedOption(selectedOptionValue);
  
//     const selectedOption = optionsByDesignation[selectedDesignation]?.find(option => option.value === selectedOptionValue);
  
//     updateMarkerLabel(selectedOptionValue, selectedName);
    
//     if (selectedOption) {
//       setSelectedSign(selectedOption.sign);
//       setSignObj(prev => ({
//         ...prev,
//         [selectedOption.sign]: '',
//       }));
//     }
//   };
  

//   const handleNameChange = (event) => {
//     const selectedNameValue = event.target.value;
//     console.log("🚀 ~ handleNameChange ~ selectedNameValue:", selectedNameValue);
  
//     // Find the selected person based on the name
//     const selectedPerson = optionsNames?.find(option => option.name === selectedNameValue);
//     console.log("🚀 ~ handleNameChange ~ selectedPerson:", selectedPerson);
  
//     // Extract the email from the selected person
//     const select_email = selectedPerson?.email;
  
//     // Update the state with the new email
//     setEmail(prevEmails => {
//       console.log("🚀 ~ handleNameChange ~ prevEmails:", prevEmails);
      
//       // Ensure prevEmails is an array
//       const emailsArray = Array.isArray(prevEmails) ? prevEmails : [];
      
//       const emailExists = emailsArray.some(emailObj => emailObj.email === select_email);
    
//       if (!emailExists && select_email) {
//         return [...emailsArray, { email: select_email, signed: false }];
//       }
    
//       return emailsArray;
//     });
    
//     console.log("🚀 ~ handleNameChange ~ email:", email)


  
//     // Update other states as needed
//     setSelectedName(selectedNameValue);
//     updateMarkerLabel(selectedOption, selectedNameValue);
//   };
  
//   const handleMarkerDragStart = (event, marker) => {
//     setCurrentMarker(marker);
//     event.dataTransfer.setDragImage(new Image(), 0, 0); // Hide the default drag image
//   };
  
//   const handleMarkerDrag = (event) => {
//     if (!currentMarker) return;
  
//     const rect = event.currentTarget.getBoundingClientRect();
//     const x = ((event.clientX - rect.left) / rect.width) * 100;
//     const y = ((event.clientY - rect.top) / rect.height) * 100;
  
//     setMarkers((prevMarkers) =>
//       prevMarkers.map((m) =>
//         m.id === currentMarker.id ? { ...m, x: Math.min(Math.max(x, 0), 100), y: Math.min(Math.max(y, 0), 100) } : m
//       )
//     );
//   };
  
//   const handleMarkerDragEnd = () => {
//     setCurrentMarker(null);
//   };
  



// const updateMarkerLabel = (option, name) => {

//   if (selectedDesignation && option && name) {
//     const selectObject = {
//       name: name,
//       value: option
//     };

//     setPersons((prevPersons) => {
//       const existingPersonIndex = prevPersons.findIndex(
//         (person) => person.value === option
//       );

//       if (existingPersonIndex !== -1) {
//         const updatedPersons = [...prevPersons];
//         updatedPersons[existingPersonIndex] = selectObject;
//         return updatedPersons;
//       }

//       return [...prevPersons, selectObject];
//     });

//     setNewMarkerLabel(`${option} - ${name}`);
//   }
// };


// const handleAddMarkerButtonClick = () => {
//   setIsAddingMarker(true);
// };

// const handleDocumentClick = (event) => {
//   if (!isAddingMarker || !newMarkerLabel.trim()) {
//     return;
//   }

//   const rect = event.currentTarget.getBoundingClientRect();
//   const x = ((event.clientX - rect.left) / rect.width) * 100;
//   const y = ((event.clientY - rect.top) / rect.height) * 100;

//   // director_name1    shareholder_name1

// const signMarkerLetter = `{{${selectedSign}}}`

//   const newMarker = {
//     id: Date.now(),
//     x,
//     y,
//     label: newMarkerLabel,
//     role: selectedDesignation,
//     designation: selectedOption,
//     name: selectedName,
//     signature: signMarkerLetter,
//   };

//   console.log("New Marker Details:", newMarker);

//   setMarkers([...markers, newMarker]);
//   resetMarkerForm();
// };

// const resetMarkerForm = () => {
//   setIsAddingMarker(false);
//   setSelectedDesignation("");
//   setSelectedOption("");
//   setSelectedName("");
//   setNewMarkerLabel("");
// };

// const handleRemoveMarker = (id) => {
//   setMarkers(markers.filter((marker) => marker.id !== id));
// };

// console.log("🚀 ~ nextFunction ~ props.selectDocumentType !==  937:", props.selectDocumentType !==  937)

// const savePdfWithMarkers = async () => {
//   console.log("🚀 ~ savePdfWithMarkers ~ savePdfWithMarkers:");
//   console.log("🚀 ~ sendNewFunc ~ email:", email);

//   if (!document) {
//       alert("No PDF document available.");
//       return;
//   }

//   try {
//       // Load the PDF document
//       const pdfDoc = await PDFDocument.load(await document.arrayBuffer());
//       const pages = pdfDoc.getPages();
//       const page = pages[0];

//       // Embed the image for markers
//       const markerImageBytes = await fetch(MarkerImg01).then(res => res.arrayBuffer());
//       const markerImage = await pdfDoc.embedPng(markerImageBytes);

//       const { width, height } = page.getSize();
//       console.log(`Page dimensions - Width: ${width}, Height: ${height}`);

//       markers.forEach((marker) => {
//           const x = (marker.x / 100) * width;
//           const y = (marker.y / 100) * height;

//           // Draw the marker image
//           page.drawImage(markerImage, {
//               x: x - 20, // Centering image horizontally
//               y: y + 160, // Adjust positioning vertically
//               width: 20,
//               height: 20,
//           });

//           // Draw the marker label or sign
//           page.drawText(marker.sign || marker.label, {
//               x: x - 20, // Centering text horizontally
//               y: y + 140, // Position text below image
//               size: 12,
//               color: rgb(0, 0, 0),
//           });
//       });

//       // Save the modified PDF
//       const pdfBytes = await pdfDoc.save();
//       const blob = new Blob([pdfBytes], { type: "application/pdf" });

//       // Set the final file state as a Blob
//       setFinalFile(blob);

//       // Ensure the state is updated before proceeding
//       setTimeout(() => {
//           if (blob) {
//               // Trigger sendNewFunc after PDF review
//               sendNewFunc(new Event('submit'));
//           } else {
//               alert("Review the Document After Submit");
//           }
//       }, 0);

//   } catch (error) {
//       console.error("Error saving PDF with markers:", error);
//       alert("An error occurred while saving the PDF.");
//   }
// };


const handleFileUpload = async (event) => {
  const file = event.target.files[0];
  if (file && file.type === "application/pdf") {
    const fileUrl = URL.createObjectURL(file);
    setFileUrl(fileUrl);
    setDocument(file);
  } else {
    alert("Please upload a PDF document.");
  }
};

const handleDesignationChange = (event) => {
  const newDesignation = event.target.value;
  setSelectedDesignation(newDesignation);
  setSelectedOption("");
  setSelectedName("");
  setNewMarkerLabel("");

  if (newDesignation === "Director") {
    setOptionsNames(directorList);
  } else if (newDesignation === "Shareholder") {
    setOptionsNames(shareHolderList);
  } else if (newDesignation === "Secretary") {
    setOptionsNames(secratoryList);
  }
};

const handleOptionChange = (event) => {
  const selectedOptionValue = event.target.value;
  setSelectedOption(selectedOptionValue);

  const selectedOption = optionsByDesignation[selectedDesignation]?.find(option => option.value === selectedOptionValue);

  updateMarkerLabel(selectedOptionValue, selectedName);

  if (selectedOption) {
    setSelectedSign(selectedOption.sign);
  }
};

const handleNameChange = (event) => {
  const selectedNameValue = event.target.value;
  const selectedPerson = optionsNames?.find(option => option.name === selectedNameValue);
  const select_email = selectedPerson?.email;
  setSelectEmail(select_email?.email);

  setEmail(prevEmails => {
    const emailsArray = Array.isArray(prevEmails) ? prevEmails : [];
    const emailExists = emailsArray.some(emailObj => emailObj.email === select_email);

    if (!emailExists && select_email) {
      return [...emailsArray, { email: select_email, signed: false }];
    }

    return emailsArray;
  });

  setSelectedName(selectedNameValue);
  updateMarkerLabel(selectedOption, selectedNameValue);
};

const handleMarkerDragStart = (event, marker) => {
  setCurrentMarker(marker);
  event.dataTransfer.setData('markerId', marker.id);
};

const handleMarkerDrag = (event) => {
  event.preventDefault();
};

const handleMarkerDrop = (event) => {
  const markerId = event.dataTransfer.getData('markerId');
  const marker = markers.find(m => m.id === parseInt(markerId));

  if (marker) {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;

    setMarkers(prevMarkers =>
      prevMarkers.map(m =>
        m.id === marker.id ? { ...m, x: Math.min(Math.max(x, 0), 100), y: Math.min(Math.max(y, 0), 100) } : m
      )
    );
  }
};

const handleMarkerDragEnd = () => {
  setCurrentMarker(null);
};

const updateMarkerLabel = (option, name) => {
  if (selectedDesignation && option && name) {
    const selectObject = {
      name: name,
      value: option
    };

    setPersons(prevPersons => {
      const existingPersonIndex = prevPersons.findIndex(person => person.value === option);

      if (existingPersonIndex !== -1) {
        const updatedPersons = [...prevPersons];
        updatedPersons[existingPersonIndex] = selectObject;
        return updatedPersons;
      }

      return [...prevPersons, selectObject];
    });

    setNewMarkerLabel(`${option} - ${name}`);
  }
};

const handleAddMarkerButtonClick = () => {
  setIsAddingMarker(true);
};

const handleMouseMove = (event) => {
  if (draggingMarker) {
    const rect = viewerRef.current.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;

    setMarkers(markers.map(m => (m.id === draggingMarker.id ? { ...m, x, y } : m)));
    setLiveX(x);
    setLiveY(y);
  }
};

const handleDocumentClick = (event) => {
  if (!isAddingMarker || !newMarkerLabel.trim()) {
    return;
  }

  const rect = event.currentTarget.getBoundingClientRect();

  const x = ((event.clientX - rect.left) / rect.width) * 100;
  const y = ((event.clientY - rect.top) / rect.height) * 100;

  const signMarkerLetter = `{{${selectedSign}}}`;

  const selectedPerson = optionsNames?.find(option => option.name === selectedName);
  const select_email = selectedPerson?.email;

  const newMarker = {
    id: Date.now(),
    x,
    y,
    label: newMarkerLabel,
    role: selectedDesignation,
    designation: selectedOption,
    name: selectedName,
    signature: signMarkerLetter,
    pageIndex,
    email:select_email,
  };
  console.log("🚀 ~ handleDocumentClick ~ newMarker:", newMarker)

  setMarkers([...markers, newMarker]);
  resetMarkerForm();
};

const resetMarkerForm = () => {
  setIsAddingMarker(false);
  setSelectedDesignation("");
  setSelectedOption("");
  setSelectedName("");
  setNewMarkerLabel("");
};

const handleRemoveMarker = (id) => {
  setMarkers(markers.filter((marker) => marker.id !== id));
};

  // Handle page change and log the current page index
  const handlePageChange = (e) => {
    setPageIndex(e.currentPage);  // Save the current page index
    console.log("Current Page Index:", e.currentPage); // Log the page index
    console.log("🚀 ~ handlePageChange ~ pageIndex:", pageIndex)

  };

  const savePdfWithMarkers = async () => {
    if (!document) {
      alert("No PDF document available.");
      return;
    }
  
    try {
      const pdfDoc = await PDFDocument.load(await document.arrayBuffer());
      const pages = pdfDoc.getPages();
  
      const markerImageBytes = await fetch(MarkerImg01).then(res => res.arrayBuffer());
      const markerImage = await pdfDoc.embedPng(markerImageBytes);
     

     
  
      markers.forEach((marker) => {
        if (marker.pageIndex >= pages.length || marker.pageIndex < 0) {
          console.error(`Page index ${marker.pageIndex} is out of bounds.`);
          return;
        }
  
        
        const page = pages[marker.pageIndex];

        if (!page || typeof page.drawImage !== 'function') {
          console.error(`Page ${marker.pageIndex} does not support drawImage.`);
          return;
        }
  
        const { width, height } = page.getSize() ;
        console.log("🚀 ~ markers.forEach ~ height:", height)
        console.log("🚀 ~ markers.forEach ~ width:", width)
  
        const x = (marker.x / 100) * width;
        const y = height - ((marker.y / 100) * height);
  
        // Draw the image on the page
        page.drawImage(markerImage, {
          x: x - 120, // Adjust x to center the image correctly
          y: y - 300, // Adjust y to center the image correctly
          width: 20,
          height: 20,
        });
  
        // Optionally draw text (signature) near the marker
        // Uncomment the following lines if you want to add text
        // page.drawText(marker.signature || marker.label, {
        //   x: x - 10, // Adjust x position for text
        //   y: y - 30, // Adjust y position for text
        //   size: 12,
        //   color: rgb(0, 0, 0),
        // });



      });
  
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
  
      setFinalFile(blob); // Ensure this is used appropriately in your component
  
      // Provide feedback to user about the file
      // const pdfUrl = URL.createObjectURL(blob);
      // window.open(pdfUrl, '_blank');

 // Ensure the state is updated before proceeding
      setTimeout(() => {
          if (blob) {
              sendNewFunc(new Event('submit'));
          } else {
              alert("Review the Document After Submit");
          }
      }, 0);

      // sendNewFunc(new Event('submit'));
    } catch (error) {
      console.error("Error saving PDF with markers:", error);
      if (window.confirm("An error occurred while saving the PDF. Do you want to reload the page?")) {
        window.location.reload(); // Reload the page if the user clicks "OK"
      }
      
    }
  };
  
  
  
  
  



{/* ============================================== ==============================================  ==============================================  */}


  return (
    <Modal
      show={props.transferOfShareModal}
      id="myModal"
      className="modal fade in"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-dialog modal-xl"
      style={{ display: "block" }}
    >
      <div className="">
        <div className="modal-content ">
          <div className="modal-header ">
            {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> */}
            <h4 className="modal-title blue-text">Update Document Placeholder Values</h4>
          </div>
          <div className="modal-body pb-0">
            <div className="row">
              <div className="col-sm-6 border-right-gray">
                {props.defaultFields &&
                  props.defaultFields.placeholderList?.map((fie, idx) => {                   
                    let companynamevalue = "";
                    if (fie.Name == "company_name") {
                      companynamevalue = sessionStorage.getItem("companyname");
                    }
                    if (fie.Name == "address") {
                      companynamevalue = sessionStorage.getItem("address");
                    }
                    if (fie.Name == "current_date") {
                      companynamevalue = sessionStorage.getItem("current_date");
                    }
                    if (fie.Name == "registration_no") {
                      companynamevalue =
                        sessionStorage.getItem("registration_no");
                    }
                    if (fie.Name == "director_name1") {
                      companynamevalue =
                        sessionStorage.getItem("director_name1");
                    }
                    if (fie.Name == "director_name2") {
                      companynamevalue =
                        sessionStorage.getItem("director_name2");
                    }
                    if (fie.Name == "director_name3") {
                      companynamevalue =
                        sessionStorage.getItem("director_name3");
                    }
                    if (fie.Name == "director_name4") {
                      companynamevalue =
                        sessionStorage.getItem("director_name4");
                    }
                    if (fie.Name == "shareholder_name1") {
                      companynamevalue =
                        sessionStorage.getItem("shareholder_name1");
                    }
                    if (fie.Name == "shareholder_name2") {
                      companynamevalue =
                        sessionStorage.getItem("shareholder_name2");
                    }
                    if (fie.Name == "shareholder_name3") {
                      companynamevalue =
                        sessionStorage.getItem("shareholder_name3");
                    }
                    if (fie.Name == "shareholder_name4") {
                      companynamevalue =
                        sessionStorage.getItem("shareholder_name4");
                    }
                    if (fie.Name == "secratory_name1") {
                      companynamevalue =
                        sessionStorage.getItem("secratory_name1");
                    }
                    if (fie.Name == "secratory_name2") {
                      companynamevalue =
                        sessionStorage.getItem("secratory_name2");
                    }
                    if (fie.Name == "secratory_name3") {
                      companynamevalue =
                        sessionStorage.getItem("secratory_name3");
                    }
                    if (fie.Name == "secratory_name4") {
                      companynamevalue =
                        sessionStorage.getItem("secratory_name4");
                    }

                    return fie.Name.includes("director_name") ? (
                      <div className="form-group" key={idx}>
                        <label
                          htmlFor="companyname"
                          className="control-label col-sm-4 required"
                        >
                          {_.startCase(fie.Name)}
                          <p className="help-block">(* Required) </p>
                        </label>
                        <div className="col-sm-8">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              changeFunc(e.target.value, fie.Name)
                            }
                            required="required"
                            name="companyname"
                            id="companyname"
                            defaultValue={companynamevalue}
                          >
                            <option defaultValue selected="selected"> Choose a Director </option>
                            {directorList.map((data, i) => {
                              return (
                                <option value={data?.name}>
                                  {data?.name}
                                </option>
                              );
                            })}
                          </select>
                          <p className="help-block">Pre populated</p>
                        </div>
                      </div>
                    ) : fie.Name.includes("shareholder_name") ? (
                      <div className="form-group" key={idx}>
                        <label
                          htmlFor="companyname"
                          className="control-label col-sm-4 required"
                        >
                          {_.startCase(fie.Name)}
                          <p className="help-block">(* Required) </p>
                        </label>
                        <div className="col-sm-8">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              changeFunc(e.target.value, fie.Name)
                            }
                            required="required"
                            name="companyname"
                            id="companyname"
                            defaultValue={companynamevalue}
                          >
                            <option defaultValue selected="selected"> Choose a ShareHolder </option>
                            {shareHolderList.map((data, i) => {
                              return (
                                <option value={data?.name}>
                                  {data?.name}
                                </option>
                              );
                            })}
                          </select>
                          <p className="help-block">Pre populated</p>
                        </div>
                      </div>
                    ) : fie.Name.includes("secratory_name") ? <div className="form-group" key={idx}>
                    <label
                      htmlFor="companyname"
                      className="control-label col-sm-4 required"
                    >
                      {_.startCase(fie.Name)}
                      <p className="help-block">(* Required) </p>
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        onChange={(e) =>
                          changeFunc(e.target.value, fie.Name)
                        }
                        required="required"
                        name="companyname"
                        id="companyname"
                        defaultValue={companynamevalue}
                      >
                        <option defaultValue selected="selected"> Choose a Secratory </option>
                        {secratoryList.map((data, i) => {
                          return (
                            <option value={data?.name} selected="selected">
                              {data?.name}
                            </option>
                          );
                        })}
                      </select>
                      <p className="help-block">Pre populated</p>
                    </div>
                  </div> : (
                      <div className="form-group" key={idx}>
                        <label
                          htmlFor="companyname"
                          className="control-label col-sm-4 required"
                        >
                          {_.startCase(fie.Name)}
                          <p className="help-block">(* Required) </p>
                        </label>
                        <div className="col-sm-8">
                          <input
                            className="form-control"
                            required="required"
                            name="companyname"
                            type="text"
                            // value={obj[fie.Name]}
                            id="companyname"
                            onChange={(e) =>
                              changeFunc(e.target.value, fie.Name)
                            }
                            defaultValue={companynamevalue}
                          />
                          <p className="help-block">Pre populated</p>
                        </div>
                      </div>
                    );
                  })}
{
  props.selectDocumentType !==  937 ? (


                <div className="col-sm-12 text-center">
                  <button
                    className="btn blue-border-btn mb-10"
                    onClick={() => previewFunc()}
                  >
                    {loading? <ColorRing
                              visible={true}
                              height="25"
                              width="25"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />:  "Get Document Preview"}
                   
                  </button>
                </div>):("")

}

{/* ============================================== ==============================================  ==============================================  */}

{/* {props.selectDocumentType ===  937 ? (  


<div className="form-group mb-3">
          <h3>Upload Document</h3>
          <input
  type="file"
  accept=".pdf"
  onChange={handleFileUpload}
  style={{
    "display": 'block',
    "width": '100%',
    "padding": '8px 12px',
    "fontSize": '14px',
    "color": '#333',
    "border": '1px solid #ccc',
    "borderRadius": '6px',
    "backgroundColor": '#f9f9f9',
    "cursor": 'pointer',
    "marginBottom": '12px',
    "outline": 'none',
  }}
/>

          <div className="my-5">
            <h3>Markers List</h3>
            <ul className="list-group mb-3">
              {markers.map((marker, index) => (
                <li key={marker.id} className="list-group-item d-flex justify-content-between align-items-center" style={{
                  "display": "flex",
    "justifyContent": "space-between",
    "alignItems": "center",
                }}>
                  <span className="d-flex gap-4 align-items-center" >
                    <b style={{
                      "marginRight":"10px"
                    }}>{index + 1}</b>
                    <span>{marker.label}</span>
                  </span>
                  <button
                    onClick={() => handleRemoveMarker(marker.id)}
                    className="btn btn-sm btn-danger"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
          
        </div>
        ):("") } */}

{props.selectDocumentType === 937 && (
        <div className="form-group mb-3">
          <h3>Upload Document</h3>
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileUpload}
            style={{
              display: 'block',
              width: '100%',
              padding: '8px 12px',
              fontSize: '14px',
              color: '#333',
              border: '1px solid #ccc',
              borderRadius: '6px',
              backgroundColor: '#f9f9f9',
              cursor: 'pointer',
              marginBottom: '12px',
              outline: 'none',
            }}
          />

          <div className="my-5">
            <h3>Markers List</h3>
            <ul className="list-group mb-3">
              {markers.map((marker, index) => (
                <li key={marker.id} className="list-group-item d-flex justify-content-between align-items-center">
                  <span className="d-flex gap-4 align-items-center">
                    <b style={{ marginRight: "10px" }}>{index + 1}</b>
                    <span>{marker.label}</span>
                  </span>
                  <button
                    onClick={() => handleRemoveMarker(marker.id)}
                    className="btn btn-sm btn-danger"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

{/* ============================================== ==============================================  ==============================================  */}


 

                <div className="form-group">
                  <div className="col-sm-1" />
                  <div className="col-sm-10">
                    <div className="vertical-border" />
                  </div>
                  <div className="col-sm-1" />
                </div>

                <div className="col-sm-12 text-center">
                  <button
                    className="btn black-border-btn mb-30"
                    onClick={() => saveChangesFunc}
                  >
                    {props.editDocumentModal == true
                      ? "Update changes"
                      : "Save changes"}
                  </button>
                </div>
              </div>
              <div className="col-sm-6 ">

{/* ============================================== ==============================================  ==============================================  */}

{/* {props.selectDocumentType ===  937 ? (  
<div className="border p-3 position-relative">
  <div style={{"width":"100%", "margin":"10px 0", "textAlign":"end"}}>
  <button
              onClick={handleAddMarkerButtonClick}
              disabled={!document || isAddingMarker}
              className="btn btn-success mb-3"

            >
              Add Marker
            </button>
  </div>
            

          
                   

  {isAddingMarker && (
        <div className="mb-3">
          <div className="">
          
            <div className="col-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <label className="form-label" style={{ fontSize: "16px" }}>
                Category:
              </label>
              <select
                value={selectedDesignation}
                onChange={handleDesignationChange}
                className="form-control"
                style={{
                  width: "70%",
                  padding: "5px 10px",
                  fontSize: "18px",
                  color: "black",
                  border: "1px solid lightgray",
                  marginBottom: "10px",
                }}
              >
                <option value="">Select a designation</option>
                {designations.map((designation) => (
                  <option key={designation} value={designation}>
                    {designation}
                  </option>
                ))}
              </select>
            </div>

      
            {selectedDesignation && (
              <div className="col-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <label className="form-label" style={{ fontSize: "16px" }}>
                  Designation:
                </label>
                <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className="form-control"
                  style={{
                    width: "70%",
                    padding: "5px 10px",
                    fontSize: "18px",
                    color: "black",
                    border: "1px solid lightgray",
                    marginBottom: "10px",
                  }}
                >
                  <option value="">Select an option</option>
                  {optionsByDesignation[selectedDesignation].map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

           
            {selectedDesignation && (
              <div className="col-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <label className="form-label" style={{ fontSize: "16px" }}>
                  Name:
                </label>
                <select
  value={selectedName}
  onChange={handleNameChange}
  className="form-control"
  style={{
    width: "70%",
    padding: "5px 10px",
    fontSize: "18px",
    color: "black",
    border: "1px solid lightgray",
    marginBottom: "10px",
  }}
>
  <option value="">Select a name</option>
  {optionsNames.map((data, i) => (
    <option key={i} value={data.name}>
      {data.name}
    </option>
  ))}
</select>

              </div>
            )}
          </div>
        </div>
      )}

            <h3 className="text-center" style={{
              "margin":"20px 10px"
            }}>Document Preview</h3>
            {fileUrl ? (
            <div style={{ width: "100%", height: "600px", overflow: "auto", position: "relative" }}>
            <div onClick={handleDocumentClick} style={{ cursor: "pointer", position: "relative" }}>
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
              </Worker>
              {markers.map((marker) => (
                <div
                  key={marker.id}
                  style={{
                    position: "absolute",
                    left: `${marker.x}%`,
                    top: `${marker.y}%`,
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <img
                    style={{
                      width: "18px",
                      height: "18px",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                    src={MarkerImg01}
                    alt="Marker"
                  />
                  <span
                    style={{
                      marginLeft: "5px",
                      padding: "2px",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    {marker.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
          
            ) : (
              <p>No document uploaded.</p>
            )}
          </div> ):("") } */}

{props.selectDocumentType === 937 && (
        <div className="border p-3 position-relative" ref={viewerRef}>
          <div style={{ width: "100%", margin: "10px 0", textAlign: "end" }}>
            <button
              onClick={handleAddMarkerButtonClick}
              disabled={!document || isAddingMarker}
              className="btn btn-success mb-3"
            >
              Add Marker
            </button>
          </div>


          <div  style={{ marginTop: "10px",  fontWeight: "bold" , padding:"10px", border:"1px solid gray" }} >
             Note: <span style={{  color: "red", marginLeft: "10px", }}> If Move the markers , Place of center to marker to  pick the center and drag to move it.</span>
           </div>
          {isAddingMarker && (
             <>
            
            <div className="mb-3">
              <div>
                {/* Designation Selection */}
                <div className="col-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <label className="form-label" style={{ fontSize: "16px" }}>
                    Category:
                  </label>
                  <select
                    value={selectedDesignation}
                    onChange={handleDesignationChange}
                    className="form-control"
                    style={{
                      width: "70%",
                      padding: "5px 10px",
                      fontSize: "18px",
                      color: "black",
                      border: "1px solid lightgray",
                      marginBottom: "10px",
                    }}
                  >
                    <option value="">Select a designation</option>
                    {designations.map((designation) => (
                      <option key={designation} value={designation}>
                        {designation}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Option Selection */}
                {selectedDesignation && (
                  <div className="col-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <label className="form-label" style={{ fontSize: "16px" }}>
                      Designation:
                    </label>
                    <select
                      value={selectedOption}
                      onChange={handleOptionChange}
                      className="form-control"
                      style={{
                        width: "70%",
                        padding: "5px 10px",
                        fontSize: "18px",
                        color: "black",
                        border: "1px solid lightgray",
                        marginBottom: "10px",
                      }}
                    >
                      <option value="">Select an option</option>
                      {optionsByDesignation[selectedDesignation].map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Name Selection */}
                {selectedDesignation && (
                  <div className="col-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <label className="form-label" style={{ fontSize: "16px" }}>
                      Name:
                    </label>
                    <select
                      value={selectedName}
                      onChange={handleNameChange}
                      className="form-control"
                      style={{
                        width: "70%",
                        padding: "5px 10px",
                        fontSize: "18px",
                        color: "black",
                        border: "1px solid lightgray",
                        marginBottom: "10px",
                      }}
                    >
                      <option value="">Select a name</option>
                      {optionsNames.map((data, i) => (
                        <option key={i} value={data.name}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div></>
          )}

          <h3 className="text-center" style={{ margin: "20px 10px" }}>Document Preview</h3>
          {fileUrl ? (
            <div style={{ width: "100%", height: "600px", overflow: "auto", position: "relative" }}>
              <div
                onClick={handleDocumentClick}
                onDragOver={(event) => event.preventDefault()} // Enable dropping
                onDrop={handleMarkerDrop} // Handle dropping
                style={{ cursor: "pointer", position: "relative" }}
              >
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <Viewer
                    fileUrl={fileUrl}
                    plugins={[defaultLayoutPluginInstance]}
                    onPageChange={handlePageChange}  
                  />
                </Worker>
                {markers.map((marker) => (
                  <div
                    key={marker.id}
                    style={{
                      position: "absolute",
                      left: `${marker.x}%`,
                      top: `${marker.y}%`,
                      transform: "translate(-50%, -50%)",
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      border:"1px solid gray",
                      borderRadius: "10px",
                      padding:"3px",
                    }}
                    draggable
                    onDragStart={(event) => handleMarkerDragStart(event, marker)}
                    onDrag={(event) => handleMarkerDrag(event)}
                    onDragEnd={handleMarkerDragEnd}
                  >
                    <img
                      style={{
                        width: "18px",
                        height: "18px",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                      src={MarkerImg01}
                      alt="Marker"
                    />
                    <span
                      style={{
                        marginLeft: "5px",
                        padding: "2px",
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    >
                      {marker.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p>No document uploaded.</p>
          )}
        </div>
      )}

{/* ============================================== ==============================================  ==============================================  */}


{
  props.selectDocumentType !==  937 ? ( 

    
                <div
                  className="mt-20 text-center"
                  style={{ minHeight: "728px" }}
                >
                  <h4 className="mb-25">Form Preview</h4>
                  {props?.fileJson && (
                    <DocumentEditor
                      fileJson={props?.fileJson}
                      setTemplateData={setTemplateData}
                      rendr={rendr}
                      setRendr={setRendr}
                    />
                  )}

                  {/* {props.docPreview && (
                    <>
                      <iframe
                        key={random}
                        src={`https://docs.google.com/viewer?url=http://13.214.35.227:4444${props.docPreview}&embedded=true`}
                        frameBorder="0"
                        width="100%"
                        id="iFrame"
                        title="doc-preview"
                        style={{ minHeight: "inherit" }}
                      />
                    </>
                  )} */}
                  <div className="form-group">
                    <div
                      className="col-sm-5 align-center justify-center"
                      style={{ justifyContent: "center", marginTop: "25px" }}
                    >
                      <input
                        type="checkbox"
                        className="check-box-active"
                        id="isCreateTemplate"
                        name="create_template"
                        value={isCreateTemplate}
                        onClick={(e) => {
                          setIsCreateTemplate(e.target.checked);
                        }}
                      />
                      <label
                        htmlFor="isCreateTemplate"
                        className="control-label pl-10 mb-0 required"
                      >
                        Create Template
                      </label>
                    </div>
                  </div>
                  {isCreateTemplate && (
                    <div className="form-group">
                      <div
                        className="col-sm-10 align-center justify-center"
                        style={{
                          justifyContent: "center",
                          marginTop: "5px",
                          marginLeft: "13px",
                        }}
                      >
                        <label
                          htmlFor="isCreateTemplate"
                          className="control-label col-sm-6 pl-12 mb-0 required"
                        >
                          Template Name
                        </label>
                        <input
                          type="text"
                          className="form-control col-sm-4"
                          name="templateName"
                          onChange={(e) => setTemName(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
  ):("")}

              </div>

              {
  props.selectDocumentType !==  937 ? ( 
              <div className="col-sm-12">


          

                {props.clientApiStatus ? (
                  <div
                    className={`alert ${
                      props.clientApiStatus === 1 ? "" : "alert-danger"
                    }`}
                    role="alert"
                  >
                    {props.clientApiStatus === 1 ? "" : props.message}
                  </div>
                ) : (
                  ""
                )}
              </div> 


):("")}


              <div
                className="col-sm-12 pt-20 pb-20 mt-20 flex-center-space"
                style={{ background: "rgba(92, 132, 149, 0.32)" }}
              >
                <button
                  className="btn transparent-btn"
                  onClick={() => {
                    props.sendValues("transferOfShareModal", false);
                    props.sendValues("defaultFields", []);
                    props.sendValues("multiple_document_id", []);
                    props.sendValues("editDocumentModal", false);
                    props.sendValues("document_id", "");
                    props.sendValues("selectDocumentType", "");
                    setObj({});
                  }}
                >
                  Cancel
                </button>
                {props?.lengtharray == props.nextValue && (
                  <>

{props.selectDocumentType ===  937 ? (  <button className="btn blue-btn" onClick={savePdfWithMarkers}>
  Goto Next
</button> ):(
  <button className="btn blue-btn" onClick={sendNewFunc}>
  Goto Next
</button>
)}
</>
                )}
                {props?.lengtharray != props.nextValue && (
                  <button className="btn blue-btn" onClick={(e) => nextFunction(e)}>
                    Next Document
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

}

// props.sendValues("sendRequestModal", true)
const mapStateToProps = (state, ownProps) => {
  const {
    transferOfShareModal,
    docPreview,
    defaultFields,
    selectDocumentType,
    to,
    multiple_document_id,
    lengtharray,
    nextValue,
    tabSelected,
    editDocumentModal,
    documentList,
    document_id,
    htmldata,
    fileJson,
  } = state.Document;

  return {
    transferOfShareModal,
    docPreview,
    htmldata,
    defaultFields,
    selectDocumentType,
    to,
    multiple_document_id,
    lengtharray,
    nextValue,
    shareHolderList: state.ShareReg.shareHolderList,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    tabSelected,
    editDocumentModal,
    documentList,
    document_id,
    selectedid: state.Client.selectedid,
    fileJson,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentPreview: (val, setRendr, setLoading) =>
      dispatch(actions.getDocumentPreview(val, setRendr, setLoading)),
    sendAndSaveDocument: (val, typ, tab, templateData, temName) =>
      dispatch(
        actions.sendAndSaveDocument(val, typ, tab, templateData, temName)
      ),
    sendAndSaveDocumentNext: (val, typ, tab, templateData, temName) =>
      dispatch(
        actions.sendAndSaveDocumentNext(val, typ, tab, templateData, temName)
      ),
    getDefaultPlaceHolder: (type) =>
      dispatch(actions.getDefaultPlaceHolderNext(type)),
    
    sendAndSaveDocument_multiple: (val, typ, tab) =>
      dispatch(actions.sendAndSaveDocument_multiple(val, typ, tab)),
    sendDocTypeAndGetDocument: (type, setRendr) =>
      dispatch(actions.sendDocTypeAndGetDocument_AutoPreview(type, setRendr)),
    editDocument: (val, typ, tab) =>
      dispatch(actions.editDocument(val, typ, tab)),
    getShareHolderList: () => dispatch(share.getShareHolderList()),
    changeClientInfo: (k, v) => dispatch(changeClientInfo(k, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferShares);
