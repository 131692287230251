import React, { Fragment, useEffect } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import "../administration/CompanySetups.css";
import { connect } from "react-redux";
import * as actions from "../../actions/UserManagement";
import User from "./User";
import Permission from "./Permission";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Individual from "./modals/IndividualPermisson"



function UserManagement(props) {

    useEffect(() => {
        window.$(document).ready(function () {
            window.$(".dropdown-toggle").dropdown();

            //console.log("template tab");
            window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
                localStorage.setItem("activeTab", $(e.target).attr("href"));
                console.log("selected tab :" + $(e.target).attr("href"));
            });

            var activeTab = localStorage.getItem("activeTab");

            if (activeTab) {
                window.$('#myTab a[href="' + activeTab + '"]').tab("show");
            }
        });


    }, []);
    return (
        <Fragment>
            <div className="wrapper">
                <Header />
                <Leftsidebar />
                <div className="content-wrapper" id="content-window">
                    <section className="content">
                        {(() => {
                            if (props.unAssigned == true) {
                                return (
                                    props.userApiStatus ? (
                                        <div
                                            className={`alert ${props.userApiStatus === 1 ? "alert-success" : "alert-danger"
                                                }`}
                                            role="alert"
                                        >
                                            {props.userApiStatus === 1 ? props.message : props.message}
                                        </div>
                                    ) : (
                                        ""
                                    ))
                            } else {
                                return (
                                    props.userApiStatus ? (
                                        <div
                                            className={`alert ${props.userApiStatus === 1 ? "alert-success" : ""
                                                }`}
                                            role="alert"
                                        >
                                            {props.userApiStatus === 1 ? props.message : ''}
                                        </div>
                                    ) : (
                                        ""
                                    )

                                )
                            }
                        })()}
                        <div >
                            <div className="tab-content col-lg-12 col-lg">
                                <ul className="nav nav-tabs" id="myTab">
                                    {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(65) ? (
                                        <li className={props.pageSelected == "User" ? "active" : ""}><a onClick={() => { props.setValues("pageSelected", "User") }}><i className />Users </a>
                                        </li>
                                    ) : null}

                                    {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(69) ? (
                                        <li className={props.pageSelected == "Permission" ? "active" : ""}><a onClick={() => { props.setValues("pageSelected", "Permission") }} ><i disabled className />Permissions </a></li>
                                    ):null}

                                </ul>
                                {props.pageSelected === "User" ? (
                                    <User />
                                ) : (
                                    <Permission />
                                )}
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>{/* ./wrapper */}
            <div id="myModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            <h4 className="modal-title">Title</h4>
                        </div>
                        <div className="modal-body">
                            Loading ...
              </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>
            <Individual />


        </Fragment>
    )
};



const mapStateToProps = (state, ownProps) => {
    const {
        pageSelected,
        userApiStatus,
        message,
        unAssigned
    } = state.UserDatas;
    return {
        pageSelected,
        userApiStatus,
        message,
        unAssigned
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setValues: (f, v) => dispatch(actions.changeUserInfo(f, v)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);