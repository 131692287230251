import React, { Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import "../CompanySetups.css";
import DataTable from "react-data-table-component";
import * as actions from "../../../actions/CompanySetups";

function Addnewcompany(props) {
  const addNewCompany = (event) => {
    event.preventDefault();
    if (validCompanyInfo()) {
      props.addCompany({
        company_name: props.companyName,
        owner_name: props.ownerName,
        address: props.address,
        phone_number: props.phoneNumber,
        bank: props.bank,
        account_no: props.bankAccount,
        email: props.email,
        smtp_server_name: props.smtpServerName,
        smtp_username: props.smtpUserName,
        smtp_password: props.smtpPassword,
      });
    }
  };

  const validCompanyInfo = () => {
    let valid = true;
    let errors = props.addCompanyErrors;
    if (!props.companyName) {
      valid = false;
      errors.companyName = "Cannot be Empty";
    } else {
      errors.companyName = "";
    }
    if (!props.ownerName) {
      valid = false;
      errors.ownerName = "Cannot be Empty";
    } else {
      errors.ownerName = "";
    }
    if (!props.address) {
      valid = false;
      errors.address = "Cannot be Empty";
    } else {
      errors.address = "";
    }
    if (!props.phoneNumber) {
      valid = false;
      errors.phoneNumber = "Cannot be Empty";
    } else {
      errors.phoneNumber = "";
    }
    if (!props.bank) {
      valid = false;
      errors.bank = "Cannot be Empty";
    } else {
      errors.bank = "";
    }
    if (!props.bankAccount) {
      valid = false;
      errors.bankAccount = "Cannot be Empty";
    } else {
      errors.bankAccount = "";
    }
    if (!props.email) {
      valid = false;
      errors.email = "Cannot be Empty";
    } else {
      errors.email = "";
    }
    if (!props.smtpServerName) {
      valid = false;
      errors.smtpServerName = "Cannot be Empty";
    } else {
      errors.smtpServerName = "";
    }
    if (!props.smtpUserName) {
      valid = false;
      errors.smtpUserName = "Cannot be Empty";
    } else {
      errors.smtpUserName = "";
    }
    if (!props.smtpPassword) {
      valid = false;
      errors.smtpPassword = "Cannot be Empty";
    } else {
      errors.smtpPassword = "";
    }
    props.updateCompanyErrors(errors);
    return valid;
  };
  return (
    <Modal
      show={props.show}
      className="modal bootstrap-dialog type-primary size-normal "
      role="dialog"
      aria-hidden="true"
      id="newcompany"
      aria-labelledby="newcompany"
      tabIndex={-1}
      onHide={() => props.changeCompanyInfo("company_modal_show", false)}
    >
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div className="bootstrap-dialog-title">New Company</div>
          </div>
        </div>
        <div className="modal-body">
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div>
                <form className="form-horizontal">
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                  />
            
                  <div className="form-group">
                    <label
                      htmlFor="companyname"
                      className="control-label col-sm-4 required"
                    >
                      Company Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="companyname"
                        type="text"
                        id="companyname"
                        value={props.companyName}
                        onChange={(e) =>
                          props.changeCompanyInfo("companyName", e.target.value)
                        }
                      />
                      {props.addCompanyErrors.companyName &&
                      props.addCompanyErrors.companyName.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.companyName}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="owner"
                      className="control-label col-sm-4 required"
                    >
                      Owner Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="owner"
                        type="text"
                        id="owner"
                        value={props.ownerName}
                        onChange={(e) =>
                          props.changeCompanyInfo("ownerName", e.target.value)
                        }
                      />
                      {props.addCompanyErrors.ownerName &&
                      props.addCompanyErrors.ownerName.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.ownerName}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="address"
                      className="control-label col-sm-4 required"
                    >
                      Address
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        rows={3}
                        required="required"
                        name="address"
                        cols={50}
                        id="address"
                        value={props.address}
                        onChange={(e) =>
                          props.changeCompanyInfo("address", e.target.value)
                        }
                      />
                      {props.addCompanyErrors.address &&
                      props.addCompanyErrors.address.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.address}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone" className="control-label col-sm-4">
                      Phone Number
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="phone"
                        type="text"
                        id="phone"
                        value={props.phoneNumber}
                        onChange={(e) =>
                          props.changeCompanyInfo("phoneNumber", e.target.value)
                        }
                      />
                      {props.addCompanyErrors.phoneNumber &&
                      props.addCompanyErrors.phoneNumber.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.phoneNumber}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="bank" className="control-label col-sm-4">
                      Bank
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="bank"
                        type="text"
                        id="bank"
                        value={props.bank}
                        onChange={(e) =>
                          props.changeCompanyInfo("bank", e.target.value)
                        }
                      />
                      {props.addCompanyErrors.bank &&
                      props.addCompanyErrors.bank.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.bank}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="bankingaccount"
                      className="control-label col-sm-4"
                    >
                      Bank Account
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="bankingaccount"
                        type="text"
                        id="bankingaccount"
                        value={props.bankAccount}
                        onChange={(e) =>
                          props.changeCompanyInfo("bankAccount", e.target.value)
                        }
                      />
                      {props.addCompanyErrors.bankAccount &&
                      props.addCompanyErrors.bankAccount.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.bankAccount}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="smtpfromemail"
                      className="control-label col-sm-4 required"
                    >
                      Email
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="smtpfromemail"
                        type="email"
                        id="smtpfromemail"
                        value={props.email}
                        onChange={(e) =>
                          props.changeCompanyInfo("email", e.target.value)
                        }
                      />
                      {props.addCompanyErrors.email &&
                      props.addCompanyErrors.email.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.email}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="smtpservername"
                      className="control-label col-sm-4"
                    >
                      SMTP Server Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="smtpservername"
                        type="text"
                        id="smtpservername"
                        value={props.smtpServerName}
                        onChange={(e) =>
                          props.changeCompanyInfo(
                            "smtpServerName",
                            e.target.value
                          )
                        }
                      />
                      {props.addCompanyErrors.smtpServerName &&
                      props.addCompanyErrors.smtpServerName.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.smtpServerName}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="smtpusername"
                      className="control-label col-sm-4"
                    >
                      SMTP username
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="smtpusername"
                        type="text"
                        id="smtpusername"
                        value={props.smtpUserName}
                        onChange={(e) =>
                          props.changeCompanyInfo(
                            "smtpUserName",
                            e.target.value
                          )
                        }
                      />
                      {props.addCompanyErrors.smtpUserName &&
                      props.addCompanyErrors.smtpUserName.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.smtpUserName}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="password"
                      className="control-label col-sm-4"
                    >
                      SMTP password
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="password"
                        type="password"
                        id="password"
                        value={props.smtpPassword}
                        onChange={(e) =>
                          props.changeCompanyInfo(
                            "smtpPassword",
                            e.target.value
                          )
                        }
                      />
                      {props.addCompanyErrors.smtpPassword &&
                      props.addCompanyErrors.smtpPassword.length > 0 ? (
                        <span className="text-danger">
                          {props.addCompanyErrors.smtpPassword}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                  {props.addCompanyStatus ? (
                    <div
                      className={`alert ${
                        props.addCompanyStatus === 1
                          ? ""
                          : "alert-danger"
                      }`}
                      role="alert"
                    >
                      {props.addCompanyStatus === 1 ? '': props.message }
                    </div>
                  ) : (
                    ""
                  )}
                  </div>
                  <button
                    className="form-control"
                    value="new"
                    name="action"
                    type="button"
                    onClick={(e) => {
                      addNewCompany(e);
                    }}
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              <button
                className="btn form-control"
                id="company-dismiss"
                data-dismiss="modal"
                onClick={() => {
                  props.changeCompanyInfo("company_modal_show", false);
                  props.changeCompanyInfo("addCompanyErrors", {});
                }}
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.CompanySetups.company_modal_show,
    companyList: state.CompanySetups.companyList,
    companyName: state.CompanySetups.companyName,
    ownerName: state.CompanySetups.ownerName,
    address: state.CompanySetups.address,
    phoneNumber: state.CompanySetups.phoneNumber,
    bank: state.CompanySetups.bank,
    bankAccount: state.CompanySetups.bankAccount,
    email: state.CompanySetups.email,
    smtpServerName: state.CompanySetups.smtpServerName,
    smtpUserName: state.CompanySetups.smtpUserName,
    smtpPassword: state.CompanySetups.smtpPassword,
    addCompanyErrors: state.CompanySetups.addCompanyErrors,
    addCompanyStatus: state.CompanySetups.addCompanyStatus,
    message: state.CompanySetups.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyList: () => dispatch(actions.getCompanyList()),
    changeCompanyInfo: (f, e) => dispatch(actions.changeCompanyInfo(f, e)),
    addCompany: (data) => dispatch(actions.addCompany(data)),
    updateCompanyErrors: (data) => dispatch(actions.updateCompanyErrors(data)),
    reset_company_info: () => dispatch(actions.reset_company_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Addnewcompany);
