import React, { useEffect } from "react";
import Routes from "./routes";
import "./App.css";
// Import root component
import { Provider } from "react-redux";
import { store } from "./stores/configureStore";

const BaseApp = (props) => {
  // useEffect(() => {
  //   console.log("here", props);
  //   // loadjs("/assets/js/app.min.js");
  // }, []);
  return (
    <Provider store={store}>
      <div className="App">
        <Routes />
      </div>
    </Provider>
  );
};

BaseApp.displayName = "BaseApp";
export default BaseApp;
