let init = {
  selectDocumentModal: false,
  transferOfShareModal: false,
  documentList: [],
  documentList2: [],
  documentTypeList: [],
  documentType: "",
  companyName: "",
  regNo: "",
  ordinaryShares: "",
  transferNominal: "",
  transferorNameType: [],
  transferorName: "",
  noOfShareHolding: "",
  formName: "",
  to: [],
  updatedTo: [],
  formNameList: "",
  tabSelected: "Pending",
  selectDocumentType: "",
  arrayselectDocumentType: [],
  lengtharray: 0,
  nextValue: 0,
  docPreview: "",
  defaultFields: [],
  sendRequestModal: false,
  reSendMailDetails: [],
  reSendRequestModal: false,
  shareHolder: [],
  rejectDocumentModal: false,
  changestatusDocumentModal: false,
  document_id: "",
  multiple_document_id: [],
  reject_reason: "",
  acceptDocumentModal: false,
  filterValue: [],
  attachment: [],
  deleteDocumentModal: false,
  editDocumentModalDate: false,
  attachment_key: "",
  document_type: "",
  docDate: "",
  notes: "",
  addNewDocumentModal: false,
  editDoc: {},
  htmldata: "",
  fileJson: {},
  docPath: [],
  created_on: ""
};

export const Document = (state = init, action) => {
  switch (action.type) {
    case "DOCUMENT_DATA":
      return { ...state, [action.field]: action.value };
    case "ATTACHMENT":
      return { ...state, attachment: [...state.attachment, action.data] };
    case "ARRAY":
      return {
        ...state,
        multiple_document_id: [...state.multiple_document_id, action.data],
      };
    case "CLEARARRAY":
      return { ...state, multiple_document_id: [] };
    case "FILE_JSON":
      return { ...state, fileJson: action.payload };
    case "DOC_PATH":
      return { ...state, docPath: action.payload };
    default:
      return state;
  }
};
