import React, { Fragment, useEffect } from "react";
import Header from "./Header";
import Leftsidebar from "./LeftSideBar";
import Footer from "./Footer";
import { connect } from "react-redux";
import "../styles/CommenStyle.css";
import * as actions from "../actions/Settings";
import AddNewSettingItem from "./settingsmodals/AddNewSettingItem";
import EditSettingItem from "./settingsmodals/EditSettingItem";
import AddNewServiceItem from "./settingsmodals/AddNewServiceItem";
import EditServiceItem from "./settingsmodals/EditServiceItem";
import AddNewEmailItem from "./settingsmodals/AddNewEmailItem";
import EditEmailItem from "./settingsmodals/EditEmailItem";
import $ from "jquery";

function Settings(props) {
  let uniquePageSelection = ["Service", "Email Template", "Document"];
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    getFunc("Currency");
  }, []);

  const getFunc = (input) => {
    console.log("get", props.pageSelect);
    if (input == "Currency") {
      props.setValues("pageSelect", "Currency");
      props.setValues("List", []);
    } else if (input == "Nationality") {
      props.setValues("pageSelect", "Nationality");
      props.setValues("List", []);
    } else if (input == "Employee Type") {
      props.setValues("pageSelect", "Employee Type");
      props.setValues("List", []);
    } else if (input == "Share Type") {
      props.setValues("pageSelect", "Share Type");
      props.setValues("List", []);
    } else if (input == "Task Setting") {
      props.setValues("pageSelect", "Task Setting");
      props.setValues("List", []);
    } else if (input == "Invoice Category") {
      props.setValues("pageSelect", "Invoice Category");
      props.setValues("List", []);
    } else if (input == "Service") {
      props.setValues("pageSelect", "Service");
      props.setValues("List", []);
    } else if (input == "Email Template") {
      props.setValues("pageSelect", "Email Template");
      props.setValues("List", []);
    } else if (input == "ID Type") {
      props.setValues("pageSelect", "ID Type");
      props.setValues("List", []);
    } else if (input == "Salutation") {
      props.setValues("pageSelect", "Salutation");
      props.setValues("List", []);
    } else if (input == "Document Classification") {
      props.setValues("pageSelect", "Document Classification");
      props.setValues("List", []);
    }else if (input == "Client Status") {
      props.setValues("pageSelect", "Client Status");
      props.setValues("List", []);
    }else if (input == "Document Status") {
      props.setValues("pageSelect", "Document Status");
      props.setValues("List", []);
    }else if (input == "Idendification Types") {
      props.setValues("pageSelect", "Idendification Types");
      props.setValues("List", []);
    }else if (input == "Flag") {
      props.setValues("pageSelect", "Flag");
      props.setValues("List", []);
    }
    console.log("input", input);
    props.getCommonSettingsList({
      page_selected: input.split(" ").join("_").toLowerCase(),
    });
  };

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div className="content-wrapper" id="content-window">
          <section className="content">
            <div>
              <div className="tab-content col-lg-12 col-lg">
                {/* <div
                  className="btn-group"
                  style={{ position: "absolute", right: 0, top: "9px" }}
                >
                  <button
                    type="button"
                    className="btn btn-success btn-sm dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-th-list" /> Menu{" "}
                    <span className="caret" />
                  </button>
                  <ul className="dropdown-menu right-menu">
                    <li role="presentation">
                      <a href="javascript:void(0)">Add </a>
                    </li>
                    <li role="presentation">
                      <a href="javascript:void(0)">Delete </a>
                    </li>
                  </ul>
                </div> */}
                <ul className="nav nav-tabs" id="myTab">
                  <li
                    className={props.pageSelect == "Currency" ? "active" : ""}
                  >
                    <a
                      onClick={() => {
                        getFunc("Currency");
                      }}
                    >
                      <i />
                      Currency{" "}
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Nationality" ? "active" : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Nationality");
                      }}
                    >
                      <i />
                      Nationality{" "}
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Employee Type" ? "active" : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Employee Type");
                      }}
                    >
                      <i />
                      Employee Types
                    </a>
                  </li>
                  <li
                    className={props.pageSelect == "Share Type" ? "active" : ""}
                  >
                    <a
                      onClick={() => {
                        getFunc("Share Type");
                      }}
                    >
                      <i />
                      Share Types{" "}
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Task Setting" ? "active" : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Task Setting");
                      }}
                    >
                      <i />
                      Task Settings
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Invoice Category" ? "active" : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Invoice Category");
                      }}
                    >
                      <i />
                      Invoice Types
                    </a>
                  </li>
                  <li className={props.pageSelect == "Service" ? "active" : ""}>
                    <a
                      onClick={() => {
                        getFunc("Service");
                      }}
                    >
                      <i />
                      Services{" "}
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Email Template" ? "active" : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Email Template");
                      }}
                    >
                      <i />
                      Email{" "}
                    </a>
                  </li>
                  <li className={props.pageSelect == "ID Type" ? "active" : ""}>
                    <a
                      onClick={() => {
                        getFunc("ID Type");
                      }}
                    >
                      <i />
                      ID Types{" "}
                    </a>
                  </li>
                  <li
                    className={props.pageSelect == "Salutation" ? "active" : ""}
                  >
                    <a
                      onClick={() => {
                        getFunc("Salutation");
                      }}
                    >
                      <i />
                      Salutation{" "}
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Document Classification"
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Document Classification");
                      }}
                    >
                      <i />
                      Document Type{" "}
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Client Status"
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Client Status");
                      }}
                    >
                      <i />
                      Client Status{" "}
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Document Status"
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Document Status");
                      }}
                    >
                      <i />
                      Document Status{" "}
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Idendification Types"
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Idendification Types");
                      }}
                    >
                      <i />
                      Idendification Types{" "}
                    </a>
                  </li>
                  <li
                    className={
                      props.pageSelect == "Flag"
                        ? "active"
                        : ""
                    }
                  >
                    <a
                      onClick={() => {
                        getFunc("Flag");
                      }}
                    >
                      <i />
                      Flag{" "}
                    </a>
                  </li>
                </ul>
                {props.pageSelect == "Currency" ? (
                  <div
                    id="settings-currency-tab"
                    className="tab-pane fade in active"
                  >
                    <h3 />
                    <p></p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">Currency</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-success btn-sm dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fa fa-th-list" /> Menu{" "}
                                <span className="caret" />
                              </button>
                              <ul className="dropdown-menu right-menu">
                                <li role="presentation">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                      props.reset_common_info();
                                      props.setValues(
                                        "common_modal_show",
                                        true
                                      );
                                      props.setValues(
                                        "setting_type",
                                        "currency"
                                      );
                                    }}
                                  >
                                    New Currency
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div classpName="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table
                            id="currency"
                            className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          >
                            <thead>
                              <tr>
                                <th className="pl-60">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.List.map((lis, idx) => {
                                return (
                                  <tr id={1}>
                                    <td data="description" className="pl-35">
                                      <a
                                        href="javascript:void(0)"
                                        className="align-center"
                                        onClick={() => {
                                          props.reset_common_info();
                                          props.setValues("id", lis.id);
                                          props.setValues(
                                            "description",
                                            lis.Name
                                          );
                                          props.setValues(
                                            "common_edit_modal_show",
                                            true
                                          );
                                          props.setValues(
                                            "setting_type",
                                            "currency"
                                          );
                                        }}
                                      >
                                        <div className="pr-10">
                                          {/* <input
                                            type="checkbox"
                                            id="SGD"
                                            name="SGD"
                                            defaultValue="SGD"
                                            className="check-box-active"
                                          /> */}
                                          <span class="glyphicon glyphicon-pencil check-box-active" />
                                        </div>
                                        {lis.Name}
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border"></div>
                    </div>
                    <p />
                  </div>
                ) : (
                  <div
                    id="settings-nationality-tab"
                    className="tab-pane fade in active  "
                  >
                    <h3 />
                    <p></p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">{props.pageSelect == "Document Classification" ? "Document Type" : props.pageSelect }</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-success btn-sm dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fa fa-th-list" /> Menu{" "}
                                <span className="caret" />
                              </button>
                              <ul className="dropdown-menu right-menu">
                                <li role="presentation">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                      if (
                                        uniquePageSelection.includes(
                                          props.pageSelect
                                        )
                                      ) {
                                        props.reset_common_info();
                                        console.log("page", props.pageSelect);
                                        if (props.pageSelect == "Service") {
                                          console.log("true");
                                          props.setValues(
                                            "service_modal_show",
                                            true
                                          );
                                        } else if (
                                          props.pageSelect == "Email Template"
                                        ) {
                                          props.setValues(
                                            "email_modal_show",
                                            true
                                          );
                                        }
                                      } else {
                                        props.reset_common_info();
                                        props.setValues(
                                          "common_modal_show",
                                          true
                                        );
                                        props.setValues(
                                          "setting_type",
                                          props.pageSelect
                                            .split(" ")
                                            .join("_")
                                            .toLowerCase()
                                        );
                                      }
                                    }}
                                  >
                                    New {props.pageSelect}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table
                            id="nationalities"
                            className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          >
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.List.map((lis, idx) => {
                                return (
                                  <tr id={2}>
                                    <td data="description">
                                      <a
                                        className="align-center"
                                        href="javascript:void(0)"
                                        onClick={() => {
                                          if (
                                            uniquePageSelection.includes(
                                              props.pageSelect
                                            )
                                          ) {
                                            props.reset_common_info();
                                            if (props.pageSelect == "Service") {
                                              props.setValues("service", {
                                                id: lis.id,
                                                description: lis.Name,
                                                estimated_rate:
                                                  lis.estimated_rate,
                                                fixed_rate: lis.fixed_rate,
                                                hourly_rate: lis.hourly_rate,
                                              });
                                              props.setValues(
                                                "edit_service_modal_show",
                                                true
                                              );
                                            } else if (
                                              props.pageSelect ==
                                              "Email Template"
                                            ) {
                                              props.setValues("email", {
                                                id: lis.id,
                                                email_tag: lis.email_tag,
                                                email_content:
                                                  lis.email_content,
                                              });
                                              props.setValues(
                                                "edit_email_modal_show",
                                                true
                                              );
                                            }
                                          } else {
                                            props.reset_common_info();
                                            props.setValues("id", lis.id);
                                            props.setValues(
                                              "description",
                                              lis.Name
                                            );
                                            props.setValues(
                                              "common_edit_modal_show",
                                              true
                                            );
                                            props.setValues(
                                              "setting_type",
                                              props.pageSelect
                                                .split(" ")
                                                .join("_")
                                                .toLowerCase()
                                            );
                                          }
                                        }}
                                      >
                                        <div className="pr-10">
                                          {/* <input
                                            type="checkbox"
                                            id="SGD"
                                            name="SGD"
                                            defaultValue="SGD"
                                            className="check-box-active"
                                          /> */}
                                        <span class="glyphicon glyphicon-pencil check-box-active" />
                                        </div>
                                        {props.pageSelect == "Email Template"
                                          ? lis.email_tag
                                          : lis.Name}
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border"></div>
                    </div>
                    <p />
                  </div>
                )}
                {/* <div id="settings-employeetype-tab" className="tab-pane fade ">
                    <h3 />
                    <p>
                    </p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">Employee Type</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-list" /> Menu <span className="caret" />
                              </button>
                              <ul className="dropdown-menu">
                                <li role="presentation"><a href="javascript:void(0)" >New Employee Type</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table id="employeetype" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id={1}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(1,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">SHAREHOLDER</a>
                                </td>
                              </tr>
                              <tr id={2}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(2,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">DIRECTOR</a>
                                </td>
                              </tr>
                              <tr id={3}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(3,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">DIRECTOR
                                    / SHAREHOLDER</a></td>
                              </tr>
                              <tr id={4}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(4,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">SECRETARY</a>
                                </td>
                              </tr>
                              <tr id={5}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(5,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">PARTNERS
                                    / MANAGERS</a></td>
                              </tr>
                              <tr id={6}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(6,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">PARTNER</a>
                                </td>
                              </tr>
                              <tr id={7}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(7,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">MANAGER</a>
                                </td>
                              </tr>
                              <tr id={8}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(8,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">SECRETARY
                                    / DIRECTOR</a></td>
                              </tr>
                              <tr id={9}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(9,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">ACCOUNTANT
                                    1</a></td>
                              </tr>
                              <tr id={10}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(10,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">ACCOUNTANT
                                    2</a></td>
                              </tr>
                              <tr id={11}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(11,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">AUDITOR</a>
                                </td>
                              </tr>
                              <tr id={12}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(12,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">DIRECTOR/MEMBER</a>
                                </td>
                              </tr>
                              <tr id={13}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(13,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">ADMINISTRATOR</a>
                                </td>
                              </tr>
                              <tr id={14}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(14,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">DIRECTOR
                                    / MANAGINGD DIRECTOR / SHAREHOLDER</a></td>
                              </tr>
                              <tr id={15}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(15,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">MANAGING
                                    DIRECTOR</a></td>
                              </tr>
                              <tr id={16}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(16,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">ACTUAL
                                    OWNERS</a></td>
                              </tr>
                              <tr id={17}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(17,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">PREFERENCE
                                    SHAREHOLDER</a></td>
                              </tr>
                              <tr id={18}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(18,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">LAWYERS</a>
                                </td>
                              </tr>
                              <tr id={19}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(19,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">FUTURE
                                    SHAREHOLDERS</a></td>
                              </tr>
                              <tr id={20}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(20,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">OUTSOURCED
                                    CFO</a></td>
                              </tr>
                              <tr id={21}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(21,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">PERSONAL
                                    SECRETARY</a></td>
                              </tr>
                              <tr id={22}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(22,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">NOMINEE
                                    DIRECTOR</a></td>
                              </tr>
                              <tr id={23}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(23,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">REGISTRABLE
                                    CONTROLLER</a></td>
                              </tr>
                              <tr id={24}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(24,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">ULTIMATE
                                    BENEFICIAL OWNER</a></td>
                              </tr>
                              <tr id={25}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(25,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">MEMBER</a>
                                </td>
                              </tr>
                              <tr id={26}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(26,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">NOMINATOR</a>
                                </td>
                              </tr>
                              <tr id={27}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(27,'/dashboard/setting/1/employeetype',null,null,null,null,null,'')">CORPORATE
                                    REPRESENTATIVE</a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border">
                      </div>
                    </div>
                    <p />
                  </div> */}
                {/* <div id="settings-sharetypes-tab" className="tab-pane fade ">
                    <h3 />
                    <p>
                    </p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">Share Type</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-list" /> Menu <span className="caret" />
                              </button>
                              <ul className="dropdown-menu">
                                <li role="presentation"><a href="javascript:void(0)" >New Share Type</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table id="sharetypes" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id={1}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(1,'/dashboard/setting/1/sharetype',null,null,null,null,null,'')">ORDINARY
                                    SHARES</a></td>
                              </tr>
                              <tr id={2}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(2,'/dashboard/setting/1/sharetype',null,null,null,null,null,'')">Preference
                                    Shares</a></td>
                              </tr>
                              <tr id={3}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(3,'/dashboard/setting/1/sharetype',null,null,null,null,null,'')">LIMITED
                                    BY GAURANTEE</a></td>
                              </tr>
                              <tr id={4}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(4,'/dashboard/setting/1/sharetype',null,null,null,null,null,'')">CLASS
                                    A</a></td>
                              </tr>
                              <tr id={5}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(5,'/dashboard/setting/1/sharetype',null,null,null,null,null,'')">PREFERENCE
                                    SHARE SERIES B</a></td>
                              </tr>
                              <tr id={6}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(6,'/dashboard/setting/1/sharetype',null,null,null,null,null,'')">ORDINARY
                                    SHARES- CLASS B</a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border">
                      </div>
                    </div>
                    <p />
                  </div> */}
                {/* <div id="settings-tasksetting-tab" className="tab-pane fade ">
                    <h3 />
                    <p>
                    </p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">Task Setting</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-list" /> Menu <span className="caret" />
                              </button>
                              <ul className="dropdown-menu">
                                <li role="presentation"><a href="javascript:void(0)" >New Task setting</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table id="tasksetting" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id={1}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(1,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">ACRA
                                    Filing</a></td>
                              </tr>
                              <tr id={2}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(2,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">TAX
                                    Filing</a></td>
                              </tr>
                              <tr id={3}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(3,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">GST
                                    Filing</a></td>
                              </tr>
                              <tr id={5}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(5,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">LAW
                                    DEADLINE</a></td>
                              </tr>
                              <tr id={6}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(6,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">ECI
                                    Filing</a></td>
                              </tr>
                              <tr id={7}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(7,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">CRM
                                    DEADLINE</a></td>
                              </tr>
                              <tr id={8}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(8,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">ROSE</a>
                                </td>
                              </tr>
                              <tr id={9}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(9,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">PERSONAL
                                    TAX</a></td>
                              </tr>
                              <tr id={10}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(10,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">CERTIFICATE
                                    OF RESIDENCE </a></td>
                              </tr>
                              <tr id={11}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(11,'/dashboard/setting/1/tasksetting',null,null,null,null,null,'')">CONSULTANCY</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border">
                      </div>
                    </div>
                    <p />
                  </div> */}
                {/* <div id="settings-invoicecategory-tab" className="tab-pane fade ">
                    <h3 />
                    <p>
                    </p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">Invoice Category</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-list" /> Menu <span className="caret" />
                              </button>
                              <ul className="dropdown-menu">
                                <li role="presentation"><a href="javascript:void(0)" >New Invoice Category</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table id="invoicecategory" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id={1}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(1,'/dashboard/setting/1/invoicecategory',null,null,null,null,null,'')">Accounting</a>
                                </td>
                              </tr>
                              <tr id={2}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(2,'/dashboard/setting/1/invoicecategory',null,null,null,null,null,'')">Financial
                                    Statements</a></td>
                              </tr>
                              <tr id={3}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(3,'/dashboard/setting/1/invoicecategory',null,null,null,null,null,'')">Secretarial</a>
                                </td>
                              </tr>
                              <tr id={4}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(4,'/dashboard/setting/1/invoicecategory',null,null,null,null,null,'')">Tax</a>
                                </td>
                              </tr>
                              <tr id={5}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(5,'/dashboard/setting/1/invoicecategory',null,null,null,null,null,'')">Discount</a>
                                </td>
                              </tr>
                              <tr id={6}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(6,'/dashboard/setting/1/invoicecategory',null,null,null,null,null,'')">Reimbursement</a>
                                </td>
                              </tr>
                              <tr id={7}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(7,'/dashboard/setting/1/invoicecategory',null,null,null,null,null,'')">Disbursement</a>
                                </td>
                              </tr>
                              <tr id={8}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(8,'/dashboard/setting/1/invoicecategory',null,null,null,null,null,'')">Others</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border">
                      </div>
                    </div>
                    <p />
                  </div> */}
                {/* <div id="settings-service-tab" className="tab-pane fade ">
                    <h3 />
                    <p>
                    </p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">Service</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-list" /> Menu <span className="caret" />
                              </button>
                              <ul className="dropdown-menu">
                                <li role="presentation"><a href="javascript:void(0)">New Service Type</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table id="service" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id={1}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(1,'/dashboard/setting/1/service',null,null,null,null,null,'')">Accounting</a>
                                </td>
                              </tr>
                              <tr id={2}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(2,'/dashboard/setting/1/service',null,null,null,null,null,'')">Financial
                                    Statements</a></td>
                              </tr>
                              <tr id={3}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(3,'/dashboard/setting/1/service',null,null,null,null,null,'')">Financial
                                    Services</a></td>
                              </tr>
                              <tr id={4}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(4,'/dashboard/setting/1/service',null,null,null,null,null,'')">Tax
                                    computation</a></td>
                              </tr>
                              <tr id={5}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(5,'/dashboard/setting/1/service',null,null,null,null,null,'')">Tax
                                    Services</a></td>
                              </tr>
                              <tr id={6}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(6,'/dashboard/setting/1/service',null,null,null,null,null,'')">SECRETARIAL</a>
                                </td>
                              </tr>
                              <tr id={7}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(7,'/dashboard/setting/1/service',null,null,null,null,null,'')">Registered
                                    Office</a></td>
                              </tr>
                              <tr id={8}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(8,'/dashboard/setting/1/service',null,null,null,null,null,'')">Reviewing</a>
                                </td>
                              </tr>
                              <tr id={9}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(9,'/dashboard/setting/1/service',null,null,null,null,null,'')">CRM</a>
                                </td>
                              </tr>
                              <tr id={10}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(10,'/dashboard/setting/1/service',null,null,null,null,null,'')">Others</a>
                                </td>
                              </tr>
                              <tr id={11}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(11,'/dashboard/setting/1/service',null,null,null,null,null,'')">Assisting
                                    in Auditing</a></td>
                              </tr>
                              <tr id={12}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(12,'/dashboard/setting/1/service',null,null,null,null,null,'')">Administration</a>
                                </td>
                              </tr>
                              <tr id={13}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(13,'/dashboard/setting/1/service',null,null,null,null,null,'')">CERTIFICATE
                                    OF RESIDENCE </a></td>
                              </tr>
                              <tr id={14}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(14,'/dashboard/setting/1/service',null,null,null,null,null,'')">CONSULTANCY</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border">
                      </div>
                    </div>
                    <p />
                  </div> */}
                {/* <div id="settings-template-tab" className="tab-pane fade ">
                    <h3 />
                    <p>
                    </p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">Email Template</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-list" /> Menu <span className="caret" />
                              </button>
                              <ul className="dropdown-menu">
                                <li role="presentation"><a href="javascript:void(0)" >New Template</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table id="template" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id={1}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(1,'/dashboard/setting/1/emailtemplate',null,null,null,null,null,'size-wide')">ACRA
                                    &amp; ECI Filing-1st reminder</a></td>
                              </tr>
                              <tr id={2}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(2,'/dashboard/setting/1/emailtemplate',null,null,null,null,null,'size-wide')">IRAS
                                    Filing - </a></td>
                              </tr>
                              <tr id={3}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(3,'/dashboard/setting/1/emailtemplate',null,null,null,null,null,'size-wide')">TEST
                                    TEMPLATE</a></td>
                              </tr>
                              <tr id={4}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(4,'/dashboard/setting/1/emailtemplate',null,null,null,null,null,'size-wide')">ACRA
                                    &amp; ECI Filing-5th reminder</a></td>
                              </tr>
                              <tr id={5}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(5,'/dashboard/setting/1/emailtemplate',null,null,null,null,null,'size-wide')">ACRA
                                    &amp; ECI Filing-2nd reminder</a></td>
                              </tr>
                              <tr id={6}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(6,'/dashboard/setting/1/emailtemplate',null,null,null,null,null,'size-wide')">ACRA
                                    &amp; ECI Filing-3rd reminder</a></td>
                              </tr>
                              <tr id={7}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(7,'/dashboard/setting/1/emailtemplate',null,null,null,null,null,'size-wide')">ACRA
                                    &amp; ECI Filing-4th reminder</a></td>
                              </tr>
                              <tr id={8}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(8,'/dashboard/setting/1/emailtemplate',null,null,null,null,null,'size-wide')">GST
                                    FILING</a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border">
                      </div>
                    </div>
                    <p />
                  </div> */}
                {/* <div id="settings-idTypes-tab" className="tab-pane fade ">
                    <h3 />
                    <p>
                    </p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">ID Type</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-list" /> Menu <span className="caret" />
                              </button>
                              <ul className="dropdown-menu">
                                <li role="presentation"><a href="javascript:void(0)" >New ID Type</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table id="idTypes" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id={2}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(2,'/dashboard/setting/1/idtype',null,null,null,null,null,'')">NRIC
                                    (SINGAPOREAN)</a></td>
                              </tr>
                              <tr id={3}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(3,'/dashboard/setting/1/idtype',null,null,null,null,null,'')">Passport</a>
                                </td>
                              </tr>
                              <tr id={4}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(4,'/dashboard/setting/1/idtype',null,null,null,null,null,'')">EP</a>
                                </td>
                              </tr>
                              <tr id={5}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(5,'/dashboard/setting/1/idtype',null,null,null,null,null,'')">WP</a>
                                </td>
                              </tr>
                              <tr id={6}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(6,'/dashboard/setting/1/idtype',null,null,null,null,null,'')">NRIC
                                    (SINGAPORE PR)</a></td>
                              </tr>
                              <tr id={7}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(7,'/dashboard/setting/1/idtype',null,null,null,null,null,'')">COMPANY
                                    REG #</a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border">
                      </div>
                    </div>
                    <p />
                  </div> */}
                {/* <div id="settings-salutations-tab" className="tab-pane fade ">
                    <h3 />
                    <p>
                    </p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">Salutation</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-list" /> Menu <span className="caret" />
                              </button>
                              <ul className="dropdown-menu">
                                <li role="presentation"><a href="javascript:void(0)" >New Salutation</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table id="salutations" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id={2}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(2,'/dashboard/setting/1/salutation',null,null,null,null,null,'')">Mr</a>
                                </td>
                              </tr>
                              <tr id={3}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(3,'/dashboard/setting/1/salutation',null,null,null,null,null,'')">Mrs</a>
                                </td>
                              </tr>
                              <tr id={4}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(4,'/dashboard/setting/1/salutation',null,null,null,null,null,'')">Ms</a>
                                </td>
                              </tr>
                              <tr id={5}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(5,'/dashboard/setting/1/salutation',null,null,null,null,null,'')">Miss</a>
                                </td>
                              </tr>
                              <tr id={6}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(6,'/dashboard/setting/1/salutation',null,null,null,null,null,'')">Dr</a>
                                </td>
                              </tr>
                              <tr id={7}>
                                <td data="description"><a href="javascript:void(0)" onclick="editRow(7,'/dashboard/setting/1/salutation',null,null,null,null,null,'')">N.A</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border">
                      </div>
                    </div>
                    <p />
                  </div> */}
                {/* <div id="settings-classifications-tab" className="tab-pane fade ">
                    <h3 />
                    <p>
                    </p>
                    <div className="box box-primary box_align_left" boxHeight>
                      <div className="box-header">
                        <h3 className="box-title">Document Classification</h3>
                        <div>
                          <div className="box-tools pull-right">
                            <div className="btn-group">
                              <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-th-list" /> Menu <span className="caret" />
                              </button>
                              <ul className="dropdown-menu">
                                <li role="presentation"><a href="javascript:void(0)" >New Document Classification</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                      <div className="box-body" boxHeight>
                        <div className="table-responsive scrollable">
                          <table id="classifications" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id={1}>
                                <td data="description"><a href="javascript:void(0)" >Secreterial</a>
                                </td>
                              </tr>
                              <tr id={2}>
                                <td data="description"><a href="javascript:void(0)" >Others</a>
                                </td>
                              </tr>
                              <tr id={3}>
                                <td data="description"><a href="javascript:void(0)" >Financial
                                    statement</a></td>
                              </tr>
                              <tr id={4}>
                                <td data="description"><a href="javascript:void(0)" >Tax
                                    computation</a></td>
                              </tr>
                              <tr id={5}>
                                <td data="description"><a href="javascript:void(0)" >GST</a>
                                </td>
                              </tr>
                              <tr id={6}>
                                <td data="description"><a href="javascript:void(0)" >CERTIFICATE
                                    OF RESIDENCE </a></td>
                              </tr>
                              <tr id={7}>
                                <td data="description"><a href="javascript:void(0)" >PERSONAL
                                    TAXES</a></td>
                              </tr>
                              <tr id={8}>
                                <td data="description"><a href="javascript:void(0)" >Vds</a>
                                </td>
                              </tr>
                              <tr id={9}>
                                <td data="description"><a href="javascript:void(0)" >xyz</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="box-footer clearfix no-border" />
                      </div>
                      <div className="box-footer clearfix no-border">
                      </div>
                    </div>
                    <p />
                  </div> */}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* ./wrapper */}
      <div id="myModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
              <h4 className="modal-title">Title</h4>
            </div>
            <div className="modal-body">Loading ...</div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
      <AddNewSettingItem />
      <EditSettingItem />
      <AddNewServiceItem />
      <EditServiceItem />
      <AddNewEmailItem />
      <EditEmailItem />
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { pageSelect, List } = state.Settings;
  return {
    pageSelect,
    List,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setValues: (f, v) => dispatch(actions.settings_data(f, v)),
    getCurrencyList: () => dispatch(actions.getCurrencyList()),
    getNationalitiesList: () => dispatch(actions.getNationalitiesList()),
    getEmployeeTypeList: () => dispatch(actions.getEmployeeTypeList()),
    getShareTypeList: () => dispatch(actions.getSettingsShareTypeList()),
    getTaskSettingsList: () => dispatch(actions.getTaskSettingsList()),
    getInvoiceTypeList: () => dispatch(actions.getInvoiceTypeList()),
    getServices: () => dispatch(actions.getServices()),
    getIdTypes: () => dispatch(actions.getIdTypes()),
    getSalutation: () => dispatch(actions.getSalutation()),
    getDocumentClassification: () =>
      dispatch(actions.getDocumentClassification()),
    getCommonSettingsList: (data) =>
      dispatch(actions.getCommonSettingsList(data)),

      getClientStatus: (data) =>
      dispatch(actions.getClientStatus(data)),
      getDocumentStatus: (data) =>
      dispatch(actions.getDocumentStatus(data)),
      getIdendificationTypes: (data) =>
      dispatch(actions.getIdendificationTypes(data)),
      getFlag: (data) =>
      dispatch(actions.getFlag(data)),
    reset_common_info: () => dispatch(actions.reset_common_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
