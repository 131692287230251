const init = {
  addDeadlineModal: false,
  exportByCompanyModal: false,
  exportByDateModal: false,
  editDeadlineModal: false,
  editDeadlineStatus: 0,
  addDeadlineStatus: 0,
  deleteDeadlineStatus: 0,
  approveModal: false,
  deleteModal: false,
  nothingSelect: false,
  approveStatus: 0,
  clientDeadline: "",
  finYear: "",
  deadlineDate: "",
  remark: "",
  to: [],
  cc: [],
  frequency: "",
  remType: "",
  clickStart: false,
  docLink: "",
  docId: "",
  attachment: "",
  attachmentKey: "",
  docType: "",
  docDate: "",
  notes: "",
  submitDate: "",
  isCompleted: "",
  completedfs: "",
  deadline_id: "",
  deadlineList: [],
  clientdeadline: [],
  newTask: false,
  task: "",
  startDate: "",
  startTime: "",
  endDate: "",
  notes: "",
  assigned: "",
  status: "",
  completedDeadline: [],
  remTypeList:[],
  taskDeadline:'',
  taskCompany:'',
  taskDeadlineDate:'',
  taskFinancialYear:'',
  taskDrop:'',
  taskStartDate:'',
  taskStartTime:'',
  taskEndDate:'',
  taskEndTime:'',
  taskNotes:'',
  taskAssigned:'',
  taskStatus:'',
  deleteSubTask:false,
  addSubTask:false,
  deadlineName:'',
  taskDelete:false,
  editTask:false,
  task_id:'',
  subTask:false,
  selectedCompanies: [],
  selectedTasks: [],
  filterFlag:"showall"

};

export const Deadline = (state = init, action) => {
  switch (action.type) {
    case "DEADLINE_DATA":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
