import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import $ from "jquery";
import {
  getCompletedDeadlineList,
  getDeadlineDrop,
} from "../../../actions/clientDetails/Deadline";
import moment from "moment";

function CompleteDeadline(props) {
  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getCompletedDeadlineList();
    props.getDeadlineDrop();
  }, []);

  function format(d) {
    console.log("did", d);
    return (
      '<table cellpadding="5" cellspacing="0" border="0" >' +
      "<tr>" +
      "<td>Remark:  " +
     
      d.remarks +
      "</td>" +
      "</tr>" +
      "<tr>" +
      //   "<td>Email:</td>" +
      //   "<td>" +
      //   d.email +
      //   "</td>" +
      //   "</tr>" +
      //   "<tr>" +
      //   "<td>Mobile::</td>" +
      //   "<td>" +
      //   d.phone +
      //   "</td>" +
      //   "</tr>" +
      "</table>"
    );
  }

  $(document).ready(function () {
    table = $("#completeDeadline").DataTable({
      pageLength:25,
      lengthMenu: [ 25, 50 ],
      paging: true,
      destroy: true,
      data: props.completedDeadline,
      columnDefs: [
        {
          targets: "_all",
          defaultContent: "",
          // "targets": 12,
        },
        {
          targets: 0,
          orderable: false,
          searchable: false,
          className: "details-control col-xs-1 col-sm-1 col-md-1 col-lg-1",
        },
        // {
        //   targets: 1,
        //   orderable: false,
        //   searchable: false,
        //   className: " btnbox col-xs-1 col-sm-1 col-md-1 col-lg-1",
        //   render: function (data, type, row) {
        //     if (row.check == true) {
        //       return `<input class="check" id=check${row.id}  name=""  checked type="checkbox" />`;
        //     } else {
        //       return `<input class="check" id=check${row.id}  name=${row.id}   type="checkbox" />`;
        //     }
        //   },
        // },
        {
          targets: 1,
          orderable: true,
          className: "col-xs-2 col-sm-2 col-md-2 col-lg-1",
          data: "client_dedadline_task_id",
          render: function (data, type, row) {
            if (props.clientdeadline.length != 0) {
              let activeName = props.clientdeadline.filter(
                (item) => item.id == data
              );
              console.log("data", activeName[0].Name);
              return `<span>${activeName[0].Name}</span>`;
            }
          },
        },
        {
          targets: 2,
          orderable: true,
          className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
          data: "financial_year",
          render: function (data, type, row) {
            if (
              data != null &&
              data != undefined &&
              moment(data).format("DD/MM/YYYY") != "Invalid date"
            ) {
              return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
            } else {
              return "";
            }
          },
        },
        {
          targets: 3,
          orderable: true,
          className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
          data: "deadline_date",
          render: function (data, type, row) {
            if (
              data != null &&
              data != undefined &&
              moment(data).format("DD/MM/YYYY") != "Invalid date"
            ) {
              return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
            } else {
              return "";
            }
          },
        },
        {
          targets: 4,
          orderable: true,
          className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
          data: "date_submitted",
          render: function (data, type, row) {
            if (
              data != null &&
              data != undefined &&
              moment(data).format("DD/MM/YYYY") != "Invalid date"
            ) {
              return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
            } else {
              return "";
            }
          },
        },
        {
          targets: 5,
          orderable: true,
          className: "col-xs-4 col-sm-4 col-md-4 col-lg-3",
          data: "remarks",
        },
        // {
        //   targets: 7,
        //   orderable: true,
        //   className: "col-xs-2 col-sm-2 col-md-2 col-lg-2",
        //   data: "id",
        //   render: function (data, type, row) {
        //     return `<button class="btnEdit btn btn-primary btn-xs" id=edit${data} title=${data} onClick="console.log('Hello',title)" ><span class="glyphicon glyphicon-pencil" /></button>
        //         <button class="btnDel btn btn-primary btn-xs" id=delete${data} title=${data}  data-toggle="modal" data-target="#deletemodal""><span class="glyphicon glyphicon-trash" /></button>
        //         <button class="btn btn-primary btn-xs" id=newtask${data} title=${data}  ><span class="glyphicon glyphicon-tasks" /></button>`;
        //   },
        // },
      ],
      rowCallback: function (row, data, index) {
        if (!data.is_approved) {
          $(row).css("background-color", "#f44242");
        }
      },
    });
    $("#completeDeadline tbody").on("click", ".btnEdit", function () {
      var data = table.row(this).data();
    });

    $("#completeDeadline tbody").on("click", ".btnDel", function () {
      var data = table.row(this).data();
    });

    $("#completeDeadline tbody").on("click", ".btnbox", function (e) {
      var data = table.row(this).data();
    });

    // Add event listener for opening and closing details
    table.on("click", "td.details-control", function () {
      var tr = $(this).closest("tr");
      var row = table.row(tr);
      if (row.data()) {
        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
          tr.removeClass("shown");
          $("tr.shown td.details-control").toggleClass(
            "tr.shown td.details-control"
          );
        } else {
          // Open this row
          row.child(format(row.data())).show();
          tr.addClass("shown");
        }
      }
    });
  });

  return (
    <Fragment>
      <div className="tab-pane fade active in">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                  {/* <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navigationbar"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#" />
                  </div>
                  <div className="collapse navbar-collapse" id="navigationbar">
                    <ul className="nav navbar-nav">
                      <ul className="nav navbar-nav">
                        <li>
                          <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Deadlines
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                role="menuitem"
                                tabIndex={-1}
                                onClick={() =>
                                  props.sendValues("addDeadlineModal", true)
                                }
                              >
                                Add
                              </a>
                            </li>
                            <li>
                              <a
                                className="button-approval"
                                href="javascript:void()"
                                data-title="Approve Selection"
                                data-table="client-deadline"
                                data-selection="Approve"
                                onClick={() => {
                                  if (props.deadline_id !== "") {
                                    props.sendValues("approveModal", true);
                                  } else {
                                    props.setFieldValues(
                                      "nothing_select",
                                      true
                                    );
                                  }
                                }}
                              >
                                Approve
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </ul>
                  </div> */}
                </div>
              </nav>
              <div className="box-tools pull-right" />
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="client-deadline_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                {/* <div className="row">
                                                        <div className="col-sm-6" />
                                                        <div className="col-sm-6">
                                                            <div id="client-deadline_filter" className="dataTables_filter">
                                                                <label>Search:<input type="search" className="form-control input-sm" placeholder aria-controls="client-deadline" /></label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                <div className="row">
                  <div className="col-sm-12">
                   
                          <table
                            id="completeDeadline"
                            className="table table-striped table-bordered col-md-12  dataTable no-footer"
                            role="grid"
                            style={{ marginLeft: 0 ,padding:0}}
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="details-control col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "52px" }}
                                  aria-label="Details"
                                >
                                  Details
                                </th>
                                {/* <th
                                  className="col-xs-1 col-sm-1 col-md-1 col-lg-1 select-checkbox sorting_asc"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "52px" }}
                                  aria-label="Select"
                                >
                                  Select
                                </th> */}
                                <th
                                  className="col-xs-2 col-sm-2 col-md-2 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="client-deadline"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "52px" }}
                                  aria-label="Deadline: activate to sort column ascending"
                                >
                                  Deadline
                                </th>
                                <th
                                  className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="client-deadline"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "52px" }}
                                  aria-label="Fin. Year: activate to sort column ascending"
                                >
                                  Fin. Year
                                </th>
                                <th
                                  className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="client-deadline"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "52px" }}
                                  aria-label="D.Date: activate to sort column ascending"
                                >
                                  D.Date
                                </th>
                                <th
                                  className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="client-deadline"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "58px" }}
                                  aria-label="Submitted: activate to sort column ascending"
                                >
                                  Submitted
                                </th>
                                <th
                                  className="col-xs-4 col-sm-4 col-md-4 col-lg-3 sorting"
                                  tabIndex={0}
                                  aria-controls="client-deadline"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "238px" }}
                                  aria-label="Remarks: activate to sort column ascending"
                                >
                                  Remarks
                                </th>
                                {/* <th
                                  className="col-xs-2 col-sm-2 col-md-2 col-lg-2 sorting"
                                  tabIndex={0}
                                  aria-controls="client-deadline"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "147px" }}
                                  aria-label="Actions: activate to sort column ascending"
                                >
                                  Actions
                                </th> */}
                              </tr>
                              <tbody></tbody>
                            </thead>
                          </table>
                       
                    <div
                      id="client-deadline_processing"
                      className="dataTables_processing panel panel-default"
                      style={{ display: props.processModal }}
                    >
                      Processing...
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5" />
                  <div className="col-sm-7" />
                </div>
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { completedDeadline, clientdeadline } = state.Deadline;
  return {
    completedDeadline,
    clientdeadline,
    processModal:state.Client.processModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getCompletedDeadlineList: () => dispatch(getCompletedDeadlineList()),
    getDeadlineDrop: () => dispatch(getDeadlineDrop()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteDeadline);
