import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "../../../styles/CommenStyle.css";
import { Modal } from 'react-bootstrap';
import * as actions from "../../../actions/clientDetails/Document";



function Egmtransfer(props) {

    useEffect(() => {

    }, [])
    
    return (
        <Modal show={props.transferShareModal} id="myModal" className="modal fade in" style={{display: 'block'}}>
          <div className="modal-content ">
            <div className="modal-header ">
              {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> */}
              <h4 className="modal-title blue-text">EGM - Transfer of Shares
              </h4>
            </div>
            <div className="modal-body pb-0">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="companyname" className="control-label col-sm-4 required">Company Name</label>
                    <div className="col-sm-8">
                      <input className="form-control" required="required" name="companyname" type="text" id="companyname" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationno" className="control-label col-sm-4 required">Registration
                      No / UEN</label>
                    <div className="col-sm-8">
                      <input className="form-control" required="required" name="registrationno" type="text" id="registrationno" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="Ordinary" className="control-label col-sm-4 required">Ordinary Shares</label>
                    <div className="col-sm-8">
                      <input className="form-control" required="required" name="Ordinary" type="text" id="Ordinary" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="Nominal" className="control-label col-sm-4 required">Transfer Nominal</label>
                    <div className="col-sm-8">
                      <div className="calendar-icon">
                        <input className="form-control" required="required" name="Nominal" type="text" id="Nominal" />
                        <span className="left-img">SGD</span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                      <div className="vertical-border" />
                    </div>
                    <div className="col-sm-1" />
                  </div>
                  <div className="form-group">
                    <div className="col-sm-10 text-left">
                      <label className="control-label  ">Shareholding of the Company</label>
                    </div>
                    <div className="col-sm-2 text-right ">
                      <h6 className="blue-text ">Print</h6>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="shareholdin-box" />
                  </div>
                  <div className="row">
                    <div className="col-sm-6 text-center">
                      <h5 className="mb-20 mt-20  bold">Signature</h5>
                    </div>
                    <div className="col-sm-6 text-center">
                      <h5 className="mb-20 mt-20 bold">Signature</h5>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                      <div className="vertical-border" />
                    </div>
                    <div className="col-sm-1" />
                  </div>
                  <div className="form-group">
                    <div className="col-sm-10 text-left">
                      <label className="control-label  mb-20">Supporting documents</label>
                    </div>
                    <div className="col-sm-2 text-right ">
                    </div>
                    <div className="col-sm-12">
                      <div className="support-card">
                        <h5 className="blue-txt">Supporting Document 1</h5>
                        <div className="flex-center-right">
                          <a hrerf="#">View</a>
                          <a hrerf="#">Download</a>
                        </div>
                      </div>
                      <div className="support-card">
                        <h5 className="blue-txt">Supporting Document 1</h5>
                        <div className="flex-center-right">
                          <a hrerf="#">View</a>
                          <a hrerf="#">Download</a>
                        </div>
                      </div>
                      <div className="text-center mt-40 mb-20">
                        <button className="btn success-btn">
                          Accept
                        </button>
                        <button className="btn danger-btn ml-15">
                          Reject
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 pt-20 pb-20 mt-20 flex-center-space" style={{background: 'rgba(92, 132, 149, 0.32)'}}>
                  <button className="btn transparent-btn" onClick={()=>props.sendValues("transferShareModal",false)}>Cancel</button>
                  <button className="btn blue-btn">Send Now</button>
                </div>
              </div>
            </div>
          </div>
      </Modal>
    )
};


const mapStateToProps = (state, ownProps) => {
  const{transferShareModal,}=state.Document

  return{
    transferShareModal,
    selectedid: state.Client.selectedid,
  };

};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
  
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(Egmtransfer);