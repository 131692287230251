import config from "./common/Api_Links";
import { headers } from "./common/Headers";
import { ifTokenExpire } from "./common/TokenValidation";
import history from "../stores/history";
import { changeClientInfo } from "./Client";

export const changeUserInfo = (field, value) => {
  return {
    type: "USER_DATA",
    field,
    value,
  };
};

export const updateRemoveCompanyErrors = (data) => {
  return {
    type: "UPDATE_COMMON_ERRORS",
    data,
  };
};

export const reset_remove_info = () => {
  return {
    type: "RESET_REMOVE_INFO",
  };
};

export const getUsersList = () => {
  var header = headers();
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.getUsersList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            responseData.data = responseData.data.filter(
              (item) => item.list.length > 0
            );
            dispatch(changeUserInfo("usersList", responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          } else {
            dispatch(changeUserInfo("usersList", []));
          }
        }
      });
  };
};

export const getRolesList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.getRolesList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("usersLists", responseData.data));
          } else {
            dispatch(changeUserInfo("usersLists", []));
          }
        }
      });
  };
};

export const getUserPermissionList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.permissionList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("permissionList", responseData.data));
          } else {
            dispatch(changeUserInfo("permissionList", []));
          }
        }
      });
  };
};

export const getPermissionList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.userPermissonList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("userPermission", responseData.data));
          } else {
            dispatch(changeUserInfo("userPermission", []));
          }
        }
      });
  };
};

export const addUser = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.addNewUser, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("addUserShow", false));
            dispatch(changeUserInfo("message", responseData.message));
            dispatch(changeUserInfo("userApiStatus", 1));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(getUsersList());
            }, 2500);
          } else {
            dispatch(changeUserInfo("userApiStatus", 2));
            dispatch(changeUserInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(changeUserInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const editUser = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.edit_user, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("editUserShow", false));
            dispatch(changeUserInfo("message", responseData.message));
            dispatch(changeUserInfo("userApiStatus", 1));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(getUsersList());
            }, 2500);
          } else {
            dispatch(changeUserInfo("userApiStatus", 2));
            dispatch(changeUserInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(changeUserInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const deleteUser = (data) => {
  let value = { id: data };
  var header = headers();
  return (dispatch) => {
    return fetch(config.delete_user, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("deleteUserShow", false));
            dispatch(changeUserInfo("message", responseData.message));
            dispatch(changeUserInfo("userApiStatus", 1));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(changeUserInfo("message", ""));
              dispatch(getUsersList());
            }, 2000);
          } else {
            dispatch(changeUserInfo("userApiStatus", 2));
            dispatch(changeUserInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(changeUserInfo("message", ""));
            }, 2000);
          }
        }
      });
  };
};

export const assignUserRole = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.assign_userRole, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("changeRoleShow", false));
            dispatch(changeUserInfo("message", responseData.message));
            dispatch(changeUserInfo("userApiStatus", 1));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(getUsersList());
            }, 2500);
          } else {
            dispatch(changeUserInfo("userApiStatus", 2));
            dispatch(changeUserInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(changeUserInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const assignCompanyToUser = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.assign_company_to_user, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("removeCompanyShow", false));
            dispatch(changeUserInfo("message", responseData.message));
            dispatch(changeUserInfo("userApiStatus", 1));
            setTimeout(() => {
              dispatch(reset_remove_info());
              dispatch(getUsersList());
            }, 2500);
          } else {
            dispatch(changeUserInfo("userApiStatus", 2));
            dispatch(changeUserInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(reset_remove_info());
            }, 2500);
          }
        }
      });
  };
};

export const removeCompanyFromUser = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.remove_company_from_user, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("removeCompanyShow", false));
            dispatch(changeUserInfo("message", responseData.message));
            dispatch(changeUserInfo("userApiStatus", 1));
            setTimeout(() => {
              dispatch(reset_remove_info());
              dispatch(getUsersList());
            }, 2500);
          } else {
            dispatch(changeUserInfo("userApiStatus", 2));
            dispatch(changeUserInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(reset_remove_info());
            }, 2500);
          }
        }
      });
  };
};

export const addPermission = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.add_permission, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("message", responseData.message));
            dispatch(changeUserInfo("userApiStatus", 1));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(getRolesList());
              dispatch(getUserPermissionList());
              dispatch(getPermissionList());
            }, 2500);
          } else {
            dispatch(changeUserInfo("userApiStatus", 2));
            dispatch(changeUserInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(changeUserInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};



export const updatePermission = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.update_permission, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeUserInfo("showPermissionModal", false));
            dispatch(changeUserInfo("message", responseData.message));
            dispatch(changeUserInfo("userApiStatus", 1));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(getRolesList());
              dispatch(getUserPermissionList());
              dispatch(getPermissionList());
              dispatch(getUsersList());
            }, 2500);
          } else {
            dispatch(changeUserInfo("userApiStatus", 2));
            dispatch(changeUserInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeUserInfo("userApiStatus", 0));
              dispatch(changeUserInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};
