import React, { Fragment } from "react";
import "./LeftSideBar.css";



export default function Footer(props) {
    return (
        <Fragment>
            <footer className="main-footer navbar-fixed-bottom">
                <div className="footer-left">
                    <b>Version</b> ver 2.4.9<br />
                    <strong>Copyright © 2009-{new Date().getFullYear()} <a href="http://smitiv.co">Smitiv Mobile Technologies Opc Pvt Ltd</a>. </strong>
                     &nbsp; All rights reserved.
                </div>
                <div className="footer-right hidden-xs">
                    <strong>Powered by</strong><span><img src="/assets/img/logo-white.png" width="80px" height="25px" style={{marginTop:"20px",marginBottom:'15px'}} /></span>
                </div>
            </footer>
        </Fragment>
    )
}