import React, { Fragment, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import * as actions from "../../../actions/UserManagement";



function Changerole(props) {
  useEffect(() => {
    props.getRolesList();
  }, [])
  const addRoleFunc = (e) => {
    e.preventDefault();
    let input = {
      user_id: props.user_id,
      role: props.role,
    };

    props.assignUserRole(input);

  }

  return (
    <Modal show={props.changeRoleShow} onHide={() => props.setValues('changeRoleShow', false)} className="modal bootstrap-dialog type-primary fade size-normal in" role="dialog" aria-hidden="true" id="f6f05434-ca06-4d28-a795-a540e5958e1b" aria-labelledby="f6f05434-ca06-4d28-a795-a540e5958e1b_title" tabIndex={-1} style={{ zIndex: 1050, display: 'block' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div className="bootstrap-dialog-close-button" style={{ display: 'none' }}><button className="close" aria-label="close">×</button></div>
              <div className="bootstrap-dialog-title" id="f6f05434-ca06-4d28-a795-a540e5958e1b_title">Change Role
                </div>
            </div>
          </div>
          <div className="modal-body">
            {props.userApiStatus ? (
              <div
                className={`alert ${props.userApiStatus === 1 ? "" : "alert-danger"
                  }`}
                role="alert"
              >
                {props.userApiStatus === 1 ? '' : props.message}
              </div>
            ) : (
              ""
            )}
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div className="bootstrap-dialog-body">
                  <form onSubmit={(e) => addRoleFunc(e)} className="form-horizontal"><input name="_token" type="hidden" defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446" />
                    <div className="form-group">
                      <label htmlFor="role" className="control-label col-sm-4 required">Role</label>
                      <div className="col-sm-8">
                        <select className="form-control" value={props.role} onChange={(e) => props.setValues("role", e.target.value)} required="required" id="role" name="role">
                          <option value='' selected="selected">===select role ===</option>
                          {props.usersLists.map((rle, idx) => {
                            return (
                              <option value={rle.id}>{rle.name}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <button className="form-control" type="submit" value="new" name="action">Assign Role to
                        Company</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons"><button onClick={() => props.setValues('changeRoleShow', false)} className="btn form-control" id="85c2abb3-34e8-4170-84df-f8bb46568819"><span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
};



const mapStateToProps = (state, ownProps) => {
  const {
    user_id,
    role,
    usersLists,
    changeRoleShow,
  } = state.UserDatas;
  return {
    user_id,
    role,
    usersLists,
    changeRoleShow,
    userApiStatus: state.UserDatas.userApiStatus,
    message: state.UserDatas.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setValues: (f, v) => dispatch(actions.changeUserInfo(f, v)),
    assignUserRole: (data) => dispatch(actions.assignUserRole(data)),
    getRolesList: () => dispatch(actions.getRolesList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Changerole);