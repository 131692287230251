import React, { Fragment } from "react";
import Header from "./Header";
import Leftsidebar from "./LeftSideBar";
import Footer from "./Footer";
import * as actions from "../actions/Profile";
import { connect } from "react-redux";
import "../components/administration/CompanySetups.css";

function Profile(props) {
  const checkFunc = (e) => {
    if (e.target.value == props.newPassword) {
      props.sendValues("display", "none")
    } else {
      props.sendValues("display", "block")
    }
  };
  return (
    <Fragment >
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div className="content-wrapper" id="content-window">
          <section className="content">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Task */}
              <div className="box box-primary">
                <div className="box-header ui-sortable-handle" style={{ cursor: 'move' }}>
                  <h3 className="box-title">Login</h3>
                </div>{/* /.box-header */}
                <div className="box-body">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Last Login was on 21 March 2021 at 02:57 am</td>
                        <td>1 day ago</td>
                      </tr>
                    </tbody>
                  </table>
                </div>{/* /.box-body */}
                <div className="box-footer clearfix no-border">
                </div>
              </div>
            </section>{/* /.Left col */}
            <section id="changepassword" className="col-lg-12 connectedSortable ui-sortable">
              {/* User Info */}
              <div className="box box-primary">
                <div className="box-header ui-sortable-handle" style={{ cursor: 'move' }}>
                  <h3 className="box-title">Personal</h3>
                </div>{/* /.box-header */}
                <div className="box-body">
                  <p />
                  
                  <div className="col-sm-12">
                      {props.cpStatus === 1 ? (
                        <div className={`alert alert-danger`} role="alert">
                          {props.errorMessage}
                        </div>
                      ) : props.cpStatus === 2 ? (
                        <div className={`alert alert-success`} role="alert">
                          {props.errorMessage}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  <form acceptCharset="UTF-8" className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      let v = {
                        "userid" : sessionStorage.getItem("login_userid"),
                        "password": props.oldPassword,
                        "newpassword": props.newPassword
                      };
                      props.changePassword(v);
                    }}>
                    <div className="form-group">
                      <label htmlFor="old_password" className="control-label col-sm-4 required">Enter your old
                          password</label>
                      <div className="col-sm-8">
                        <input className="form-control" value={props.oldPassword} required="required" name="old_password" onChange={(e) => { props.sendValues("oldPassword", e.target.value) }} type="password" id="old_password" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="control-label col-sm-4 required">Enter your new
                          password</label>
                      <div className="col-sm-8">
                        <input className="form-control" value={props.newPassword} required="required" name="password" onChange={(e) => { props.sendValues("newPassword", e.target.value) }} type="password" id="password" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password_confirmation"  className="control-label col-sm-4 required">Enter
                          your new password again</label>
                      <div className="col-sm-8">
                        <input className="form-control" value={props.confirmPassword} required="required" name="password_confirmation" onChange={(e) => {
                          checkFunc(e)
                          props.sendValues("confirmPassword", e.target.value)
                        }} type="password" id="password_confirmation" />
                        <span className="text-danger" style={{ display: props.display }}>
                          *Both New Password Should Be Same
                        </span>
                      </div>
                    </div>
                    <button className="form-control" type="submit" id="change" value="change" name="action">Change Password</button>
                  </form>
                  <p />
                </div>{/* /.box-body */}
                <div className="box-footer clearfix no-border">
                </div>
              </div>
            </section>
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* User Info */}
              <div className="box box-primary">
                <div className="box-header ui-sortable-handle" style={{ cursor: 'move' }}>
                  <h3 className="box-title">Personal</h3>
                </div>{/* /.box-header */}
                <div className="box-body">
                  <p />
                  <form method="POST" action="https://crm2.gbsc.geneasol-apps.info/dashboard/profile/%7Bprofile%7D" acceptCharset="UTF-8" className="form-horizontal"><input name="_method" type="hidden" defaultValue="PUT" /><input name="_token" type="hidden" defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446" />
                    <div className="form-group">
                      <label htmlFor="name" className="control-label col-sm-4 required">Name</label>
                      <div className="col-sm-8">
                        <input className="form-control" required="required" value={props.name} onChange={(e) => { props.sendValues("name", e.target.value) }} name="name" type="text" defaultValue="SMITIV" id="name" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="company_id" className="control-label col-sm-4 required">Company Name</label>
                      <div className="col-sm-8">
                        <select className="form-control" value={props.companyName} onChange={(e) => { props.sendValues("companyName", e.target.value) }} required="required" id="company_id" name="company_id">
                          <option value={1} selected="selected">Gerard Business Services Consultancy
                            </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <input className="form-control" name="id" type="hidden" defaultValue={25} />
                    </div>
                    <button className="form-control" type="submit" id="update" value="update" name="action">Update Profile</button>
                  </form>
                  <p />
                </div>{/* /.box-body */}
                <div className="box-footer clearfix no-border">
                </div>
              </div>
            </section>
          </section>
        </div>
        <Footer />
      </div>{/* ./wrapper */}
      <div id="myModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
              <h4 className="modal-title">Title</h4>
            </div>
            <div className="modal-body">
              Loading ...
              </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  )
};



const mapStateToProps = (state, ownProps) => {
  const {
    oldPassword,
    newPassword,
    confirmPassword,
    name,
    companyName,
    display,
    cpStatus,
    errorMessage
  } = state.Profile
  return {
    oldPassword,
    newPassword,
    confirmPassword,
    name,
    companyName,
    display,
    cpStatus,
    errorMessage
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (f, v) => dispatch(actions.profileDetails(f, v)),
    changePassword: (v) => dispatch(actions.changePassword(v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);