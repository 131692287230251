import React, { Fragment, useEffect,useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import { connect } from "react-redux";
import "./CompanySetups.css";
import AddNewCompany from "./modals/AddNewCompany";
import DataTable from "react-data-table-component";
import $ from "jquery";
import * as actions from "../../actions/CompanySetups";

const CompanySetups = (props) => {
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getCompanyList();
  }, []);
  useEffect(() => {
    if (props.addCompanyStatus === 1) {
      setTimeout(function () {
        props.changeCompanyInfo("addCompanyStatus", 0);
        props.changeCompanyInfo("company_modal_show", false);
        props.reset_company_info();
      }, 2000);
    }
  }, [props.addCompanyStatus]);

  var table;

  $(document).ready(function () {
    table = $("#companylist").DataTable({
      pageLength:25,
      lengthMenu: [ 25, 50 ],
      paging: true,
      destroy: true,
     
      data: props.companyList,
      columnDefs: [
        {
          targets: "_all",
          defaultContent: "",
          // "targets": 12,
        },
        {
          targets: 0,
          orderable: true,
          className: " company col-xs-3 col-sm-3 col-md-3 col-lg-3",
          data: "company_name",
          id: "rl-name",
        },
        {
          targets: 1,
          orderable: true,
          className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
          data: "owner_name",
        },
        {
          targets: 2,
          orderable: true,
          className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
          data: "address",
        },
      ],
    });
  });

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div className="content-wrapper" id="content-window">
          <section className="content">
          {props.addCompanyStatus ? (
                  <div
                    className={`alert ${
                      props.addCompanyStatus === 1
                        ? "alert-success"
                        : ""
                    }`}
                    role="alert"
                  >
                    {props.addCompanyStatus === 1 ? props.message : ''}
                  </div>
                ) : (
                  ""
                )}
            <div className="box box-primary box_align_left">
              <div className="box-header">
                {" "}
                <i className="glyphicon glyphicon-list-alt" />
                <h3 className="box-title">Company Setup</h3>
                <div>
                  <nav className="navbar navbar-default" role="navigation">
                    <div className="container-fluid" id="navfluid">
                      <div className="navbar-header">
                        <button
                          type="button"
                          className="navbar-toggle"
                          data-toggle="collapse"
                          data-target="#navigationbar"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle navigation</span>
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                        </button>
                        <a className="navbar-brand" href="#" />
                      </div>
                      <div
                        className="collapse navbar-collapse"
                        id="navigationbar"
                      >
                        <ul className="nav navbar-nav">
                          <ul className="nav navbar-nav">
                            <li>
                              <a
                                href="#"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Company
                                <span className="caret" />
                              </a>
                              <ul className="dropdown-menu">
                              {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(62)?( 
                                <li>
                                  <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() =>
                                      props.changeCompanyInfo(
                                        "company_modal_show",
                                        true
                                      )
                                    }
                                  >
                                    New
                                  </a>
                                </li>
                              ):null}
                              </ul>
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </nav>
                  <div className="box-tools pull-right" />
                </div>
                <div />
              </div>
              <div className="box-body">
             
                <div className="table-responsive">
                  <div
                    id="company_wrapper"
                    className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                  >
                  
                    <div className="row">
                      <div className="col-sm-12">
                       
                              <table
                                className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12 dataTable no-footer"
                                role="grid"
                                id="companylist"
                                style={{ marginLeft: 0 }}
                              >
                                <thead>
                                  <tr role="row">
                                    <th
                                      className="col-xs-3 col-sm-3 col-md-3 col-lg-3 sorting_asc"
                                      tabIndex={0}
                                      aria-controls="clients-table"
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 118 }}
                                      aria-sort="ascending"
                                      aria-label="Company Name: activate to sort column descending"
                                    >
                                      Client
                                    </th>
                                    <th
                                      className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                                      tabIndex={0}
                                      aria-controls="clients-table"
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 48 }}
                                      aria-label="Reg. No: activate to sort column ascending"
                                    >
                                      Owner
                                    </th>
                                    <th
                                      className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                                      tabIndex={0}
                                      aria-controls="clients-table"
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 36 }}
                                      aria-label="Inc. Date: activate to sort column ascending"
                                    >
                                      Address
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                           
                        <div
                          id="clients-table_processing"
                          className="dataTables_processing panel panel-default"
                          style={{ display: props.processModal }}
                        >
                          Processing...
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-5" />
                      <div className="col-sm-7" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border"></div>
            </div>
          </section>
        </div>

        <Footer />
      </div>

      {/* ./wrapper */}
      <div id="myModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
              <h4 className="modal-title">Title</h4>
            </div>
            <div className="modal-body">Loading ...</div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
      <AddNewCompany />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    companyList: state.CompanySetups.companyList,
    companyName: state.CompanySetups.companyName,
    ownerName: state.CompanySetups.ownerName,
    address: state.CompanySetups.address,
    phoneNumber: state.CompanySetups.phoneNumber,
    bank: state.CompanySetups.bank,
    bankAccount: state.CompanySetups.bankAccount,
    email: state.CompanySetups.email,
    smtpServerName: state.CompanySetups.smtpServerName,
    smtpUserName: state.CompanySetups.smtpUserName,
    smtpPassword: state.CompanySetups.smtpPassword,
    addCompanyErrors: state.CompanySetups.addCompanyErrors,
    addCompanyStatus: state.CompanySetups.addCompanyStatus,
    message: state.CompanySetups.message,
    processModal:state.Client.processModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyList: () => dispatch(actions.getCompanyList()),
    changeCompanyInfo: (f, e) => dispatch(actions.changeCompanyInfo(f, e)),
    addCompany: (data) => dispatch(actions.addCompany(data)),
    updateCompanyErrors: (data) => dispatch(actions.updateCompanyErrors(data)),
    reset_company_info: () => dispatch(actions.reset_company_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySetups);
