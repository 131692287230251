import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import * as actions from "../../../actions/clientDetails/ShareRegister";
import CreateNew from "../modals/CreateShareRegister";
import Split from "../modals/SplitShares";
import Transfer from "../modals/TransferShares";
import Reduce from "../modals/ReduceShares";
import Convert from "../modals/ConvertShares";
import History from "../modals/History";
import moment from "moment";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// var _ = require("lodash");

function Register(props) {
  var table;
  var sno=0;
  useEffect(() => {
   
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        // console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    const {
      getShareRegisterlist,
      getShareTypeList,
      getShareHolderList,
      getValuePerShareList,
      getShareHistorylist,
      getDocumentClassification,
    } = props;
    setTimeout(() => {
      getShareRegisterlist();
    }, 500);
    // getShareRegisterlist();
    getShareTypeList();
    getShareHolderList(1);
    getValuePerShareList();
    getShareHistorylist();
    getDocumentClassification();
   
  }, []);
  //   console.log("share", props.shareRegisterList);

  const [totalShareHeld, setTotalShareHeld] = useState({});
  const [totalPercentage, setTotalPercentage] = useState("");

  useEffect(() => {
    function format(d) {
      // console.log("did", d);
      return (
        `<tr id="detail-view-${d?.id}"><table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;width:100%">` +
        "<tr>" +
        "<td>Remark : " +
        d?.remarks +
        "</td>" +
        "</tr>" +
        `</table></tr>`
      );
    }
    
    $(document).ready(function () {
      // var result = _(props.shareRegisterListDuplicate)
      // .groupBy((x) => x.share_type)
      // .map((value, key) => ({ name: key, list: value }))
      // .value();

      props.shareRegisterListDuplicate.forEach((item) => {
        totalShareHeld[item.name] = item.list.reduce((acc, val) => {
          return val.no_of_shares_held + acc;
        }, 0);
      });
      let percentage = "";
     
      $("#user tbody").off("click");
      $("#user tbody").on("click", "td.company", function () {
        var data = table.row(this).data();
        sessionStorage.setItem("personalId", data.id);
        // history.push("/client_individual");
      });
      $("#share-table tbody").on(
          "click",
          ".details-control",
          async function () {
            var newid=$(this).attr("id");
            $("#displayhide_"+newid).css("display","block")
          });
     
      
    });
  }, [
    props.shareRegisterList,
    // props.ShareTypeList,
    // props.shareHolderList,
    // props.shareRegisterListDuplicate,
  ]);
  var backgroundindex=-1;
  var backgroundcolors=['#E0BBE4','#FEC8D8','#957DAD','#FFDFD3','#FEC8D8','#0A8580','#668A00','#313E0A','#065A28','#043341']
  var temp="";
  // console.log("props.shareRegisterList",props.shareRegisterList)
  return (
    <Fragment>
      <div id="share-register-tab" className="tab-pane fade active in">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navigationbar"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#" />
                  </div>
                  <div className="collapse navbar-collapse" id="navigationbar">
                    <ul className="nav navbar-nav">
                      <ul className="nav navbar-nav">
                        <li>
                          <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            View
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                role="menuitem"
                                tabIndex={-1}
                                onClick={() =>
                                  props.sendValues("viewHistory", true)
                                }
                              >
                                View Share History
                              </a>
                            </li>
                          </ul>
                        </li>
                        <ul className="nav navbar-nav">
                          <li>
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Shares
                              <span className="caret" />
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  onClick={() =>
                                    props.sendValues("addShares", true)
                                  }
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  Add Shares
                                </a>
                              </li>
                              <li>
                                <a
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() =>
                                    props.sendValues("splitShares", true)
                                  }
                                >
                                  Split Shares
                                </a>
                              </li>
                              <li>
                                <a
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() =>
                                    props.sendValues("transferShares", true)
                                  }
                                >
                                  Transfer Shares
                                </a>
                              </li>
                              <li>
                                <a
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() =>
                                    props.sendValues("reduceShares", true)
                                  }
                                >
                                  Reduce Shares
                                </a>
                              </li>
                              <li>
                                <a
                                  role="menuitem"
                                  tabIndex={-1}
                                  onClick={() =>
                                    props.sendValues("convertShares", true)
                                  }
                                >
                                  Convert Shares
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </ul>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className="box-tools pull-right" />
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="share-register_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                <div className="row">
                  <div className="col-sm-6" />
                  <div className="col-sm-6" />
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="dataTables_scroll">
                      <div
                        className="dataTables_scrollHead"
                        style={{
                          overflow: "hidden",
                          position: "relative",
                          border: "0px",
                          width: "100%",
                        }}
                      >
                        <div
                          className="dataTables_scrollHeadInner"
                          style={{
                            boxSizing: "content-box",
                          }}
                        >
                          <table
                            // className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12 dataTable no-footer"
                            role="grid"
                            style={{ marginLeft: 0 }}
                            id="share-table"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="details-control col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_asc"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "86px" }}
                                  aria-label="Details"
                                >
                                  Details
                                </th>
                                <th
                                  className="col-md-1 col-lg-2 sorting"
                                  tabIndex={0}
                                  aria-controls="share-register"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "211px" }}
                                  aria-label="Res. Date: activate to sort column ascending"
                                >
                                  Res. Date
                                </th>
                                <th
                                  className="col-md-1 col-lg-2 sorting"
                                  tabIndex={0}
                                  aria-controls="share-register"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "211px" }}
                                  aria-label="Res. Date: activate to sort column ascending"
                                >
                                 Acra Filed Date
                                </th>
                                <th
                                  className="col-md-1 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="share-register"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "85px" }}
                                  aria-label="Currency: activate to sort column ascending"
                                >
                                  Currency
                                </th>
                                <th
                                  className="col-xs-2 col-md-1 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="share-register"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "86px" }}
                                  aria-label="Share %: activate to sort column ascending"
                                >
                                  Share %
                                </th>
                                <th
                                  className="col-md-1 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="share-register"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "86px" }}
                                  aria-label="Sh. Held: activate to sort column ascending"
                                >
                                  Sh. Held
                                </th>
                                <th
                                  className="col-md-1 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="share-register"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "87px" }}
                                  aria-label="Val/Share $: activate to sort column ascending"
                                >
                                  Val/Share $
                                </th>
                                <th
                                  className="col-xs-2 col-md-1 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="share-register"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "101px" }}
                                  aria-label="Total Amt: activate to sort column ascending"
                                >
                                  Total Amt
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                            {
                            
                              props.shareRegisterList && props.shareRegisterList.map((val, idx) => {
                                var subdataresult=val.subdata;
                                var subdataresult2=val.subdata;
                                var ototal=0,opercent=0,tpercent=0;
                                console.log("subdataresult",val.sharename)
                                
                                var heading=val.sharename,status=0,totalshare=0,sharepercent=0,overallpercent=0,tv=0;
                                if(heading != temp){
                                  status =1;
                                  sno=0;
                                  temp=heading;
                                  backgroundindex=backgroundindex+1;
                                  props.shareRegisterList.map((valx, idxx) => {
                                    if(heading == valx.sharename ){
                                      totalshare=totalshare+valx.shareheld;
                                      sharepercent=sharepercent+valx.sharepercentage;
                                      overallpercent=overallpercent+100;
                                      var subdataresult1=valx.subdata;
                                      subdataresult1.map((valy, idxy) => {
                                        tv=tv+valy.totalamt;
                                      })
                                    }
                                  })
                                  
                                }
                                subdataresult2.map((valz, idxz) => {
                                  ototal=ototal+valz.totalamt;
                                  opercent=opercent+valz.sharepercentage
                                  tpercent=tpercent+100

                                });
                                opercent=Math.round(opercent).toFixed(2)
                                ototal=ototal.toFixed(2);
                                sno++;
                                return (
                                  <>
                                  {(status == 1) && <tr style={{"backgroundColor":backgroundcolors[backgroundindex],"border":"2px solid #fff"}}><td colspan="8" style={{"padding":"10px"}}>{val.sharename}/ Share Percentage : 100%/ No of Shares Held :{totalshare}/ Total Value :{ tv } </td></tr>}
                                  <tr style={{"backgroundColor": backgroundcolors[backgroundindex],"border":"2px solid #fff"}}><td colspan="8" style={{"padding":"10px"}}>{sno+"). "+val.sharenameoffice}/ Share Percentage : {opercent}%/ No of Shares Held :{val.shareheld}/ Total Value :{ototal} </td></tr>
                                  {
                                    subdataresult.map((val1, idx1) => {
                                      return(
                                        <>
                                        
                                        <tr><td id={ ''+idx+idx1 } title={idx1}  class='details-control '> </td>
                                        <td>{ moment(val1.resolution_date).format("DD/MM/YYYY")}</td>
                                        <td>{ moment(val1.acrafileddate).format("DD/MM/YYYY")}</td>
                                        <td>{ val1.currency }</td>
                                        <td>{ val1.sharepercentage }</td>
                                        <td>{ val1.no_of_shares_held }</td>
                                        <td>{ val1.value_per_share }</td>
                                        <td>{ val1.totalamt }</td>
                                        </tr>
                                        <tr id={ 'displayhide_'+idx+idx1 } style={{"display":"none"}}><td>Remarks:{ val1.remarks }</td></tr>
                                        </>
                                      )
                                      
                                      
                                    })
                                  }
                                </>
                                )
                                
                                
                                
                                
                              })
                            }

                            </tbody>
                          </table>
                        </div>
                      </div>
                      
                    </div>
                    <div
                      id="share-register_processing"
                      className="dataTables_processing panel panel-default"
                      style={{ display: props.processModal }}
                    >
                      Processing...
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5" />
                  <div className="col-sm-7" />
                </div>
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>
      <History />
      <CreateNew />
      <Split />
      <Transfer />
      <Reduce />
      <Convert />
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    shareRegisterList,
    ShareTypeList,
    shareHolderList,
    shareRegisterListDuplicate,
  } = state.ShareReg;
  return {
    shareRegisterList,
    ShareTypeList,
    shareHolderList,
    shareRegisterListDuplicate,
    processModal: state.Client.processModal,
   
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.share_register(k, v)),
    getShareRegisterlist: () => dispatch(actions.getShareRegisterlist()),
    getShareHistorylist: () => dispatch(actions.getShareHistorylist()),
    getShareTypeList: (data) => dispatch(actions.getShareTypeList(data)),
    getShareHolderList: (data) => dispatch(actions.getShareHolderList(data)),
    getValuePerShareList: (data, type) =>
      dispatch(actions.getValuePerShareList(data, type)),
    getDocumentClassification: () =>
      dispatch(actions.getDocumentClassification()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
