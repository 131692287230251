import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Deadline";
import { MultiSelect } from "react-multi-select-component";

function ApproveDeadline(props) {
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    let pList = [];
    props.setFieldValues("selectedCompanies", []);
    props.clientList.map((p) =>
      pList.push({ label: p.company_name, value: p.id })
    );
    setSelected(pList);
  }, [props.clientList]);

  return (
    <Modal
      show={props.exportByCompanyModal}
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="confirmExportByCompanyModal"
      aria-labelledby="confirmExportByCompanyModal"
      tabIndex={-1}
      onHide={() => props.setFieldValues("exportByCompanyModal", false)}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="104fed43-4c83-4895-add8-9df518a9f9c3_title"
              >
                Export By Company
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="row">
                <div className="form-group">
                  <label
                    htmlFor="Document"
                    className="control-label col-sm-4 required"
                  >
                    Export List
                  </label>
                  <div className="col-sm-8">
                    <MultiSelect
                      options={selected}
                      value={props.selectedCompanies}
                      onChange={(to) => props.setFieldValues("selectedCompanies", to)}
                      labelledBy="Select"
                    ></MultiSelect>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn btn-default"
                  id="4997aa0e-751a-455d-8bac-07e55d9d7e2c"
                  onClick={() => props.setFieldValues("exportByCompanyModal", false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-default"
                  id="btn-confirm"
                  onClick={() =>{
                    props.exportByCompany({
                      "client_ids": props.selectedCompanies.map(o => o.value),
                      "task_ids":[],
                      "is_company":true
                    })

                    props.setFieldValues("exportByCompanyModal", false)
                  }
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    exportByCompanyModal: state.Deadline.exportByCompanyModal,
    selectedCompanies: state.Deadline.selectedCompanies,
    deadline_id: state.Deadline.deadline_id,
    approveStatus: state.Deadline.approveStatus,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    clientList: state.Client.clientList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFieldValues: (f, v) => {
      dispatch(actions.deadline(f, v));
    },
    exportByCompany: (ids) => {
      dispatch(actions.exportByCompany(ids));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveDeadline);
