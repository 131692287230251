import { merge } from "lodash";

const init = {
  companyList: [],
  companyName: "",
  ownerName: "",
  address: "",
  phoneNumber: "",
  bank: "",
  bankAccount: "",
  email: "",
  smtpServerName: "",
  smtpUserName: "",
  smtpPassword: "",
  addCompanyErrors: {},
  addCompanyStatus: 0,
  company_modal_show: false,
  message: "",
};

export const CompanySetups = (state = init, action) => {
  switch (action.type) {
    case "COMPANY_LIST":
      return { ...state, companyList: action.data };
    case "CHANGE_COMPANY_INFO":
      return { ...state, [action.field]: action.value };
    case "UPDATE_COMPANY_ERRORS":
      return {
        ...state,
        addCompanyErrors: { ...state.addCompanyErrors, ...action.data },
      };
    case "RESET_COMPANY_INFO":
      return {
        ...state,
        companyName: "",
        ownerName: "",
        address: "",
        phoneNumber: "",
        bank: "",
        bankAccount: "",
        email: "",
        smtpServerName: "",
        smtpUserName: "",
        smtpPassword: "",
        addCompanyErrors: {},
      };
    default:
      return state;
  }
};
