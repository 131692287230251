import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "../../../styles/CommenStyle.css";
import * as actions from "../../../actions/clientDetails/Remainder";
import {getDeadlineDrop} from '../../../actions/clientDetails/Deadline' 
import $ from "jquery";
import moment from "moment";


function Remainder(props) {
     
    var table;
    useEffect(() => {
        window.$(document).ready(function () {
            window.$(".dropdown-toggle").dropdown();
      
            //console.log("template tab");
            window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
              localStorage.setItem("activeTab", $(e.target).attr("href"));
              console.log("selected tab :" + $(e.target).attr("href"));
            });
      
            var activeTab = localStorage.getItem("activeTab");
      
            if (activeTab) {
              window.$('#myTab a[href="' + activeTab + '"]').tab("show");
            }
          });

          props.getRemainderList();
          props.getDeadlineDrop();

    }, []);


    $(document).ready(function () {
        table = $("#remainder").DataTable({
         
          pageLength:25,
          lengthMenu: [ 25, 50 ],
          paging: true,
          destroy: true,
       
          data: props.remainder_list,
          select: {
            style: "multi",
            selector: "td:first-child>input",
          },
          fixedHeader: {
            header: true,
            headerOffset: 50,
          },
          columnDefs: [
            {
              targets: "_all",
              defaultContent: "",
              // "targets": 12,
            },
    
            {
              targets: 0,
              orderable: true,
              searchable: true,
              className: "col-xs-2 col-sm-2 col-md-2 col-lg-2",
              data: "client_dedadline_task_id",
              render: function (data, type, row) {
                if(props.clientdeadline.length !=0){
                let activeName = props.clientdeadline.filter(
                  (item) => item.id === data
                );
                console.log("data", activeName[0].Name);
                return `<span>${activeName[0].Name}</span>`;
                }
              },
            },
            {
                targets: 1,
                orderable: true,
                className: " col-xs-1 col-sm-1 col-md-1 col-lg-1",
                data: "deadline_date",
                render: function (data, type, row) {
                  if (data != null && data != undefined && moment(data).format("DD/MM/YYYY") != "Invalid date") {
                    return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
                  }else{
                    return ''
                  }
                },
              },
            {
              targets: 2,
              orderable: true,
              className: "col-xs-1 col-sm-1 col-md-1 col-lg-1 ",
              data: "email_tag",
              render: function (data, type, row) {
                 
                // let activeName = props.remainderType.filter(
                //   (item) => item.id == data
                // );
                return `<span>${data}</span>`;
              },
              
            },
           
            {
              targets: 3,
              orderable: true,
              searchable: true,
              className: " col-xs-1 col-sm-1 col-md-1 col-lg-1",
              data: "frequency",
              
              render: function (data, type, row) {
                let activeName = props.frequency_list.filter(
                  (item) => item.id == data
                );
                console.log("data", activeName[0].Name);
                return `<span>${activeName[0].Name}</span>`;
              },
            },
          
           
    
          ],
        });
        // $(".btnEdit").on("click", function () {
        //   var data = table.row(this).data();
        //   props.chargeList.map((val, idx) => {
        //     if (val.id == this.title) {
        //       props.sendValues("chargeId", val.id);
        //       props.sendValues("chargeNo", val.charge_no);
        //       props.sendValues("dateRegistered", new Date(val.date_registered));
        //       props.sendValues("dateDischarge", new Date(val.date_discharge));
        //       props.sendValues("currency", val.currency_id);
        //       props.sendValues("amountSecured", val.amount_secured);
        //       props.sendValues("chargee", val.chargee);
        //       props.sendValues("remark", val.remarks);
        //       props.sendValues("editCharges", true);
        //       //   props.setFieldValues("documentUpload", val.document_upload);
        //       //   props.setFieldValues("documentLink", val.document_link);
        //       //   props.setFieldValues("personal_id", val.id);
        //       //   props.setFieldValues("editPersonal", true);
        //     }
        //   });
        // });
    
        // $(".btnDel").on("click", function () {
        //   var data = table.row(this).data();
        //   console.log("data", data);
        //   props.sendValues("charges_id", this.title);
        //   props.sendValues("deleteCharges", true);
        // });
    
        // $(".btnbox").on("click", function (e) {
        //   var data = table.row(this).data();
        //   console.log("data", data);
        //   let arr = [...props.chargeList];
        //   arr[table.row(this).index()].check = e.target.checked;
        //   props.sendValues("chargeList", arr);
        //   console.log("nature", e.target.name);
        //   if (e.target.name != "" && e.target.name != undefined) {
        //     props.sendValues("charges_id", e.target.name);
        //   } else {
        //     props.sendValues("charges_id", "");
        //   }
        // });
    });    

    return (
        <Fragment>
            <div id="client-reminders-tab" className="tab-pane fade active in">
                                    <h3 />
                                    <p>
                                    </p>
                                    <div className="box box-primary box_align_left" boxHeight>
                                        <div className="box-header">
                                            <div>
                                                <nav className="navbar navbar-default" role="navigation">
                                                    <div className="container-fluid" id="navfluid">
                                                        <div className="navbar-header">
                                                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navigationbar" aria-expanded="false">
                                                                <span className="sr-only">Toggle navigation</span>
                                                                <span className="icon-bar" />
                                                                <span className="icon-bar" />
                                                                <span className="icon-bar" />
                                                                <span className="icon-bar" />
                                                            </button>
                                                            <a className="navbar-brand" href="#" />
                                                        </div>
                                                        <div className="collapse navbar-collapse" id="navigationbar">
                                                            <ul className="nav navbar-nav" />
                                                        </div>
                                                    </div>
                                                </nav>
                                                <div className="box-tools pull-right" />
                                            </div>
                                            <div />
                                        </div>
                                        <div className="box-body" boxHeight>
                                            <div className="table-responsive">
                                                <div id="client-reminder_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                                         <table id="remainder" className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12 dataTable no-footer" role="grid" style={{ marginLeft: 0 }}>
                                                                    <thead>
                                                                        <tr role="row">
                                                                            <th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 sorting" tabIndex={0} aria-controls="client-reminder" rowSpan={1} colSpan={1} style={{ width: '364px' }} aria-label="Deadline: activate to sort column ascending">
                                                                                Deadline</th>
                                                                            <th className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_asc" tabIndex={0} aria-controls="client-reminder" rowSpan={1} colSpan={1} style={{ width: '164px' }} aria-sort="ascending" aria-label="D. Date: activate to sort column descending">
                                                                                D. Date</th>
                                                                            <th className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting" tabIndex={0} aria-controls="client-reminder" rowSpan={1} colSpan={1} style={{ width: '165px' }} aria-label="Template: activate to sort column ascending">
                                                                                Template</th>
                                                                            <th className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting" tabIndex={0} aria-controls="client-reminder" rowSpan={1} colSpan={1} style={{ width: '166px' }} aria-label="Rem. Type: activate to sort column ascending">
                                                                                Frequency</th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                           
                                                    <div id="client-reminder_processing" className="dataTables_processing panel panel-default" style={{ display: props.processModal }}>Processing...</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-footer clearfix no-border">
                                        </div>
                                    </div>
                                    <p />
                                </div>

        </Fragment>
    )
};



const mapStateToProps = (state, ownProps) => {
const {remainder_list,remainderType,frequency_list}=state.Remainder
return{
    remainder_list,
    remainderType,frequency_list,
    clientdeadline:state.Deadline.clientdeadline,
    processModal:state.Client.processModal,
}

};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRemainderList:()=>dispatch(actions.getRemainderList()),
        getDeadlineDrop:()=>dispatch(getDeadlineDrop()),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(Remainder);