import config from "../common/Api_Links";
import { headers } from "../common/Headers";
import { ifTokenExpire } from "../common/TokenValidation";
import history from "../../stores/history";
import { changeClientInfo,client_list } from "../Client";
import { s3_file_upload } from "../common/S3_Upload";

export const charges = (field, value) => {
  return {
    type: "CHARGES_DATA",
    field,
    value,
  };
};
export const getListDocumentbyid = (val) => {
  let value =config.listdocumentbyid +val;
  var header = headers();
  return (dispatch) => {
    return fetch(value, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          console.log("props responseData",responseData.data[0])
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            console.log(responseData.data.document_id,"Props check")
                dispatch(charges("docId",responseData.data[0].document_id));
                dispatch(charges("chargeNo",responseData.data[0].id));
                // dispatch(charges("attachment", ""));
                dispatch(charges("attachmentKey", responseData.data[0].keyword));
                dispatch(charges("docType", responseData.data[0].document_type));
                dispatch(charges("docDate", responseData.data[0].resolution_date));
                dispatch(charges("note", responseData.data[0].notes));
                dispatch(charges("description", responseData.data[0].description));
              // dispatch(changeClientInfo("message", responseData.message));
              // dispatch(changeClientInfo("clientApiStatus", 2));
          } else {
            
          }
        }
      });
  };
};
//Edit Document
export const getClientListnew = () => {
  var header = headers();
  let url;
  url = config.client_list;
  
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            if (responseData.status === 1) {
              let add = [...responseData.data];
              responseData.data.map((val, idx) => {
                val.check = false;
              });
              dispatch(client_list(add));
              dispatch(changeClientInfo("processModal", "none"));
            }
          } else {
            dispatch(client_list([]));
          }
        }
      });
  };
};
export const editDocument_Old = (data) => {
  var header = headers();
  return async (dispatch) => {
    // var response = {
    //   status: 1,
    // };
    // if (data.attachment != "") {
    //   response = await s3_file_upload(data);
    // }
    // console.log("persona", response);
    // if (response.status === 1) {
    //   data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.edit_document_old, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(changeClientInfo("editdocumentmodel", false));
              dispatch(changeClientInfo("message", responseData.message));
              
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("idshowelement", ""));
                dispatch(changeClientInfo("client_key", ""));
                
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(charges("chargeNo", ""));
                dispatch(charges("dateDischarge", ""));
                dispatch(charges("currency", ""));
                dispatch(charges("amountSecured", ""));
                dispatch(charges("remark", ""));
                dispatch(charges("docLink", ""));
                dispatch(charges("docId", ""));
                dispatch(charges("addChargesStatus", ""));
                dispatch(charges("docId", ""));
                dispatch(charges("attachment", ""));
                dispatch(charges("attachmentKey", ""));
                dispatch(charges("docType", ""));
                dispatch(charges("docDate", ""));
                dispatch(charges("note", ""));
                dispatch(getClientListnew());
              }, 1500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    // } else {
    //   dispatch(changeClientInfo("clientApiStatus", 2));
    //   dispatch(changeClientInfo("message", response.message));
    //   setTimeout(() => {
    //     dispatch(changeClientInfo("clientApiStatus", 0));
    //     dispatch(changeClientInfo("message", ""));
    //   }, 2500);
    // }
  };
};

export const addDocument_Old = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_document_old, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          console.log("res", response);
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(changeClientInfo("adddocumentmodel", false));
              dispatch(changeClientInfo("message", responseData.message));
              
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("idshowelement", ""));
                dispatch(changeClientInfo("client_key", ""));
                
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(charges("chargeNo", ""));
                dispatch(charges("dateDischarge", ""));
                dispatch(charges("currency", ""));
                dispatch(charges("amountSecured", ""));
                dispatch(charges("remark", ""));
                dispatch(charges("docLink", ""));
                dispatch(charges("docId", ""));
                dispatch(charges("addChargesStatus", ""));
                dispatch(charges("docId", ""));
                dispatch(charges("attachment", ""));
                dispatch(charges("attachmentKey", ""));
                dispatch(charges("docType", ""));
                dispatch(charges("docDate", ""));
                dispatch(charges("note", ""));
                dispatch(charges("description", ""));
                dispatch(getClientListnew());
              }, 1500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};



export const addCharegs = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_charges, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(charges("addCharges", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(charges("chargeNo", ""));
                dispatch(charges("dateDischarge", ""));
                dispatch(charges("currency", ""));
                dispatch(charges("amountSecured", ""));
                dispatch(charges("remark", ""));
                dispatch(charges("docLink", ""));
                dispatch(charges("docId", ""));
                dispatch(charges("addChargesStatus", ""));
                dispatch(charges("docId", ""));
                dispatch(charges("attachment", ""));
                dispatch(charges("attachmentKey", ""));
                dispatch(charges("docType", ""));
                dispatch(charges("docDate", ""));
                dispatch(charges("note", ""));
                dispatch(getChargesList());
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const getChargesList = () => {
  var header = headers();
  let data = {
    client_id: sessionStorage.getItem("personalId"),
  };

  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.get_charges_list, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) { 
            dispatch(charges("chargeList", responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          } else {
            dispatch(charges("chargeList", []));
          }
        }
      });
  };
};

export const approveCharges = (val) => {
  var header = headers();
  let value = { client_charge_id: val };

  return (dispatch) => {
    return fetch(config.approve_Charges, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status === 1) {
          ifTokenExpire(responseData);
          dispatch(charges("approve_modal", false));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(charges("charges_id", ''));
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(getChargesList());
           
          }, 2500);
        } else {
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const deleteCharge = (val) => {
  var header = headers();
  let value = { client_charge_id: val };

  return (dispatch) => {
    return fetch(config.delete_Charges, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(charges("deleteCharges", false));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(getChargesList());
              dispatch(charges("charges_id", ''));
            }, 2500);
          } else {
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const editCharegs = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.edit_charges, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(charges("editCharges", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(charges("chargeNo", ""));
                dispatch(charges("dateDischarge", ""));
                dispatch(charges("currency", ""));
                dispatch(charges("amountSecured", ""));
                dispatch(charges("remark", ""));
                dispatch(charges("docLink", ""));
                dispatch(charges("docId", ""));
                dispatch(charges("editChargesStatus", ""));
                dispatch(charges("docId", ""));
                dispatch(charges("attachment", ""));
                dispatch(charges("attachmentKey", ""));
                dispatch(charges("docType", ""));
                dispatch(charges("docDate", ""));
                dispatch(charges("note", ""));
                dispatch(getChargesList());
                dispatch(charges("charges_id", ''));
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const getCurrencyList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_currency_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(charges("currencyList", responseData.data));
          } else {
            dispatch(charges("currencyList", []));
          }
        }
      });
  };
};
