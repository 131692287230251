import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "../../../styles/CommenStyle.css";
import { Modal } from 'react-bootstrap';
import * as actions from "../../../actions/clientDetails/Personal"; 



function NothingSelect(props) {

    useEffect(() => {

    }, [])

    return (
        <Modal
        show ={props.nothing_select}
        className="modal bootstrap-dialog type-primary fade size-normal "
        role="dialog"
        aria-hidden="true"
        id="confirmation"
        aria-labelledby="confirmation"
        tabIndex={-1}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="bootstrap-dialog-header">
                <div
                  className="bootstrap-dialog-close-button"
                  style={{ display: "none" }}
                >
                  <button className="close" aria-label="close">
                    ×
                  </button>
                </div>
                <div
                  className="bootstrap-dialog-title"
                  id="fb1ca9c8-3a60-4a30-9567-e165dbb0fcf1_title"
                >
                  Confirmation
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="bootstrap-dialog-body">
                <div className="bootstrap-dialog-message">Nothing selected</div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="bootstrap-dialog-footer">
                <div className="bootstrap-dialog-footer-buttons">
                  <button
                    className="btn btn-default"
                    id="7a1806c6-36f8-4445-b36d-f4fd145ab46c"
                    onClick={()=>props.setFieldValues("nothing_select",false)}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-default disabled"
                    id="btn-confirm"
                    disabled
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
};

const mapStateToProps = (state, ownProps) => {
    return{
        nothing_select:state.Personal.nothing_select,
    }

};

const mapDispatchToProps = (dispatch, ownProps) => {
     return{
         setFieldValues : (f,v)=>{dispatch(actions.personal_data(f,v))}
     }
};

export default connect(mapStateToProps, mapDispatchToProps)(NothingSelect);