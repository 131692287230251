import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "../../../styles/CommenStyle.css";
import { Modal } from 'react-bootstrap';
import * as actions from "../../../actions/clientDetails/Deadline";



function DeleteDeadline(props) {

  useEffect(() => {

  }, [])

  return (
    <Modal show={props.deleteModal} className="modal bootstrap-dialog type-primary fade size-normal " onHide={() => props.setFieldValues("deleteModal", false)} role="dialog" aria-hidden="true" id="deletedeadline" aria-labelledby="deletedeadline" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="bootstrap-dialog-header">
              <div className="bootstrap-dialog-close-button" style={{ display: 'none' }}><button className="close" aria-label="close">×</button></div>
              <div className="bootstrap-dialog-title" id="1d440a62-e34a-4ca1-9259-beadeadea7d3_title">Confirm
                  Delete</div>
            </div>
          </div>
          <div className="modal-body">
            {props.clientApiStatus ? (
              <div
                className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                  }`}
                role="alert"
              >
                {props.clientApiStatus === 1 ? "" : props.message}
              </div>
            ) : (
              ""
            )}
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">Confirm to delete ?</div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons"><button onClick={() => {
                if (props.taskDelete == true) {

                  props.deleteTask(props.task_id)
                } else {
                  props.deleteDeadline(props.deadline_id)
                }
              }} className="btn form-control btn-danger" id="a2bf198e-564f-46f7-9969-cee092baaa2d"><span className="bootstrap-dialog-button-icon glyphicon glyphicon-check" />Delete</button><button onClick={() => {
                props.setFieldValues("deleteModal", false)
                props.setFieldValues("deadline_id", '');
              }} className="btn form-control" id="c7f02962-d633-4ca8-bf0a-b1afa5b11b41" data-dismiss="modal"><span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
};

const mapStateToProps = (state, ownProps) => {
  return {
    deleteModal: state.Deadline.deleteModal,
    deadline_id: state.Deadline.deadline_id,
    deleteDeadlineStatus: state.Deadline.deleteDeadlineStatus,
    taskDelete: state.Deadline.taskDelete,
    task_id: state.Deadline.task_id,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFieldValues: (f, v) => {
      dispatch(actions.deadline(f, v));
    },
    deleteDeadline: (id) => {
      dispatch(actions.deleteDeadline(id));
    },
    deleteTask: (id) => {
      dispatch(actions.deleteTask(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDeadline);