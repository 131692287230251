import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "../../../styles/CommenStyle.css";
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import * as actions from "../../../actions/clientDetails/Deadline";



function EditTask(props) {

    useEffect(() => {

    }, [])

    const editFunc = (e) => {
        e.preventDefault();

        let input = {
            client_id: props.client_id ? props.client_id : sessionStorage.getItem("personalId"),
            client_dedadline_id: props.client_dedadline_id,
            task_id: props.task_id,
            financial_year: moment(props.taskFinancialYear).format('YYYY-MM-DD'),
            deadline_date: moment(props.taskDeadlineDate).format('YYYY-MM-DD'),
            task_service_id: props.taskDrop,
            start_date: moment(props.taskStartDate).format('YYYY-MM-DD'),
            start_time: props.taskStartTime,
            end_date: moment(props.taskEndDate).format('YYYY-MM-DD'),
            end_time: props.taskEndTime,
            user_id: props.taskAssigned,
            status: props.taskStatus,
            notes: props.taskNotes,

        };
        console.log("sel", input)
        props.editNewTask(input)

    }


    return (
        <Modal
            className="modal bootstrap-dialog type-primary fade size-normal "
            role="dialog"
            aria-hidden="true"
            id="newtask"
            aria-labelledby="newtask"
            tabIndex={-1}
            show={props.editTask}
            onHide={() => props.setFieldValues('editTask', false)}
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bootstrap-dialog-draggable">
                        <div className="bootstrap-dialog-header">
                            <div
                                className="bootstrap-dialog-close-button"
                                style={{ display: "none" }}
                            >
                                <button className="close" aria-label="close">
                                    ×
                  </button>
                            </div>
                            <div
                                className="bootstrap-dialog-title"
                                id="a1b5292c-fdb9-4d58-a391-05de2ce7d98b_title"
                            >
                                Edit Task
                </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="bootstrap-dialog-body">
                            <div className="bootstrap-dialog-message">
                                <div>
                                    <form
                                        onSubmit={editFunc}
                                        className="form-horizontal"
                                    >
                                        <input
                                            name="_token"
                                            type="hidden"
                                            defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                                        />
                                        <div className="form-group">
                                            <label
                                                htmlFor="deadlines"
                                                className="control-label col-sm-4"
                                            >
                                                Deadline
                        </label>
                                            <div className="form-control-static" id="deadlines">
                                                TAX Filing
                        </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="companyname"
                                                className="control-label col-sm-4"
                                            >
                                                Company
                        </label>
                                            <div className="form-control-static" id="companyname">
                                                {sessionStorage.getItem("selectedClient")}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="deadlinedate"
                                                className="control-label col-sm-4"
                                            >
                                                Deadline Date
                        </label>
                                            <div className="form-control-static" id="deadlinedate">
                                                {moment(props.taskDeadlineDate).format("DD/MM/YYYY")}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="financialyear"
                                                className="control-label col-sm-4"
                                            >
                                                Financial Year
                        </label>
                                            <div className="form-control-static" id="financialyear">
                                                {moment(props.taskFinancialYear).format("DD/MM/YYYY")}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="service_id"
                                                className="control-label col-sm-4 required"
                                            >
                                                Task
                        </label>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    required="required"
                                                    id="service_id"
                                                    name="service_id"
                                                    value={props.taskDrop}
                                                    onChange={(e) => { props.setFieldValues("taskDrop", e.target.value) }}
                                                >
                                                    <option value=''>==Select Task ==</option>
                                                    {props.clientdeadline && props.clientdeadline.map((lis) => {
                                                        return (
                                                            <option value={lis.id}>{lis.Name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="startdate"
                                                className="control-label col-sm-4 required"
                                            >
                                                Start Date
                        </label>
                                            <div className="col-sm-8">
                                                <DatePicker
                                                    showPopperArrow={false}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    selected={props.taskStartDate}
                                                    locale={"en"}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    popperModifiers={{
                                                        offset: {
                                                            enabled: true,
                                                            offset: "0px, 0px",
                                                        },
                                                        preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false,
                                                            boundariesElement: "viewport",
                                                        },
                                                    }}
                                                    onChange={(Date) => {
                                                        props.setFieldValues("taskStartDate", Date);
                                                    }}
                                                >
                                                    <div
                                                        className="trash-icon"
                                                        onClick={() =>
                                                            props.setFieldValues("taskStartDate", "")
                                                        }
                                                    >
                                                        <span class="glyphicon glyphicon-trash"></span>
                                                    </div>
                                                </DatePicker>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="starttime"
                                                className="control-label col-sm-4 required"
                                            >
                                                Start Time
                        </label>
                                            <div className="col-sm-8">
                                                <input
                                                    className="form-control"
                                                    required="required"
                                                    name="starttime"
                                                    type="time"
                                                    value={props.taskStartTime}
                                                    onChange={(e) => { props.setFieldValues("taskStartTime", e.target.value) }}
                                                    id="starttime"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="finishdate"
                                                className="control-label col-sm-4 required"
                                            >
                                                End Date
                        </label>
                                            <div className="col-sm-8">
                                                <DatePicker
                                                    showPopperArrow={false}
                                                    dateFormat={"dd/MM/yyyy"}
                                                    selected={props.taskEndDate}
                                                    locale={"en"}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    popperModifiers={{
                                                        offset: {
                                                            enabled: true,
                                                            offset: "0px, 0px",
                                                        },
                                                        preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false,
                                                            boundariesElement: "viewport",
                                                        },
                                                    }}
                                                    onChange={(Date) => {
                                                        props.setFieldValues("taskEndDate", Date);
                                                    }}
                                                >
                                                    <div
                                                        className="trash-icon"
                                                        onClick={() =>
                                                            props.setFieldValues("taskEndDate", "")
                                                        }
                                                    >
                                                        <span class="glyphicon glyphicon-trash"></span>
                                                    </div>
                                                </DatePicker>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="finishtime"
                                                className="control-label col-sm-4 required"
                                            >
                                                End Time
                        </label>
                                            <div className="col-sm-8">
                                                <input
                                                    className="form-control"
                                                    required="required"
                                                    name="finishtime"
                                                    type="time"
                                                    value={props.taskEndTime}
                                                    onChange={(e) => { props.setFieldValues("taskEndTime", e.target.value) }}
                                                    id="finishtime"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="notes"
                                                className="control-label col-sm-4"
                                            >
                                                Notes
                        </label>
                                            <div className="col-sm-8">
                                                <textarea
                                                    className="form-control"
                                                    rows={3}
                                                    name="notes"
                                                    cols={50}
                                                    id="notes"
                                                    value={props.taskNotes}
                                                    onChange={(e) => { props.setFieldValues("taskNotes", e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="assigned"
                                                className="control-label col-sm-4 required"
                                            >
                                                Assigned
                        </label>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    required="required"
                                                    id="assigned"
                                                    name="assigned"
                                                    value={props.taskAssigned}
                                                    onChange={(e) => { props.setFieldValues('taskAssigned', e.target.value) }}
                                                >
                                                    <option value=''>==Select==</option>
                                                    {props.personalList && props.personalList.map((per) => {
                                                        return (
                                                            <option value={per.id}>{per.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="status"
                                                className="control-label col-sm-4 required"
                                            >
                                                Status
                        </label>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    required="required"
                                                    id="status"
                                                    name="status"
                                                    value={props.taskStatus}
                                                    onChange={(e) => { props.setFieldValues('taskStatus', e.target.value) }}
                                                >
                                                    <option value=''>==select==</option>
                                                    <option value={1} >
                                                        Open
                            </option>
                                                    <option value={2}>Completed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div
                                                className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                                id="errortext"
                                            />
                                        </div>
                                        <div className="col-sm-8">
                                            <input
                                                className="form-control"
                                                name="name"
                                                type="hidden"
                                            />
                                        </div>
                                        <div className="col-sm-8">
                                            <input
                                                className="form-control"
                                                name="id"
                                                type="hidden"
                                            />
                                        </div>
                                        <div className="col-sm-8">
                                            <input
                                                className="form-control"
                                                name="parent_id"
                                                type="hidden"
                                            />
                                        </div>
                                        <div className="col-sm-8">
                                            <input
                                                className="form-control"
                                                name="deadline_id"
                                                type="hidden"
                                                defaultValue={2961}
                                            />
                                        </div>
                                        <div className="col-sm-12" >
                                            {props.clientApiStatus ? (
                                                <div
                                                    className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                                                        }`}
                                                    role="alert"
                                                >
                                                    {props.clientApiStatus === 1 ? "" : props.message}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <button
                                            className="form-control"
                                            type="submit"
                                            id="button"
                                            value="addnew"
                                            name="action"
                                        >
                                            Update
                      </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="bootstrap-dialog-footer">
                            <div className="bootstrap-dialog-footer-buttons">
                                <button
                                    className="btn form-control"
                                    id="3d7be7fc-fcc4-4fc7-92df-7d0df0073e81"
                                    onClick={() => props.setFieldValues('editTask', false)}
                                >
                                    <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                    Close
                  </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

const mapStateToProps = (state, ownProps) => {
    const {
        editTask,
        deadline_id,
        taskDeadline,
        taskCompany,
        taskDeadlineDate,
        taskFinancialYear,
        taskDrop,
        taskStartDate,
        taskStartTime,
        taskEndDate,
        taskEndTime,
        taskNotes,
        taskAssigned,
        taskStatus,
        deadlineName,
        deadlineDate,
        finYear,
        clientdeadline,
        task_id,
        client_id,
        client_dedadline_id
    } = state.Deadline
    return {
        editTask,
        deadline_id,
        taskDeadline,
        taskCompany,
        taskDeadlineDate,
        taskFinancialYear,
        taskDrop,
        taskStartDate,
        taskStartTime,
        taskEndDate,
        taskEndTime,
        taskNotes,
        taskAssigned,
        taskStatus,
        deadlineName,
        deadlineDate,
        finYear,
        clientdeadline,
        task_id,
        personalList: state.Personal.personalList,
        clientApiStatus: state.Client.clientApiStatus,
        message: state.Client.message,
        client_id,
        client_dedadline_id
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setFieldValues: (f, v) => {
            dispatch(actions.deadline(f, v));
        },
        editNewTask: (input) => {
            dispatch(actions.editNewTask(input));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTask);