import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import {
  personal_data,
  getIdentificationType,
  getDesignation,
  getSalutation,
  getNationalities,
  getFlag,
  addPersonal,
} from "../../../actions/clientDetails/Personal";
import * as settings from "../../../actions/Settings";
import * as client from "../../../actions/Client";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';

function AddNewPersonal(props) {
  useEffect(() => {
    window.$(document).ready(function () {
      $("#addpersonnel").draggable({
        handle: ".modal-header"
      });
    })
  })
  useEffect(() => {
    props.identificationType();
    props.getClientStatus(1);
    props.getClientIdList();
    props.getDesignation();
    props.getSalutation();
    props.getNationalities();
    props.getFlag();
    props.sendValues("documentLink", "");
    props.getDocumentList();
    props.singleperson_list();
    props.allListDocuments(sessionStorage.getItem("personalId"));
  }, []);
  useEffect(() => {
    if (props.singlePersonList) {
      if (props.singlePersonList[0]) {
        console.log(props.singlePersonList[0], "sdasda");

        if (document.getElementById("identificationtype")) {
          if (document.getElementById("identificationtype").value != null) {
            props.sendValues(
              "idType",
              props.singlePersonList[0].identification_type_id
            );
            props.sendValues(
              "dateIssue",
              props.singlePersonList[0].id_date_of_issue != "0000-00-00" &&
                moment(props.singlePersonList[0].id_date_of_issue)._d
            );
            props.sendValues(
              "dateExpiry",
              props.singlePersonList[0].id_date_of_expiry != "0000-00-00" &&
                moment(props.singlePersonList[0].id_date_of_expiry)._d
            );
            // props.sendValues(
            //   "dateAppointment",
            //   props.singlePersonList[0].apponitment_date != "0000-00-00" &&
            //     props.singlePersonList[0].apponitment_date != null &&
            //     moment(props.singlePersonList[0].apponitment_date)._d
            // );
            // props.sendValues(
            //   "appointmentAcradate",
            //   props.singlePersonList[0].acra_apponitment_date != "0000-00-00" &&
            //     props.singlePersonList[0].acra_apponitment_date != null &&
            //     moment(props.singlePersonList[0].acra_apponitment_date)._d
            // );
            // props.sendValues(
            //   "resignationAcradate",
            //   props.singlePersonList[0].acra_resignation_date != "0000-00-00" &&
            //     props.singlePersonList[0].acra_resignation_date != null &&
            //     moment(props.singlePersonList[0].acra_resignation_date)._d
            // );
            props.sendValues(
              "designation",
              props.singlePersonList[0].designation_id
            );
            props.sendValues("client_id", props.singlePersonList[0].client_id);
            props.sendValues("status", props.singlePersonList[0].status);
            props.sendValues(
              "dateBirth",
              props.singlePersonList[0].date_of_birth != "0000-00-00" &&
                moment(props.singlePersonList[0].date_of_birth)._d
            );
            props.sendValues(
              "salutation",
              props.singlePersonList[0].salutation_id
            );
            props.sendValues("name", props.singlePersonList[0].name);
            props.sendValues("address", props.singlePersonList[0].address);
            props.sendValues("email", props.singlePersonList[0].email);
            props.sendValues("phone", props.singlePersonList[0].phone);
            props.sendValues(
              "nationality",
              props.singlePersonList[0].nationality_id
            );
            props.sendValues("flag", props.singlePersonList[0].flag);
            props.sendValues("copiedStatus_value", true);
            document.getElementById("identificationtype").value =
              props.singlePersonList[0].identification_type_id;
            document.getElementById("employeetype_id").value =
              props.singlePersonList[0].designation_id;
            document.getElementById("salutation").value =
              props.singlePersonList[0].salutation_id;
            document.getElementById("nationality").value =
              props.singlePersonList[0].nationality_id;
            document.getElementById("flag_id").value =
              props.singlePersonList[0].flag;
          }
        }
      }
    } else {
      props.sendValues("copiedStatus_value", true);
    }
  }, [props.singlePersonList]);
  const handleChange = async (e) => {
    console.log("call");
    props.sendValues("idNo", e.target.value);
    props.getIdentificationno(e.target.value);
  };

  const getfilename = (e) => {
    var splitedval = e.target.value.split("_");

    props.sendValues("documentLink", e.target.value);
    var index = e.nativeEvent.target.selectedIndex + 1;

    if (e.target.value != 0 && e.target.value != "") {
      props.sendValues("documentLink", e.target.value);

      props.alldocumentclientList &&
        props.alldocumentclientList.map((filepathresultnn, indexvalnn) => {
          if (splitedval[0] == filepathresultnn.id) {
            document.getElementById("viewdocument").style.display = "block";
            document.getElementById("viewdocument").href =
              filepathresultnn.file_path;
          }
        });
    } else {
      document.getElementById("viewdocument").style.display = "none";
    }
  };

  const addFunc = (event) => {
    event.preventDefault();
    // if (props.dateAppointment != "") {
      let document_date = "";
      if (props.documentDate != "") {
        document_date = moment(props.documentDate).format("YYYY-MM-DD");
      }
      var userData = JSON.parse(localStorage.getItem("userData"));
      let copystatus = "",
        copystatus_value = "";
      if (props.copiedStatus == true) {
        copystatus = 1;
      } else {
        copystatus = 0;
      }
      //copiedStatus_value
      if (props.copiedStatus_value == true) {
        copystatus_value = 1;
      } else {
        copystatus_value = 1;
      }
      
      let data = {
        company_id: userData.company_id,
        client_id: sessionStorage.getItem("personalId") ? sessionStorage.getItem("personalId") : props.client_id,
        identification_no: props.idNo,
        identification_type_id: props.idType,
        id_date_of_issue: props.dateIssue ? moment(props.dateIssue).format("YYYY-MM-DD") : "Invalid date",
        id_date_of_expiry: props.dateExpiry ? moment(props.dateExpiry).format("YYYY-MM-DD") : "Invalid date",
        designation_id: props.designation,
        status: props.status,
        salutation_id: props.salutation,
        name: props.name,
        address: props.address,
        nationality_id: props.nationality,
        apponitment_date: props.dateAppointment ? moment(props.dateAppointment).format("YYYY-MM-DD") : "Invalid date",
        resignation_date: props.dateResignation ? moment(props.dateResignation).format("YYYY-MM-DD") : "Invalid date",
        acra_apponitment_date: props.appointmentAcradate ? moment(props.appointmentAcradate).format("YYYY-MM-DD") : "Invalid date",
        acra_resignation_date: props.resignationAcradate ? moment(props.resignationAcradate).format("YYYY-MM-DD") : "Invalid date",
        date_of_birth: props.dateBirth ? moment(props.dateBirth).format("YYYY-MM-DD") : "Invalid date",
        email: props.email,
        phone: props.phone,
        flag: props.flag,
        flag_remarks: props.flagRemark,
        document_upload: props.documentUpload,
        document_link: props.documentLink,
        person_copied_status: copystatus,
        person_login_status: copystatus_value,
        active_tab: props.pageSelected,
        document_id: props.documentId,
        attachment: props.attachment,
        attachment_keyword: props.documentKey,
        document_type: props.documentType,
        document_date: document_date,
        notes: props.note,
        description: props.description,
        smsOTPStatus: props.smsOTPStatus,
        emailOTPStatus: props.emailOTPStatus,
      };
      // console.log("chinnu data", data);
      props.addPersonalData(data);
    // } else {
    //   alert("please Select Appointment Date");
    // }
  };

  return (
    <Modal
      show={props.addPersonal}
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="addpersonnel"
      aria-labelledby="addpersonnel"
      tabIndex={-1}
      // onHide={() => props.sendValues("addPersonal", false)}
    >
      <div className=" modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              {props.officeBearer == true ? (
                <div
                  className="bootstrap-dialog-title"
                  id="dbe3cda7-7fe1-4237-8d29-d1fa184efa50_title"
                >
                  Add Office Bearer
                </div>
              ) : (
                <div
                  className="bootstrap-dialog-title"
                  id="674e7cf8-7f90-492b-8f8f-1f9a704a6ce2_title"
                >
                  Add Personal
                </div>
              )}
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div>
                  <form
                    id="form"
                    className="form-horizontal"
                    onSubmit={(e) => addFunc(e)}
                  >
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                    />
                    <div className="form-group">
                      <label
                        htmlFor="identification"
                        className="control-label col-sm-4 required"
                      >
                        Identification No
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.idNo}
                          onChange={handleChange}
                          required="required"
                          name="identification"
                          type="text"
                          id="identification"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="identificationtype"
                        className="control-label col-sm-4 required"
                      >
                        Identification Type
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.idType}
                          required="required"
                          id="identificationtype"
                          disabled={`${props?.singlePersonList?.length > 0 ? true : ""}`}
                          onChange={(e) =>
                            props.sendValues("idType", e.target.value)
                          }
                          name="identificationtype"
                        >
                          <option value="" selected="selected">
                            === Select ===
                          </option>
                          {props.idList.map((identi, idx) => {
                            return (
                              <option key={idx} value={identi.id}>
                                {identi.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    {props.officeBearer == true ? (
                      <div className="form-group">
                        <label
                          htmlFor="currency_id"
                          className="control-label col-sm-4 required"
                        >
                          Client
                        </label>
                        <div className="col-sm-8">
                          <select
                            className="form-control"
                            required="required"
                            id="currency_id"
                            value={props.client_id}
                            name="client_id"
                            onChange={(e) =>
                              props.sendValues("client_id", e.target.value)
                            }
                          >
                            <option value="">Select Client Name</option>
                            {props.clientIdList.map((cur) => {
                              return (
                                <option value={cur.id}>
                                  {cur.company_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    ) : (
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          name="client_id"
                          type="hidden"
                          defaultValue={489}
                        />
                      </div>
                    )}
                    <div className="form-group">
                      <label
                        htmlFor="identification_date_of_issue"
                        className="control-label col-sm-4 required"
                      >
                        ID Date of Issue
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateIssue}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateIssue", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() => props.sendValues("dateIssue", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                        {/* <p className="help-block">* Required </p> */}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="identification_date_of_expiry"
                        className="control-label col-sm-4"
                      >
                        ID Date of Expiry
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateExpiry}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateExpiry", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() => props.sendValues("dateExpiry", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="employeetype_id"
                        className="control-label col-sm-4 required"
                      >
                        Designation
                      </label>
                      <div className="col-sm-8">
                        <select
                          onChange={(e) =>
                            props.sendValues("designation", e.target.value)
                          }
                          className="form-control"
                          value={props.designation}
                          required="required"
                          id="employeetype_id"
                          name="employeetype_id"
                        >
                          <option value selected="selected">
                            === Select ===
                          </option>
                          {props.designationList.map((desig, idx) => {
                            return (
                              <option key={idx} value={desig.id}>
                                {desig.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="currency_id"
                        className="control-label col-sm-4 required"
                      >
                        Status
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          required="required"
                          id="currency_id"
                          name="currency_id"
                          value={props.clientstatus}
                          onChange={(e) =>
                            props.sendValues("status", e.target.value)
                          }
                        >
                          <option value="">Select Client status</option>
                          {props.List1.map((cur) => {
                            return <option value={cur.id}>{cur.Name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="salutation"
                        className="control-label col-sm-4 required"
                      >
                        Salutation
                      </label>
                      <div className="col-sm-8">
                        <select
                          onChange={(e) =>
                            props.sendValues("salutation", e.target.value)
                          }
                          className="form-control"
                          value={props.salutation}
                          required="required"
                          disabled={`${props?.singlePersonList?.length > 0 ? true : ""}`}
                          id="salutation"
                          name="salutation"
                        >
                          <option value="" selected="selected">
                            === Select ===
                          </option>
                          {props.salutationList.map((sal, idx) => {
                            return (
                              <option key={idx} value={sal.id}>
                                {sal.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="employeename"
                        className="control-label col-sm-4 required"
                      >
                        Name
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.name}
                          onChange={(e) =>
                            props.sendValues("name", e.target.value)
                          }
                          defaultValue={props.name}
                          required="required"
                          disabled={`${props?.singlePersonList?.length > 0 ? true : ""}`}
                          name="employeename"
                          type="text"
                          id="employeename"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="address"
                        className="control-label col-sm-4 required"
                      >
                        Address
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          value={props.address}
                          onChange={(e) =>
                            props.sendValues("address", e.target.value)
                          }
                          rows={3}
                          required="required"
                          disabled={`${props?.singlePersonList?.length > 0 ? true : ""}`}
                          name="address"
                          cols={50}
                          id="address"
                          defaultValue={props.address}
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="nationality"
                        className="control-label col-sm-4 required"
                      >
                        Nationality
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.nationality}
                          onChange={(e) =>
                            props.sendValues("nationality", e.target.value)
                          }
                          required="required"
                          disabled={`${props?.singlePersonList?.length > 0 ? true : ""}`}
                          id="nationality"
                          name="nationality"
                        >
                          <option value selected="selected">
                            === Select ===
                          </option>
                          {props.nationalitiesList.map((nat, idx) => {
                            return (
                              <option key={idx} value={nat.id}>
                                {nat.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="dateofappointment"
                        className="control-label col-sm-4 required"
                      >
                        Date of Appointment
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateAppointment}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateAppointment", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() =>
                              props.sendValues("dateAppointment", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="dateofresignation"
                        className="control-label col-sm-4"
                      >
                        Date of Resignation
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateResignation}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateResignation", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() =>
                              props.sendValues("dateResignation", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="appointment_acra_filed_date"
                        className="control-label col-sm-4 required"
                      >
                        Appointment Acra Filed Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.appointmentAcradate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("appointmentAcradate", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() =>
                              props.sendValues("appointmentAcradate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                        {/* <p className="help-block">* Required </p> */}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="resignation_acra_filed_date"
                        className="control-label col-sm-4 required"
                      >
                        Resignation Acra Filed Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.resignationAcradate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("resignationAcradate", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() =>
                              props.sendValues("resignationAcradate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="dateofbirth"
                        className="control-label col-sm-4 required"
                      >
                        Date of Birth
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateBirth}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateBirth", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() => props.sendValues("dateBirth", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="control-label col-sm-4 required"
                      >
                        Email
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.email}
                          onChange={(e) =>
                            props.sendValues("email", e.target.value)
                          }
                          required="required"
                          name="email"
                          type="email"
                          id="email"
                          defaultValue={props.email}
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="mobile"
                        className="control-label col-sm-4 required"
                      >
                        Phone
                      </label>
                      <div className="col-sm-8">
                        <PhoneInput
                          // className="PhoneInputInput"
                          value={props.phone}
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="SG"
                          onChange={(phone) => props.sendValues("phone", phone)}
                          // onChange={(e) =>
                          //   props.sendValues("phone", e.target.value || undefined)
                          // }
                          required="required"
                          name="mobile"
                          type="tel"
                          id="mobile"
                          defaultValue={props.phone}
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        id="copied"
                        name="copied"
                        type="checkbox"
                        checked={props.copiedStatus}
                        defaultValue={1}
                        onChange={(e) =>
                          props.sendValues("copiedStatus", e.target.checked)
                        }
                      />
                      <label
                        htmlFor="copied"
                        className="control-label col-sm-4"
                      >
                        To place this person in copied
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="copied"
                        name="copied"
                        type="checkbox"
                        checked={props.emailOTPStatus}
                        defaultValue={1}
                        onChange={(e) =>
                          props.sendValues("emailOTPStatus", e.target.checked)
                        }
                      />
                      <label
                        htmlFor="copied"
                        className="control-label col-sm-4"
                      >
                        Email OTP
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="copied"
                        name="copied"
                        type="checkbox"
                        checked={props.smsOTPStatus}
                        defaultValue={1}
                        onChange={(e) =>
                          props.sendValues("smsOTPStatus", e.target.checked)
                        }
                      />
                      <label
                        htmlFor="copied"
                        className="control-label col-sm-4"
                      >
                        SMS OTP
                      </label>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="flag_id"
                        className="control-label col-sm-4 required"
                      >
                        Flag
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.flag}
                          required="required"
                          onChange={(e) =>
                            props.sendValues("flag", e.target.value)
                          }
                          id="flag_id"
                          name="flag_id"
                        >
                          <option value selected="selected">
                            === Select ===
                          </option>
                          {props.flagList.map((fl, idx) => {
                            return (
                              <option key={idx} value={fl.id}>
                                {fl.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="flag_text"
                        className="control-label col-sm-4"
                      >
                        Flag Remarks
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.flagRemark}
                          onChange={(e) =>
                            props.sendValues("flagRemark", e.target.value)
                          }
                          name="flag_text"
                          type="text"
                          id="flag_text"
                          defaultValue={props.flagRemark}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        id="copied_mail"
                        name="copied_mail"
                        type="checkbox"
                        defaultValue={1}
                        onChange={(e) =>
                          props.sendValues(
                            "copiedStatus_value",
                            e.target.checked
                          )
                        }
                      />
                      <label
                        htmlFor="copied"
                        className="control-label col-sm-4"
                      >
                        Do you want to provide the login to the system
                      </label>
                    </div>
                    <div className="form-group">
                      <div
                        className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        id="errortext"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="global_status"
                        className="control-label col-sm-4 required"
                      >
                        Document Upload To
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.documentUpload}
                          required="required"
                          onChange={(e) =>
                            props.sendValues("documentUpload", e.target.value)
                          }
                          id="global_status"
                          name="global_status"
                        >
                          <option value={1}>Global</option>
                          <option value={2}>Local</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="document_link"
                        className="control-label col-sm-4"
                      >
                        Document Link
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.documentLink}
                          onChange={(e) => getfilename(e)}
                          id="document_link"
                          name="document_link"
                        >
                          <option value="" selected="selected">
                            View from the drop-down list / Select New Doc to upload new
                          </option>
                          <option value={0}>New Document</option>
                          {props.alldocumentclientList &&
                            props.alldocumentclientList.map(
                              (filepathresult, index) => {
                                return (
                                  <>
                                    <option
                                      value={
                                        filepathresult.id +
                                        "_" +
                                        filepathresult.document_type +
                                        "_" +
                                        filepathresult.indexno
                                      }
                                    >
                                      {" "}
                                      {filepathresult.file_path
                                        .split("/")
                                        .pop()
                                        .split("?")[0]
                                        .split("#")[0]
                                        .replace(/[/\\?%*:|"<>]/g, "-")}
                                    </option>
                                  </>
                                );
                              }
                            )}
                        </select>

                        <a
                          href=""
                          id="viewdocument"
                          style={{ display: "none" }}
                          target="_blank"
                        >
                          View Selected Document
                        </a>
                      </div>
                    </div>
                    {props.documentLink == "0" ? (
                      <div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_id"
                            className="control-label col-sm-4"
                          >
                            Document ID
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              value={props.documentId}
                              onChange={(e) =>
                                props.sendValues("documentId", e.target.value)
                              }
                              name="document_id"
                              type="text"
                              id="document_id"
                            />
                            <p className="help-block">
                              Must be unique ID with this client{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="original_filename"
                            className="control-label col-sm-4"
                          >
                            Attachment
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                if (e.target.files[0].size <= 2000000) {
                                  props.sendValues(
                                    "attachment",
                                    e.target.files[0]
                                  );
                                } else {
                                  props.sendValues("attachment", "");
                                  e.target.value = "";
                                  props.sendError("clientApiStatus", 2);
                                  props.sendError(
                                    "message",
                                    "File size limit only 2Mb"
                                  );
                                  setTimeout(() => {
                                    props.sendError("clientApiStatus", 0);
                                    props.sendError("message", "");
                                  }, 2500);
                                }
                              }}
                              maxLength={4096}
                              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                              name="original_filename"
                              type="file"
                              id="original_filename"
                            />
                            <p className="help-block">
                              * Uploading of file limited to 2MB only.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_tag"
                            className="control-label col-sm-4"
                          >
                            Description
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              rows={3}
                              name="document_tag"
                              onChange={(e) =>
                                props.sendValues("description", e.target.value)
                              }
                              cols={50}
                              id="document_tag"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="keywords"
                            className="control-label col-sm-4"
                          >
                            Attachment Keyword for Searching Purposes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              rows={3}
                              name="keywords"
                              onChange={(e) =>
                                props.sendValues("documentKey", e.target.value)
                              }
                              cols={50}
                              id="keywords"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="document_type_id"
                            className="control-label col-sm-4"
                          >
                            Document Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              value={props.documentType}
                              id="document_type_id"
                              name="document_type_id"
                              onChange={(e) =>
                                props.sendValues("documentType", e.target.value)
                              }
                            >
                              <option value="">=== Select ===</option>
                              {props.DocumentTypeList.map((val) => {
                                return (
                                  <option value={val.id}>{val.Name}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="document_date"
                            className="control-label col-sm-4"
                          >
                            Resolution/Document Date
                          </label>
                          <div className="col-sm-8">
                            <DatePicker
                              showPopperArrow={false}
                              dateFormat={"dd/MM/yyyy"}
                              selected={props.documentDate}
                              locale={"en"}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "0px, 0px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                              onChange={(Date) => {
                                props.sendValues("documentDate", Date);
                              }}
                            >
                              <div
                                className="trash-icon"
                                onClick={() =>
                                  props.sendValues("documentDate", "")
                                }
                              >
                                <span class="glyphicon glyphicon-trash"></span>
                              </div>
                            </DatePicker>
                            <p className="help-block">DD/MM/YYYY </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_tag"
                            className="control-label col-sm-4"
                          >
                            Notes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              rows={3}
                              name="document_tag"
                              onChange={(e) =>
                                props.sendValues("note", e.target.value)
                              }
                              cols={50}
                              id="document_tag"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    {/* <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div> */}
                    <div className="col-sm-12">
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${
                            props.clientApiStatus === 1 ? "" : "alert-danger"
                          }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      id="addnew"
                      value="new"
                      name="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  onClick={() => {
                    props.sendValues("addPersonal", false);
                    props.sendValues("documentLink", "");
                    props.singleperson_list();
                    document.location.reload()
                  }}
                  className="btn form-control"
                  id="06e42a9e-94ec-4843-80af-7dda812b3cda"
                  data-dismiss="modal"
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    addPersonal,
    idNo,
    idType,
    dateIssue,
    appointmentAcradate,
    resignationAcradate,
    dateExpiry,
    designation,
    status,
    client_id,
    salutation,
    name,
    address,
    nationality,
    dateAppointment,
    dateResignation,
    dateBirth,
    email,
    phone,
    flag,
    flagRemark,
    documentUpload,
    documentLink,
    idList,
    designationList,
    salutationList,
    nationalitiesList,
    flagList,
    documentId,
    attachment,
    documentKey,
    documentType,
    documentDate,
    note,
    description,
    addPersonalStatus,
    officeBearer,
    copiedStatus,
    copiedStatus_value,
    smsOTPStatus,
    emailOTPStatus
  } = state.Personal;
  return {
    addPersonal,
    idNo,
    idType,
    dateIssue,
    appointmentAcradate,
    resignationAcradate,
    dateExpiry,
    designation,
    status,
    client_id,
    salutation,
    name,
    address,
    nationality,
    dateAppointment,
    dateResignation,
    dateBirth,
    email,
    phone,
    flag,
    flagRemark,
    documentUpload,
    documentLink,
    idList,
    designationList,
    salutationList,
    nationalitiesList,
    flagList,
    documentId,
    attachment,
    documentKey,
    documentType,
    documentDate,
    note,
    description,
    addPersonalStatus,
    copiedStatus,
    copiedStatus_value,
    officeBearer,
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeList: state.Settings.DocumentTypeList,
    singlePersonList: state.Client.singlePersonList,
    alldocumentclientList: state.Client.alldocumentclientList,
    List1: state.Client.List1,
    clientIdList: state.Client.clientIdList,
    smsOTPStatus,
    emailOTPStatus
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    singleperson_list: () => dispatch(settings.singleperson_list([])),
    sendValues: (f, v) => dispatch(personal_data(f, v)),
    sendError: (f, v) => dispatch(client.changeClientInfo(f, v)),
    identificationType: () => dispatch(getIdentificationType()),
    getDesignation: () => dispatch(getDesignation()),
    getSalutation: () => dispatch(getSalutation()),
    getNationalities: () => dispatch(getNationalities()),
    getFlag: () => dispatch(getFlag()),
    addPersonalData: (data) => dispatch(addPersonal(data)),
    getDocumentList: () => dispatch(settings.getDocumentClassification()),
    getIdentificationno: (data) => dispatch(settings.getIdentificationno(data)),
    allListDocuments: (data) => dispatch(client.allListDocuments(data)),
    getClientStatus: () => dispatch(client.getClientStatus()),
    getClientIdList: () => dispatch(client.getClientIdList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPersonal);
