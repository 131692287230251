import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import * as actions from "../../../actions/clientDetails/Document";
import * as clientactions from "../../../actions/Client";
import $ from "jquery";
import AddNewDocument from "../modals/DocumentType";
import AddDocumentNew from "../modals/AddDocument";
import CommentUpdate from "../modals/CommentUpdate";
import EgmTransfer from "../modals/EGM-Transfer";
import EgmTransferOfShare from "../modals/TransferOfShares";
import SendRequest from "../modals/SentRequest";
import RejectDocument from "../modals/RejectDocument";
import ChangeStatusDocument from "../modals/ChangeStatusDocument";
import AcceptDocument from "../modals/AcceptDocument";
import Delete from "../modals/DeleteDocument";
import EditDocuementDate from "../modals/EditDocuemtDate"
import Add from "../modals/AddNewDocument";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import ResendRequestModal from "../modals/ResendRequestModal";
var _ = require("lodash");

function Document(props) {
  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });
    if (
      props.tabSelected == "ALL" ||
      props.tabSelected == null ||
      props.tabSelected == undefined
    ) {
      props.getDocumentList();
    } else {
      if (props.tabSelected == "Pending") {
        props.getDocumentList(1);
      } else {
        props.getDocumentList(2);
      }
    }

    // return () => {
    //     table.destroy(true);
    //   };
  }, []);

  // useEffect(()=>{
  //     // if ($.fn.DataTable.isDataTable("#documentTable")) {
  //         $('#documentTable').DataTable().clear().destroy();
  //     //   }
  //     // table.ajax.reload();

  // },[props.tabSelected])

  useEffect(() => {
    function format(d) {
      let approve = "inline-block";
      let words = "<br/>";
      var names = "";
      let idsnewword = "";
      let reject = "inline-block";
      var acno=0,rejno=0,partialno=0,notaccptorrejectno=0;
      // console.log(`Hi` + names);
      // if((d.accepteddata == null || d.accepteddata == "") && (d.paccepteddata == null || d.paccepteddata == "") && (d.rejecteddata == null || d.rejecteddata == "")){
      //   words="No one Accepted Document Yet"
      // }else{
      if ((d.accepteddata != null && d.accepteddata != "")) {
        // names = names + d.accepteddata + " Accepted <br/>"
        var splitname = d.accepteddata.split(",")
        acno=splitname.length;
        var ty = splitname.map(o => o.concat(" Accepted <br/>"))
        names = `${names} ${ty.join(" ")}`
      }
      if ((d.paccepteddata != null && d.paccepteddata != "")) {
        // names = names + d.paccepteddata + " Partially Accepted <br/>"
        var splitname = d.paccepteddata.split(",")
        partialno=splitname.length;
        var ty = splitname.map(o => o.concat(" Partially Accepted <br/>"))
        names = `${names} ${ty.join(" ")}`
      }
      if ((d.rejecteddata != null && d.rejecteddata != "")) {
        // names = names + d.rejecteddata + " Rejected <br/>"
        var splitname = d.rejecteddata.split(",")
        rejno=splitname.length;
        var ty = splitname.map(o => o.concat(" Rejected <br/>"))
        names = `${names} ${ty.join(" ")}`
      }
      if ((d.notagreed != null && d.notagreed != "")) {
        // names = names + d.notagreed + " not Accepted/rejected yet "

        var splitname = d.notagreed.split(",")
        notaccptorrejectno=splitname.length;
        var ty = splitname.map(o => o.concat(" not Accepted/rejected yet <br/>"))
        names = `${names} ${ty.join(" ")}`
      }
      var total=partialno+rejno+notaccptorrejectno+acno;
      // }
      // console.log(":DAtA", d.accepteddata, d.paccepteddata, d.rejecteddata)
      // console.log(":DAtA2", d)
      if (d.stateval == 2) {
        if (d.status == 33) {
          names = "Completed";
          idsnewword = d.ids;
        } else {
          idsnewword = d.id
        }
        if (d.status == 33) {
          return (
            `<tr id="detail-view-${d?.id}">
              <td colspan="7" style="padding:0px;">
                <table id='detail-view-${d?.id}'  style="width:100%" >
                  <tr>
                    <td>Document :  ${idsnewword}</td>
                  </tr>
                  <tr>
                    <td>Document Status :  ${names}</td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </table>
              </td>
            </tr>`
          );
        }else{
          return (
            `<tr id="detail-view-${d?.id}">
              <td colspan="7" style="padding:0px;">
                <table id='detail-view-${d?.id}'  style="width:100%" >
                  <tr>
                    <td>Document :  ${idsnewword}  </td>
                  </tr>
                  <tr>
                    <td>Document Status : ${names}  ${acno}/${total} accpeted</td>
                  </tr>
                  <tr>
                    <td> </td>
                  </tr>
                </table>
              </td>
            </tr>`
          );
        }
       
      } else {
        if (d.status == 33) {
          names = "Completed";
          idsnewword = d.ids;
        } else {
          idsnewword = d.id
        }
        if (d.status == 4) {
          names = d.status_name
          return (
            `<tr id="detail-view-${d?.id}">
              <td colspan="7" style="padding:0px;">
                <table id='detail-view-${d?.id}'  style="width:100%" >
                  <tr>
                    <td>Document : ${idsnewword}  </td>
                  </tr>
                  <tr>
                    <td>Document Status : ${d.status_name} </td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btnChangeStatus btn btn-primary btn-xs"  id=ChangeStatus${d.id} title="ChangeStatus" name=${d.id} style="background-color:green;display:${approve}">Change Status</button>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>`
          );
        } else {
          if (d.status == 33) {
            return (
              `<tr id="detail-view-${d?.id}">
                <td colspan="7" style="padding:0px;">
                  <table id='detail-view-${d?.id}' style="width:100%" >
                    <tr>
                      <td>Document : ${idsnewword}  </td>
                    </tr>
                    <tr>
                      <td>Document Status : <br/> ${names}</td>
                    </tr>
                    <tr>
                      <td>
                        <button class="btnAccept btn btn-primary btn-xs"  id=accept${d.id} title="Accept" name=${d.id} style="background-color:green;display:${approve}">Accept</button>
                        <button class="btnReject btn btn-primary btn-xs"  id=reject${d.id} title="Reject"  name =${d.id} style="background-color:red;display:${reject}"  data-toggle="modal" data-target="#deletemodal"">Reject</button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>`

            );
          }else{
            var partialData = "";
            var alt = "";
            var peralt = "";
            let pacceptedlist = d.pacceptedlist ? JSON.parse(`[${d.pacceptedlist}]`) : "";
            let sharelist = d.sharepercentage ? JSON.parse(`${d.sharepercentage}`) : "";
            console.log("chinnu pacceptedlist", pacceptedlist, "sharelist", sharelist)
            if(d.pacceptedlist){
              pacceptedlist = JSON.parse(`[${d.pacceptedlist}]`);
              let groupedData = _.chain(pacceptedlist)
                .groupBy("name")
                .map((value, key) => ({ name: key, users: value }))
                .value();
              var thz = groupedData.map(o =>{ return `${o.name.concat("<br/>")} ${o.users.map((dh)=> `<li>${dh.agree_name}</li>`).join(" ")}`})
              partialData = `${partialData} ${thz.join("<br/>")}`
              partialData = partialData    
            }
            if(d.agree_list){
              // let al = d.agree_list.split(",")
              console.log("chinnu", pacceptedlist, d.agree_list);
              // let flis = d.sign_list.split(",").filter(o => pacceptedlist && pacceptedlist?.some((obj)=>obj.name != o));
              let accList = d.accepteddata != null && d.accepteddata.split(",");
              let recList = d.rejecteddata != null && d.rejecteddata.split(",");
              let flis = d.sign_list.split(",").filter(o => {
                if(pacceptedlist && !pacceptedlist?.some((obj)=>obj.name === o))
                  return o;
              });
              if(flis.length === 0 && d.notagreed != null){
                console.log("chinnu notagreed if", flis);
                flis = d.notagreed.split(",");
              }
              console.log("chinnu flis", flis);
              alt = d?.agree_list.split(",").map(h => {
                var tlen = pacceptedlist.length > 0 ? pacceptedlist?.filter(k => k.agree_name == h).length : 0;
                tlen = parseInt(tlen) + (accList.length > 0 ? accList.length : 0);
                var ttlen = d?.sign_list.split(",").length;
                var tper = (parseInt(tlen)/parseInt(ttlen)) * 100;
                console.log("chinnu tlen", tlen, ttlen, tper)
                var ttemp =  `<tr>
                  <td>${h}</td>
                  ${d.sign_list.split(",").map(o => {
                    return (
                      `<td>${accList.length > 0 && accList.some(b => b == o) === true? "Accept" : recList.length > 0 && recList.some(b => b == o) === true? "Reject" : flis.some(t => t == o) === true? "No action" : pacceptedlist && pacceptedlist?.some(k => k.agree_name == h && k.name == o) === true ? "Accept" : "Reject"}</td>`
                    )
                  })}
                  <td>${tlen}/${ttlen}</td>
                  <td>${tper > 50 ? "accept": "not accepted"}</td>
                </tr>`
                return ttemp;
              })
              peralt = d?.agree_list.split(",").map(h => {
                var tlen = pacceptedlist.length > 0 ? pacceptedlist?.filter(k => k.agree_name == h) : [];
                if(accList){
                  accList.map(j => tlen.push({name: j}));
                }
                // tlen = parseInt(tlen) + accList.length;
                // var ttlen = d?.sign_list.split(",").length;
                // var ttlen = tlen.filter(o => sharelist && sharelist?.some((obj)=>obj.shareholder == o.name));
                var ttlen = sharelist.length > 0 && sharelist?.filter(o => tlen && tlen?.some((obj)=>o.shareholder == obj.name));
                var perc = ttlen.length > 0 ? ttlen?.map(i=>i.percentage).reduce((a,b)=>a+b, 0) : 0
                var tper = `${perc.toFixed(2)} %`;
                console.log("chinnu tlen",ttlen, sharelist,)

                var ttemp =  `<tr>
                  <td>${h}</td>
                  ${d.sign_list.split(",").map(o => {
                    return (
                      `<td>${accList.length > 0 && accList.some(b => b == o) === true? "Accept" : recList.length > 0 && recList.some(b => b == o) === true? "Reject" : flis.some(t => t == o) === true? "No action" : pacceptedlist && pacceptedlist?.some(k => k.agree_name == h && k.name == o) === true ? "Accept" : "Reject"}</td>`
                    )
                  })}
                  <td>${tper}</td>
                  <td>${perc > 50 ? "accept": "not accepted"}</td>
                </tr>`
                return ttemp;
              })
            }
            console.log("chinndfwfsdfsdfswfwef", peralt)
            return (
              `<tr id="detail-view-${d?.id}">
                <td colspan="7" style="padding:0px;">
                  <table id='detail-view-${d?.id}' style="width:100%" >
                    <tr>
                      <td>Document :  ${idsnewword}  </td>
                    </tr>
                    <tr>
                      <td>Document Status : <br/> ${names} ${acno}/${total} accpeted</td>
                    </tr>
                    <tr>
                      <td> ${partialData}</td>
                    </tr>
                    <tr>
                      <td>
                        <button class="btnAccept btn btn-primary btn-xs"  id=accept${d.id} title="Accept" name=${d.id} style="background-color:green;display:${approve}">Accept</button>
                        <button class="btnReject btn btn-primary btn-xs"  id=reject${d.id} title="Reject"  name =${d.id} style="background-color:red;display:${reject}"  data-toggle="modal" data-target="#deletemodal"">Reject</button> 
                      </td>
                    </tr>
                    <tr> </tr>
                  </table>
                </td>
              </tr>
              <tr id="detail-sign-${d?.id}">
                <td colspan="7">
                  <h3>Sign Percentage</h3>
                  <table class="table table-striped table-hover">
                    <thead>
                      <th>Agenda</th>
                      ${d.sign_list?.split(',').map(f => `<th>${f}</th>`)}
                      <th>Based on signature</th>
                      <th>Status</th>
                    </thead>
                    <tbody>
                    ${alt}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr id="detail-per-${d?.id}">
                <td colspan="7">
                  <h3>Share Percentage</h3>
                  <table class="table table-striped table-hover">
                    <thead>
                      <th>Agenda</th>
                      ${d.sign_list?.split(',').map(f => `<th>${f}</th>`)}
                      <th>Based on Share %</th>
                      <th>Status</th>
                    </thead>
                    <tbody>
                    ${peralt}
                    </tbody>
                  </table>
                </td>
              </tr>`
            );
          }
         
        }

      }


    }
    function formatInline(d) {
      // List 
      // return "chinnu test"
      let approve = "inline-block";
      let words = "<br/>";
      var names = "";
      let idsnewword = "";
      let reject = "inline-block";
      var acno=0,rejno=0,partialno=0,notaccptorrejectno=0;
      if ((d.accepteddata != null && d.accepteddata != "")) {
        // names = names + d.accepteddata + " Accepted <br/>"
        var splitname = d.accepteddata.split(",")
        acno=splitname.length;
        // var ty = splitname.map(o => o.concat(" Accepted"))
        // names = `${names} ${ty.join(" ")}`
      }
      if ((d.paccepteddata != null && d.paccepteddata != "")) {
        // names = names + d.paccepteddata + " Partially Accepted <br/>"
        var splitname = d.paccepteddata.split(",")
        partialno=splitname.length;
        // var ty = splitname.map(o => o.concat(" Partially Accepted <br/>"))
        // names = `${names} ${ty.join(" ")}`
      }
      if ((d.rejecteddata != null && d.rejecteddata != "")) {
        // names = names + d.rejecteddata + " Rejected <br/>"
        var splitname = d.rejecteddata.split(",")
        rejno=splitname.length;
        // var ty = splitname.map(o => o.concat(" Rejected <br/>"))
        // names = `${names} ${ty.join(" ")}`
      }
      if ((d.notagreed != null && d.notagreed != "")) {
        // names = names + d.notagreed + " not Accepted/rejected yet "

        var splitname = d.notagreed.split(",")
        notaccptorrejectno=splitname.length;
        // var ty = splitname.map(o => o.concat(" not Accepted/rejected yet <br/>"))
        // names = `${names} ${ty.join(" ")}`
      }
      var resDt = partialno+rejno+acno;
      var total=partialno+rejno+notaccptorrejectno+acno;
      if (d.stateval === 2) {
        if (d.status === 33) {
          names = "Completed";
          idsnewword = d.ids;
        } else {
          idsnewword = d.id
        }
        if (d.status === 33) {
          return (`Document Status : ${names}`);
        }else{
          return (`${acno+"/"+total+" accpeted"}`);
        }
       
      } else {
        if (d.status === 33) {
          names = "Completed";
          idsnewword = d.ids;
        } else {
          idsnewword = d.id
        }
        if (d.status === 4) {
          names = d.status_name
          return (`Document Status : ${d.status_name}`);
        } else {
          if (d.status === 33) {
            return (`Document Status : ${names}`);
          }else{
            // return (`${acno+"/"+total}`);
            return (`${resDt+"/"+total}`);
          }
        }
      }
    }
    // $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
    //     $($.fn.dataTable.tables(true))
    //         .DataTable()
    //         .columns.adjust()
    //         .responsive.recalc();
    // });
    $.fn.DataTable.ext.pager.numbers_length = 3;

    // if (props.documentList.length > 0) {
    $(document).ready(async function () {
      // console.log("func");
      table = $("#documentTable").DataTable({

        paging: true,
        pageLength: 25,
        lengthMenu: [25, 50],
        autoWidth: true,
        destroy: true,
        dom: '<"top"lBf>rt<"bottom"ip><"clear">',
        data: props.documentList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
          },
          {
            targets: 0,
            orderable: false,
            searching: true,
            // autoWidth: false,

            className: " col-xs-1 col-sm-1 col-md-1 col-lg-1",
            render: function (data, type, row) {
              return `<span>${row.documentType}</span>`;
            },
          },
        ],
        fixedColumns: true,
        dom: "lBfrtip",
        buttons: [
          {
            extend: "collection",
            text: "Export",
            buttons: ["excel", "csv", "pdf", "print"],
          },
        ],
        rowCallback: function (row, data, index) {
          $(row).css("background-color", "#0075b0");
        },
        createdRow: function (row, data, dataIndex) {
          $("td:eq(0)", row).attr("colspan", 8).css("color", "white");
          $("td:eq(1)", row).css("display", "none").css("width", "75px");
          $("td:eq(2)", row).css("display", "none").css("width", "75px");
          $("td:eq(3)", row).css("display", "none").css("width", "75px");
          $("td:eq(4)", row).css("display", "none").css("width", "75px");
          $("td:eq(5)", row).css("display", "none").css("width", "75px");
          $("td:eq(6)", row).css("display", "none").css("width", "75px");
          $("td:eq(7)", row).css("display", "none").css("width", "75px");

        },
      });

      $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
        $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
      });

      table.rows().every(function () {
        var rowdata = this.data();
        var dataChild = rowdata.list;

        this.child(
          dataChild.map((item) => {
            // if(item.attachment !="" && item.attachment !=null && item.attachment !=undefined ){
            //    name = `${item.name}<a><span class="document glyphicon glyphicon-paperclip" /></a>`
            // }else {
            //   name = item.name
            // }
            let chg =
              item.file_path.replace("D:", "") &&
              item.file_path.replace("E:", "");
            let dat = chg.replace(/\\/g, "/");
            let arr = dat.split("/");
            let file = arr[arr.length - 1];
            var spliteddocs = (item.docTypeCode).split(",");
            if (item.status == 33) {

              for (var ival = 0; ival < spliteddocs.length; ival++) {
                var replacetext = (item.file_path).replace(/^.*[\\\/]/, '');
                // console.log("spliteddocs After split", spliteddocs, spliteddocs.length)
                
                return $(
                  `<tr id=${item.id + ival}  >` +
                  `<td id=expand${item.id + ival} title=${item.id + ival} name=${item} class='details-control' >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;` +
                  item.ids +
                  "</td>" +
                  `<td class="path" >` +
                  `<a  style="color:${item.status == 3 ? "#ff0000" : ""};" target="_blank" href="${item.file_path}" >${replacetext}</a>` +
                  "</td>" +
                  `<td class="path" >` +
                  `-` +
                  "</td>" +
                  `<td >` +
                  (() => {
                    if (item.descp != "") {
                      if (item.status == 33) {
                        return `
                        <textarea id="4" class="text-area-class" name="descp_${item.id}" rows="2" cols="30">${item.descp ? item.descp : ""}</textarea>`;
                      } else {
                        return `<textarea id="3" class="text-area-class" name="descp_${item.id}" rows="2" cols="30">${item.descp ? item.descp : ""}</textarea>`;
                      }
  
                    } else {
                      if (item.status == 33) {
                        return `<textarea id="4" class="text-area-class" name="descp_${item.id}" rows="2" cols="30"></textarea>`;
  
                      } else {
                        return `<textarea id="3" class="text-area-class" name="descp_${item.id}" rows="2" cols="30"></textarea>`;
                      }
                    }
                  })() +
                  "</td>" +
                  `<td  >` +
                  (() => {
                    if (item.created_on != null && item.created_on != "") {
                      return moment(item.created_on).format("DD/MM/YYYY");
                    }
                  })() +
                  "</td>" +
                  `<td  >` +
                  (() => {

                    if (item.status == 33) {
                      if (item.globalstatus == 0)
                        return `Global`;
                      else
                        return `Global`;

                    } else {
                      return `Local`;
                    }


                  })() +
                  "</td>" +
                  `<td >` +
                  (() => {
                    if (item.status == 1 || item.status == 5) {
                      return "Pending";
                    } else if (item.status == 2) {
                      return "Received";
                    } else if (item.status == 3) {
                      return "Rejected";
                    } else if (item.status == 4) {
                      return "Approved";
                    } else if (item.status == 6) {
                      return "Send";
                    } else {
                      return "Completed";
                    }
                  })() +
                  "</td>" +
                  `<td >` +
                  (() => {
                    if (item.notes != "") {
                      if (item.status == 33) {
                        return `<textarea id="2" class="text-area-class" name="comment_${item.id}" rows="2" cols="30">${item.notes}</textarea>`;
                      } else {
                        return `<textarea id="1" class="text-area-class" name="comment_${item.id}" rows="2" cols="30">${item.notes}</textarea>`;
                      }

                    } else {
                      if (item.status == 33) {
                        return `<textarea id="2" class="text-area-class" name="comment_${item.id}" rows="2" cols="30">${item.notes}</textarea>`;
                      } else {
                        return `<textarea id="1" class="text-area-class" name="comment_${item.id}" rows="2" cols="30">${item.notes}</textarea>`;          }
                    }
                  })() +
                  "</td>" +
                  "<td  ></td>" +
                  "</tr>"

                  // <button class="btn btn-primary btn-xs"  ><span class="glyphicon glyphicon-resize-small" /></button>
                  //     style="display:${edit}" style="display:${delet}"     <button class="btn btn-primary btn-xs" ><span class="glyphicon glyphicon-record" /></button>
                );

              }

            } else {
              return $(
                `<tr id=${item.id}  >` +
                `<td id=expand${item.id} title=${item.id} name=${item} class='details-control' >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;` +
                item.id +
                "</td>" +
                `<td class="path" >` +
                `<a  style="color:${item.status == 3 ? "#ff0000" : ""};"  target="_blank" href="https://api-gbs.smitiv.co${item.file_path}"  >${item.file_path.split('/').pop()
                }</a>` +
                "</td>" +
                `<td>` +
                // `${formatInline(item)} accpeted`+
                `${formatInline(item)}`+
                "</td>" +
                `<td >` +
                (() => {
                  if (item.descp != "") {
                    if (item.status == 33) {
                      return `
                      <textarea id="4" class="text-area-class" name="descp_${item.id}" rows="2" cols="30">${item.descp ? item.descp : ""}</textarea>`;

                    } else {
                      return `<textarea id="3" class="text-area-class" name="descp_${item.id}" rows="2" cols="30">${item.descp ? item.descp : ""}</textarea>`;
                    }

                  } else {
                    if (item.status == 33) {
                      return `<textarea id="4" class="text-area-class" name="descp_${item.id}" rows="2" cols="30"></textarea>`;

                    } else {
                      return `<textarea id="3" class="text-area-class" name="descp_${item.id}" rows="2" cols="30"></textarea>`;
                    }
                  }
                })() +
                "</td>" +
                `<td  >` +
                (() => {
                  if (item.created_on != null && item.created_on != "") {
                    return moment(item.created_on).format("DD/MM/YYYY");
                  }
                })() +
                "</td>" +
                `<td  >` +
                (() => {
                  if (item.status == 33) {
                    if (item.globalstatus == 0)
                      return `Global`;
                    else
                      return `Global`;

                  } else {
                    return `Local`;
                  }

                })() +
                "</td>" +
                `<td >` +
                (() => {
                  if (item.status == 1 || item.status == 5) {
                    return "Pending";
                  } else if (item.status == 2) {
                    return "Received";
                  } else if (item.status == 3) {
                    return "Rejected";
                  } else if (item.status == 4) {
                    return "Approved";
                  } else if (item.status == 6) {
                    return "Send";
                  } else {
                    return "Completed";
                  }
                })() +
                "</td>" +
                `<td >` +
                (() => {
                  if (item.notes != "") {
                    if (item.status == 33) {
                      return `
                      <textarea id="2" class="text-area-class" name="comment_${item.id}" rows="2" cols="30">${CommentUpdate}</textarea>`;

                    } else {
                      return `<textarea id="1" class="text-area-class" name="comment_${item.id}" rows="2" cols="30">${item.notes}</textarea>`;
                    }

                  } else {
                    if (item.status == 33) {
                      return `<textarea id="2" class="text-area-class" name="comment_${item.id}" rows="2" cols="30">${item.notes}</textarea>`;

                    } else {
                      return `<textarea id="1" class="text-area-class" name="comment_${item.id}" rows="2" cols="30">${item.notes}</textarea>`;
                    }
                  }
                })() +
                "</td>" +
                "<td  >" +
                //Send After Approved
                (() => {
                  if (item.status != 6) {
                    return `<button class="btnEdit btn btn-primary btn-xs" 
                              style="display:${sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(37) ? "inline-block" : "none"}" 
                              id=edit${item.id} title="Edit Resolution Date" data-createdon="${item.created_on}" name=${item.id}  
                              value=${JSON.stringify([item.document_type_id, item.file_path, ])} >
                                <span class="glyphicon glyphicon-pencil" />
                            </button>
                            <button class="btnDel btn btn-primary btn-xs" 
                              style="display:${sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(38) ? "inline-block" : "none"}" 
                              id=delete${item.id} title="Delete"  name =${item.id }  
                              data-toggle="modal" data-target="#deletemodal"">
                                <span class="glyphicon glyphicon-trash" />
                            </button>
                            <button class="btnSend btn btn-primary btn-xs" style="display:${"inline-block"}" id=permission${item} 
                              name=${item.id} title="Send docs with attachments" value=${item.document_type_id}>
                                <span class="glyphicon glyphicon-send">
                            </button>
                            <button class="btnReSend btn btn-primary btn-xs" style="display:${"inline-block"}" id=permission${item} 
                              name=${item.id} title="View Sent docs" value=${item.document_type_id}>
                                <span class="glyphicon glyphicon-share">
                            </button>`;
                  } else {
                    return `<button class="btnEdit btn btn-primary btn-xs" style="display:${sessionStorage.getItem("permissions") &&
                      sessionStorage.getItem("permissions").includes(37)
                      ? "inline-block"
                      : "none"
                      }"  id=edit${item.id} title="Edit Resolution Date"
                      data-createdon="${item.created_on}"
                      name=${item.id
                      } value=${JSON.stringify([
                        item.document_type_id,
                        item.file_path,
                      ])} ><span class="glyphicon glyphicon-pencil" /></button>
                       <button class="btnDel btn btn-primary btn-xs" style="display:${sessionStorage.getItem("permissions") &&
                        sessionStorage.getItem("permissions").includes(38)
                        ? "inline-block"
                        : "none"
                      }"   id=delete${item.id} title="Delete"  name =${item.id
                      }  data-toggle="modal" data-target="#deletemodal""><span class="glyphicon glyphicon-trash" /></button>`;
                  }
                })() +
                "</td>" +
                "</tr>"
                // <button class="btn btn-primary btn-xs"  ><span class="glyphicon glyphicon-resize-small" /></button>
                //     style="display:${edit}" style="display:${delet}"     <button class="btn btn-primary btn-xs" ><span class="glyphicon glyphicon-record" /></button>
              );
            }

          })
        ).show();
      });
      $("#documentTable").on("change", ".text-area-class", function () {
        var comment=this.value;
        var idData=this.name;
        var statuscmt=this.id;
        var doc_id=idData.split("_");
        var result_doc_id=doc_id[1];
        // alert(statuscmt);
        props.saveDocumentNotes(statuscmt,comment,result_doc_id);
      });
      $("#documentTable").on("change", ".text-area-class", function () {
        var descp=this.value;
        var idDataDes=this.name;
        var statusdescp=this.id;
        var des_id=idDataDes.split("_");
        var result_des_id=des_id[1];
        // alert(statusdescp);
        props.saveDocumentDescription(statusdescp,descp,result_des_id);
      });


      $("#documentTable").on("click", ".btnEdit", function () {
        // console.log("valfghjklue", JSON.parse(this.value));
        // let getVal = JSON.parse(this.value);
        // let chg = getVal[1].replace("D:", "") && getVal[1].replace("E:", "");
        // let dat = chg.replace(/\\/g, "/");
        // let arr = dat.split("/");
        // let file = arr[arr.length - 1];

        
        // // let editDoc = props.documentList.filter((item) => item === getVal[0]);
        // props.sendValues("document_id", this.name);
        // // props.sendValues("editDoc", editDoc);
        // props.sendValues("docPreview", file);
        // props.getDefaultPlaceHolder(getVal[0]);
        // props.sendValues("selectDocumentType", getVal[0]);
        // props.sendValues("editDocumentModal", true);
        // props.sendValues("transferOfShareModal", true);

        props.sendValues("document_id", this.name);
        props.sendValues("editDocumentModalDate", true);
        let dt = moment(this.dataset['createdon']).format("YYYY-MM-DD")
        props.sendValues("created_on", dt);
        console.log(dt,"ksdjfaksdjfl")


      });
      $("#documentTable").on("click", ".notes_text", function () {
        var id = this.name;
        var val = $(this).val()
        //props.saveDocumentNotes(1,val,id);
        var text = this.id
        //props.saveDocumentNotes(2,val,id);
        props.changeClientInfo("updated_comment", text);
        props.changeClientInfo("updated_status", 1);
        props.changeClientInfo("updated_doc_id", this.name);
        props.changeClientInfo("document_comment_modal_show", true);



      });
      $("#documentTable").on("click", ".notes_text1", function () {
        var id = this.name;
        var val = $(this).val()
        var text = this.id
        //props.saveDocumentNotes(2,val,id);
        props.changeClientInfo("updated_comment", text);
        props.changeClientInfo("updated_status", 2);
        props.changeClientInfo("updated_doc_id", this.name);

        props.changeClientInfo("document_comment_modal_show", true);
      });
      $("#documentTable").on("click", ".btnSend", function () {
        props.sendValues("document_id", this.name);
        props.sendValues("selectDocumentType", this.value);
        props.sendValues("sendRequestModal", true);
      });

      $("#documentTable").on("click", ".btnReSend", function () {
        console.log("Im clicked btnReSend")
        props.sendValues("document_id", this.name);
        props.sendValues("selectDocumentType", this.value);
        props.sendValues("reSendRequestModal", true);
        props.reSendMail(this.name)
      });
                                                                                                       ;
      
      $("#documentTable").on("click", ".btnDel", function () {
        props.sendValues("document_id", this.name);
        props.sendValues("deleteDocumentModal", true);
      });

      $("#documentTable").on("click", ".btnReject", function () {
        props.sendValues("document_id", this.name);
        props.sendValues("rejectDocumentModal", true);
      });
      $("#documentTable").on("click", ".btnChangeStatus", function () {
        props.sendValues("document_id", this.name);
        props.sendValues("changestatusDocumentModal", true);
      });
      $("#documentTable").on("click", ".btnAccept", function () {
        // console.log("this", this.name);
        props.sendValues("document_id", this.name);
        props.sendValues("acceptDocumentModal", true);
      });

      $("#documentTable").on("click", ".details-control", function (e) {
        let data;
        props.documentList &&
          props.documentList.map((ite, k) => {
            ite.list &&
              ite.list.map((val, idx) => {
                if (val.id == this.title) {
                  data = val;
                }
              });
          });
        var tr = $(this).closest("tr");
        var row = table.row(tr);
        // console.log("tr", row);
        // console.log("rowing", table);

        // if (row.child.isShown()) {
        //   // This row is already open - close it
        //   row.child.hide();
        //   tr.removeClass("shown");
        //   $("tr.shown td.details-control").toggleClass(
        //     "tr.shown td.details-control"
        //   );
        // } else {
        // Open this row

        // document.getElementById(this.title).appendChild($.parseHTML(format(data)))
        // console.log(
        //   "dom",
        //   $(`#detail-view-${this.title}`).length,
        //   tr.hasClass("shown")
        // );
        // if (!$(`#detail-view-${this.title}`).length) {
        //   $(`#${this.title}`).after(format(data));
        //   tr.addClass("shown");
        // } else {
        //   tr.removeClass("shown");
        //   $(`#detail-view-${this.title}`).remove();
        // }

        if (!tr.hasClass("shown") && !$(`#detail-view-${this.title}`).length) {
          if (data && Object.keys(data).length > 0) {
            console.log("hi format called");
            tr.after(format(data));
            tr.addClass("shown");
          }
        } else if (
          !tr.hasClass("shown") &&
          $(`#detail-view-${this.title}`).length
        ) {
          console.log("extra");
          tr.addClass("shown");
          $(`#detail-view-${this.title}`).css("display", "");
          $(`#detail-sign-${this.title}`).css("display", "");
          $(`#detail-per-${this.title}`).css("display", "");
        } else {
          console.log("else");
          tr.removeClass("shown");
          $(`#detail-view-${this.title}`).css("display", "none");
          $(`#detail-sign-${this.title}`).css("display", "none");
          $(`#detail-per-${this.title}`).css("display", "none");

          // $(`#detail-view-${this.title}`).remove();
        }
      });
    });
    // } else {
    //     $('#documentTable').DataTable().clear().destroy();
    //     console.log("datahu", $('#documentTable').DataTable())
    // }
    // $('#documentTable').on( 'keyup', function () {
    //     table
    //         .columns( 3 )
    //         .search( this.value )
    //         .draw();
    // } );
  }, [props.documentList]);

  // console.log("pro", props.documentList);

  return (
    <Fragment>
      <div id="documents-tab" className="tab-pane fade active in">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div
                  className="container-fluid flex-center-right"
                  id="navfluid"
                >
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navigationbar"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#" />
                  </div>
                  <div
                    className="collapse navbar-collapse "
                    id="navigationbar"
                  ></div>

                  <a
                    data-tip data-for="newTemplateTip"
                    className="new-document-link mr-20"
                    onClick={() =>
                      props.sendValues("addNewDocumentModal", true)
                    }
                  >
                    Add New Template
                  </a>
                  <ReactTooltip id="newTemplateTip" place="top" effect="solid">
                    Add New Template
                  </ReactTooltip>
                  <a
                    data-tip data-for="sendDocTip"
                    className="new-document-link mr-20"
                    onClick={() =>
                      props.sendValues("selectDocumentModal", true)
                    }
                  >
                    Send New Document
                  </a>
                  <ReactTooltip id="sendDocTip" place="top" effect="solid">
                    Send Document to Clients
                  </ReactTooltip>
                  <a
                    data-tip data-for="addDocTip"
                    className="new-document-link"
                    onClick={() =>
                      props.changeClientInfo("adddocumentmodel", true)
                    }
                  >
                    Add Document
                  </a>
                  <ReactTooltip id="addDocTip" place="top" effect="solid">
                    Add Document
                  </ReactTooltip>
                </div>
              </nav>
              <div className="box-tools pull-right" />
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="documents_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                <div className="row">
                  <div className="col-sm-6" />
                  <div className="col-sm-6" />
                  <div className="col-sm-6" />
                </div>
                <div className="tabset ">
                  {/* <input
                    type="radio"
                    name="apps"
                    defaultValue="Document1"
                    id="Document"
                    onClick={() => {
                      props.sendValues("tabSelected", "ALL");
                      // props.sendValues("documentList", []);
                      props.getDocumentList();
                    }}
                    aria-controls="Document"
                    defaultChecked={props.tabSelected == "ALL" ? true : false}
                  />
                  <label htmlFor="Document">All Document </label> */}
                  <input
                    type="radio"
                    name="apps"
                    defaultValue="Pending"
                    id="Pending"
                    onClick={() => {
                      props.sendValues("tabSelected", "Pending");
                      // props.sendValues("documentList", []);
                      props.getDocumentList(1);
                    }}
                    aria-controls="Pending"
                    defaultChecked={
                      props.tabSelected == "Pending" ? true : false
                    }
                  />
                  <label htmlFor="Pending">Pending</label>
                  <input
                    type="radio"
                    name="apps"
                    defaultValue="Received"
                    id="Received"
                    onClick={() => {
                      props.sendValues("tabSelected", "Received");
                      // props.sendValues("documentList", []);
                      props.getDocumentList(2);
                    }}
                    aria-controls="Received"
                    defaultChecked={
                      props.tabSelected == "Received" ? true : false
                    }
                  />
                  <label htmlFor="Received">Completed</label>
                  <input
                    type="radio"
                    name="apps"
                    defaultValue="Uploaded"
                    id="Uploaded"
                    onClick={() => {
                      props.sendValues("tabSelected", "Uploaded");
                      // props.sendValues("documentList", []);
                      props.getDocumentList(3);
                      // $("#documents_processing").DataTable().column(2).visible(false);
                      // $("#documentTable").DataTable().column(2).visible(false);
                    }}
                    aria-controls="Uploaded"
                    defaultChecked={
                      props.tabSelected == "Uploaded" ? true : false
                    }
                  />
                  <label htmlFor="Uploaded">Uploaded</label>
                  <div className="tab-panels">
                    <section
                      className="tab-panel mt-30"
                      style={{ display: "block" }}
                    >
                      <div className="row">
                        <div className="col-sm-12">

                          <table
                            className="table table-striped table-bordered col-md-12 dataTable no-footer"
                            role="grid"
                            style={{ marginLeft: 0, padding: 0 }}
                            // className="table table-striped"
                            id="documentTable"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="col-xs-2 col-md-2 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="documents"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "43px" }}
                                  aria-label="Doc. ID: activate to sort column ascending"
                                >
                                  Doc. ID
                                </th>
                                <th
                                  className="col-xs-2 col-md-2 col-lg-2 sorting_asc"
                                  tabIndex={0}
                                  aria-controls="documents"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "163px" }}
                                  aria-label="Filename: activate to sort column ascending"
                                >
                                  Filename
                                </th>
                                <th
                                  className="col-xs-2 col-md-2 col-lg-2 sorting"
                                  tabIndex={0}
                                  aria-controls="documents"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "126px" }}
                                  aria-label="Category: activate to sort column ascending"
                                >
                                  Action Taken By
                                </th>
                                <th
                                  className="col-xs-2 col-md-2 col-lg-2 sorting"
                                  tabIndex={0}
                                  aria-controls="documents"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "126px" }}
                                  aria-label="Category: activate to sort column ascending"
                                >
                                  Description
                                </th>
                                <th
                                  className="col-xs-2 col-md-2 col-lg-1 sorting"
                                  tabIndex={0}
                                  aria-controls="documents"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "44px" }}
                                  aria-label="Res. Date: activate to sort column ascending"
                                >
                                  Res. Date
                                </th>
                                <th
                                  className="col-xs-2 col-md-2 col-lg-1 sorting_desc"
                                  tabIndex={0}
                                  aria-controls="documents"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "44px" }}
                                  aria-label="Fin. Year: activate to sort column ascending"
                                >
                                  Global/Local
                                </th>
                                <th
                                  className="col-xs-2 col-md-4 col-lg-1 sorting_disabled"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "94px" }}
                                  aria-label="Keyword"
                                >
                                  Status
                                </th>
                                <th
                                  className="col-xs-2 col-md-4 col-lg-2 sorting_disabled"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "127px" }}
                                  aria-label="Notes"
                                >
                                  Comments
                                </th>
                                <th
                                  className="col-xs-2 col-md-2 col-lg-1 sorting_disabled"
                                  rowSpan={1}
                                  colSpan={1}
                                  style={{ width: "45px" }}
                                  aria-label="Actions"
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>

                            </tbody>
                          </table>

                        </div>
                      </div>
                    </section>
                    <div
                      id="documents_processing"
                      className="dataTables_processing panel panel-default"
                      style={{ display: props.processModal }}
                    >
                      Processing...
                    </div>
                    {/* <section className="tab-panel"></section> */}
                  </div>
                </div>
                {/* <div className="row">
                                    <div className="col-sm-5" />
                                    <div className="col-sm-7" />
                                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="box-footer clearfix no-border"></div> */}
        </div>
        <p />
      </div>
      <AddNewDocument />
      <AddDocumentNew />
      <CommentUpdate />
      <EgmTransfer />
      <EgmTransferOfShare />
      <SendRequest />
      <RejectDocument />
      <ChangeStatusDocument />
      <AcceptDocument />
      <Add />
      <Delete />
      <EditDocuementDate/>
      <ResendRequestModal/>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { tabSelected, documentList, reSendRequestModal } = state.Document;

  return {
    tabSelected,
    documentList,
    reSendRequestModal,
    processModal: state.Client.processModal,
    document_comment_modal_show: state.Client.document_comment_modal_show,
    updated_comment: state.Client.updated_comment,
    updated_status: state.Client.updated_status,
    updated_doc_id: state.Client.updated_doc_id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    reSendMail: (id) => dispatch(actions.reSendMail(id)),
    getDocumentList: (val) => dispatch(actions.getDocumentList(val)),
    saveDocumentNotes: (status, notes, documentid) => dispatch(actions.saveDocumentNotes(status, notes, documentid)),
    saveDocumentDescription: (status, descp, documentid) => dispatch(actions.saveDocumentDescription(status, descp, documentid)),
    getDefaultPlaceHolder: (type) =>
      dispatch(actions.getDefaultPlaceHolder(type)),
    changeClientInfo: (field, value) =>
      dispatch(clientactions.changeClientInfo(field, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
