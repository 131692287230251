import React, { Fragment, useEffect, useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import { connect } from "react-redux";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from "moment";
import {
  deadline,
  getDeadlineList,
  getReminderType,
} from "../../actions/clientDetails/Deadline";
import * as personalActions from "../../actions/clientDetails/Personal";
import * as client from "../../actions/Client";
import * as settings from "../../actions/Settings";
import AddDeadline from "../client/modals/AddDeadline";
import Nothing from "../client/modals/NothingSelected";
import EditDeadline from "../client/modals/EditDeadline";
import NewTask from "../client/modals/newTask";
import EditTask from "../client/modals/EditTask";
import Approve from "../client/modals/ApproveDeadline";
import ExportByCompany from "../client/modals/ExportByCompany";
import ExportByDate from "../client/modals/ExportByDate";
import Delete from "../client/modals/DeleteDeadline";
import Attachment from "../client/modals/AttachmentModal";
import "../../styles/CommenStyle.css";
var _ = require("lodash");

function Deadlinesummary(props) {


  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });
    props.getPersonalList();
    setTimeout(() => { props.getDeadlineList(); }, 100)
    props.getClientList();
    // props.getTaskSettingsList()
    props.getReminderType();


    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(52)) {
      setEdit("inline-block")
    };

    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(53)) {
      setDelet("inline-block")
    };


  }, []);



  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");
  const [task, setTask] = useState(true);


  var table;

  useEffect(() => {
    let flagValue = props.filterFlag;
    let value = _(props.deadlineList)
      .groupBy((x) => flagValue === "incompletebydate" ? x.deadline_date : x.client_id)
      .map((value, key) => ({ name: key, lists: value }))
      .sortBy((o) => o.name)
      .value();

    // console.log("vlue", value)
    function format(d) {
      let array;
      if (d.tasks.length != 0) {
        array = d.tasks;
      } else {
        array = [];
      }

      table.on("click", "button.expandEdit", function () {
        console.log("edit");
        var data = table.row(this).data();
        array.map((val, idx) => {
          if (val.id == this.name) {
            props.clientdeadline &&
              props.clientdeadline.map((dead) => {
                if (val.task_service_id == dead.id) {
                  props.sendValues("taskDeadline", dead.id);
                }
              });
            if (new Date(val.start_date) != "Invalid Date") {
              props.sendValues("taskStartDate", new Date(val.start_date));
            }
            if (new Date(val.end_date) != "Invalid Date") {
              props.sendValues("taskEndDate", new Date(val.end_date));
            }
            if (new Date(val.deadline_date) != "Invalid Date") {
              props.sendValues("taskDeadlineDate", new Date(val.deadline_date));
            }
            if (new Date(val.financial_year) != "Invalid Date") {
              props.sendValues(
                "taskFinancialYear",
                new Date(val.financial_year)
              );
            }
            
            props.sendValues("client_dedadline_id", val.client_dedadline_id);
            props.sendValues("taskDrop", val.task_service_id);
            props.sendValues("taskNotes", val.notes);
            props.sendValues("taskAssigned", val.user_id);
            props.sendValues("taskStatus", val.status);
            props.sendValues("taskStartTime", val.start_time);
            props.sendValues("taskEndTime", val.end_time);
            props.sendValues("editTask", true);
            props.sendValues("task_id", val.id);
          }
        });
      });

      table.on("click", "button.expandDel", function () {
        console.log("del");
        var data = table.row(this).data();
        props.sendValues("task_id", this.name);
        props.sendValues("deleteModal", true);
        props.sendValues("taskDelete", true);
      });


      table.on("click", "button.expandsub", function () {
        props.clientdeadline.map((cli) => {
          if (cli.id == d.client_dedadline_task_id) {
            props.sendValues("deadlineName", cli.Name);
          }
          props.clientList && props.clientList.map((val) => {
            array.map((row) => {
              if (val.id == row.client_id) {
                sessionStorage.setItem("selectedClient", val.company_name)
              }
            })
          })
          props.sendValues("taskDeadlineDate", d.deadline_date);
          props.sendValues("taskFinancialYear", d.financial_year);
          props.sendValues("deadline_id", d.id);
          props.sendValues("newTask", true);
          props.sendValues("subTask", true);
        });
      });



      return (
        `<tr id="detail-view-${d?.id}"><td  colspan="8" style="padding:0px"><table id="detail-view-${d?.id}" cellpadding="7" cellspacing="0" border="0" style="padding-left:50px;width:100%"  >` +
        "<tr>" +
        "<th>Task</th>" +
        "<th>Start</th>" +
        "<th>Finish</th>" +
        "<th>Status</th>" +
        "<th>Assigned</th>" +
        "<th>Notes</th>" +
        "<th>Actions</th>" +
        `</tr>` +
        array.map((val) => {
          let user = "";
          let status = "";
          let task = "";
          props.personalList &&
            props.personalList.map((per) => {
              if (per.id == val.user_id) {
                user = per.name;
              }
            });
          if (val.status == 1) {
            status = "Open";
          } else {
            status = "Completed";
          }

          props.clientdeadline &&
            props.clientdeadline.map((dead) => {
              if (val.task_service_id == dead.id) {
                task = dead.Name;
              }
            });

          if (array.length != 0) {
            return (
              "<tbody>" +
              "<tr>" +
              `<td>${task}</td>` +
              `<td>${moment(val.start_date).format("DD/MM/YYYY")}</td>` +
              `<td>${moment(val.end_date).format("DD/MM/YYYY")}</td>` +
              `<td>${status}</td>` +
              `<td>${user}</td>` +
              `<td>${val.notes}</td>` +
              `<td><button class="expandEdit btn btn-primary btn-xs" id=edit${val.id} title="Edit" name=${val.id}  ><span class="glyphicon glyphicon-pencil" /></button>
      <button class="expandDel btn btn-primary btn-xs" id=delete${val.id} title="Delete"  name=${val.id} onclick="deleteFunc()"  ><span class="glyphicon glyphicon-trash" /></button>
      <button class="expandsub btn btn-primary btn-xs" id=newtask${val.id} title="Add Sub Task" name=${val.id}  ><span class="glyphicon glyphicon-tasks" /></button></td>` +
              "</tr>" +
              "</tbody>"
            );
          } else {
            return "<tr>No Data</tr>";
          }
        }) +
        "</table></td></tr>"
      );
    }
  //   $(document).on( 'init.dt', function ( e, settings ) {
  //     var api = new $.fn.dataTable.Api( settings );
  //     window.setTimeout(function () {
  //             api.table().columns.adjust().draw();
  //         },1);
  // } );

    $(function () {
      table = $("#Deadline-summary").DataTable({
        
        pageLength:25,
        lengthMenu: [ 25, 50 ],
        paging: true,
        destroy: true,
     
        data: value,
        "order": [[ 1, "desc" ]],
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
          },
          {
            targets: 0,
            width: 200,
            orderable: true,
            searchable: true,
            className: " col-lg-1 col-md-1 col-sm-1 col-xs-1",
            render: function (data, type, row) {
              let company_name = '';
              if(flagValue === "incompletebydate"){
                company_name = moment(row.name).format("DD/MM/YYYY")
              }else{
                props.clientList && props.clientList.map((val) => {
                  if (val.id == row.name) {
                    company_name = val.company_name
                  }
                })
              }
              
              return `<span/>${company_name}</span>`;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#A9A9A9");
          }
        },
        createdRow: function (row, data, dataIndex) {
          $("td:eq(0)", row).attr("colspan", 8).css("color", "white");
          $("td:eq(1)", row).css("display", "none").css("width", "75px");
          $("td:eq(2)", row).css("display", "none").css("width", "75px");
          $("td:eq(3)", row).css("display", "none").css("width", "75px");
          $("td:eq(4)", row).css("display", "none").css("width", "75px");
          $("td:eq(5)", row).css("display", "none").css("width", "75px");
          $("td:eq(6)", row).css("display", "none").css("width", "75px");
          $("td:eq(7)", row).css("display", "none").css("width", "75px");
          $("td:eq(8)", row).css("display", "none").css("width", "75px");
          
        },
      });


      $('a[data-toggle="tab"]').on('shown.bs.tab', function(e){
        $($.fn.dataTable.tables(true)).DataTable()
           .columns.adjust();
     }); 



      table.rows().every(function () {
        var rowdata = this.data();
        var dataChild = rowdata.lists;

        this.child(
          dataChild.map((item) => {
            let com_sts = "";
            let dead = "";
            let clr = '';
            if (item.is_completed == 1) {
              com_sts = "completed";
            } else {
              com_sts = "incompleted";
            }
            (() => {
              props.clientdeadline.map((val) => {
                if (val.id == item.client_dedadline_task_id) {
                  if (item.docids != '' && item.docids != null && item.docids != undefined) {
                    dead = `${val.Name}<a class="document"><span class="document glyphicon glyphicon-paperclip" /></a>`
                  }else {
                    dead = val.Name  
                  }
                  }
                });
            })();

            if (item.is_approved == 0) {
              clr = '#f44242'
            } else {
              // clr = '<span style="margin-left: 30px;">---</span>';
            }

            return $(
              `<tr style="background-color:${clr};width:${"100%"};text-decoration:${com_sts == 'completed'? 'line-through':'none'}">` +
              `<td id="expand"${item.id} title=${item.id} class="details-control">` +
              "</td>" +
              "<td class='btnbox'>" +
              `${(() => {
                if (item.check == true) {
                  return `<input class="check" id=check${item.id}  title=${item.id} name=""  checked type="checkbox" />`;
                } else {
                  return `<input class="check" id=check${item.id} title=${item.id} name=${item.id}   type="checkbox" />`;
                }
              })()}` +
              "</td>" +
              "<td >" +
              `${flagValue === 'incompletebydate' ? item.company_name : moment(item.deadline_date).format("DD/MM/YYYY")}` +
              "</td>" +
              "<td >" +
              moment(item.financial_year).format("DD/MM/YYYY") +
              "</td>" +
              "<td >" +
              dead +
              "</td>" +
              "<td >" +
              `${(() => {
                if (
                  moment(item.date_submitted).format("DD/MM/YYYY") !=
                  "Invalid date"
                ) {
                  return moment(item.date_submitted).format("DD/MM/YYYY");
                } else {
                  return '<span style="margin-left: 30px;">---</span>';
                }
              })()}` +
              "</td>" +
              "<td >" +
              com_sts +
              "</td>" +
              "<td  >" +
                `<button class="btnEdit btn btn-primary btn-xs" style="display:${edit}" id=edit${item.id} title="Edit" name = ${item.id} onClick="console.log('Hello',title)" ><span class="glyphicon glyphicon-pencil" /></button>
                <button class="btnDel btn btn-primary btn-xs" style="display:${delet}" id=delete${item.id} title="Delete"  name =${item.id}  data-toggle="modal" data-target="#deletemodal""><span class="glyphicon glyphicon-trash" /></button>
                <button class="btnTask btn btn-primary btn-xs"  id=newtask${item.id} title="New Task"  name=${item.id}  ><span class="glyphicon glyphicon-tasks" /></button>` +
              "</td>" +
              "</tr>"
            );
          })
        ).show();
      });
      // `<button class="btnEdit btn btn-primary btn-xs" style="display:${edit}" id=edit${item.id} title="Edit" name = ${item.id} onClick="console.log('Hello',title)" ><span class="glyphicon glyphicon-pencil" /></button>
      // <button class="btnDel btn btn-primary btn-xs" style="display:${delet}" id=delete${item.id} title="Delete"  name =${item.id}  data-toggle="modal" data-target="#deletemodal""><span class="glyphicon glyphicon-trash" /></button>
      // <button class="btnTask btn btn-primary btn-xs"  id=newtask${item.id} title="New Task"  name=${item.id}  ><span class="glyphicon glyphicon-tasks" /></button>` +
     

     
      $("#Deadline-summary").on("click", ".btnEdit", function () {
        var data = table.row(this).data();
        props.deadlineList &&
          props.deadlineList.map((val, idx) => {
            if (val.id == this.name) {
              if (val.is_start_reminder == 1) {
                props.sendValues("clickStart", true);
              } else {
                props.sendValues("clickStart", false);
              }
              props.sendValues("client_id", val.client_id);

              if (val.is_completed == 1) {
                props.sendValues("isCompleted", true);
              } else {
                props.sendValues("isCompleted", false);
              }

              props.sendValues("clientDeadline", val.client_dedadline_task_id);
              props.sendValues("frequency", val.frequency);
              if (val.financial_year !== "Invalid Date" && val.financial_year !== "") {
                props.sendValues("finYear", new Date(val.financial_year));
              }
               if (val.remainder_date !== "Invalid Date" && val.remainder_date !== "") {
                props.sendValues("remainderDate", new Date(val.remainder_date));
              }
              if (val.deadline_date !== "Invalid Date" && val.deadline_date !== "") {
                props.sendValues("deadlineDate", new Date(val.deadline_date));
              }
              if (
                val.receipent_to != null &&
                val.receipent_to != undefined &&
                val.receipent_to != ""
              ) {
                props.sendValues("to", JSON.parse(val.receipent_to));
              }
              if (
                val.receipent_cc != null &&
                val.receipent_cc != undefined &&
                val.receipent_cc != ""
              ) {
                props.sendValues("cc", JSON.parse(val.receipent_cc));
              }
              props.sendValues("remType", val.reminder_type_id);
              props.sendValues("remark", val.remarks);
              props.sendValues("docLink", val.document_link);
              props.sendValues("docId", val.document_id);
              // props.sendValues("attachment", val.attachment);
              props.sendValues("attachmentKey", val.attachment_keyword);
              props.sendValues("docType", val.document_type);
              if (new Date(val.document_date) != "Invalid Date") {
                props.sendValues("docDate", new Date(val.document_date));
              }
              props.sendValues("notes", val.notes);
              props.sendValues("editDeadlineModal", true);
              props.sendValues("deadline_id", val.id);
              if (val.date_submitted !== "0000-00-00" &&  val.date_submitted !== null && val.date_submitted !== "Invalid Date") {
                props.sendValues("submitDate", new Date(val.date_submitted));
              }
            }
          });
      });

      $("#Deadline-summary").on("click", ".document", function () {
        props.setValue("attachmentModal", true)

      });

      $("#Deadline-summary").on("click", ".btnTask", function () {
        var data = table.row(this).data();
        props.deadlineList &&
          props.deadlineList.map((val, idx) => {
            if (val.id == this.name) {
              props.sendValues("client_id", val.client_id);
              props.clientdeadline && props.clientdeadline.map((cli) => {
                if (cli.id == val.client_dedadline_task_id) {
                  props.sendValues("deadlineName", cli.Name);
                }
                props.clientList && props.clientList.map((row) => {
                  if (row.id == val.client_id) {
                    sessionStorage.setItem("selectedClient", row.company_name)
                  }
                })
                if (new Date(val.deadline_date) != "Invalid Date") {
                  props.sendValues("taskDeadlineDate", val.deadline_date);
                };
                if (new Date(val.financial_year) != "Invalid Date") {
                  props.sendValues("taskFinancialYear", val.financial_year);
                }
                props.sendValues("deadline_id", this.name);
                props.sendValues("newTask", true);
              });
            }
          });
      });

      $("#Deadline-summary").on("click", ".btnDel", function () {
        var data = table.row(this).data();
        props.sendValues("deadline_id", this.name);
        props.sendValues("deleteModal", true);
      });

      $("#Deadline-summary").on("click", ".btnbox", function (e) {
        var data = table.row(this).data();
        // let arr = [...props.value];
        console.log("target", e.target.name)
        let ind;
        value &&
          value.map((val, idx) => {
            val.lists.map((key) => {
              if (key.id == e.target.title) {
                key.check = e.target.checked
              }
            });
          });
        // props.sendValues("deadlineList", arr);
        if (e.target.name != "" && e.target.name != undefined && e.target.checked == true) {
          props.sendValues("deadline_id", e.target.name);
        } else {
          props.sendValues("deadline_id", "");
        }
      });

      // Add event listener for opening and closing details
      $("#Deadline-summary tbody").on("click", ".details-control", function () {
        let data;
        props.deadlineList &&
          props.deadlineList.map((val, idx) => {
            if (val.id == this.title) {
              data = val;
            }
          });
        var tr = $(this).closest("tr");
        var row = table.row(tr);
        // if (row.child.isShown()) {
        //   // This row is already open - close it
        //   row.child.hide();
        //   tr.removeClass("shown");
        //   $("tr.shown td.details-control").toggleClass(
        //     "tr.shown td.details-control"
        //   );
        // } else {
        //   row.child(format(data)).show();
        //   tr.addClass("shown");
        // }

        // if(!$(`#detail-view-${this.title}`).length) {
        //   $(`#${this.title}`).after(format(data));
        //   tr.addClass("shown");
        // } else {
        //   tr.removeClass("shown");
        //   $(`#detail-view-${this.title}`).remove();
        // }
        if (!tr.hasClass("shown") && !$(`#detail-view-${this.title}`).length) {
          console.log("remove");
          if (data && Object.keys(data).length > 0) {
            tr.after(format(data));
            tr.addClass("shown");
          }
        } else if (
          !tr.hasClass("shown") &&
          $(`#detail-view-${this.title}`).length
        ) {
          console.log("add");
          tr.addClass("shown");
          $(`#detail-view-${this.title}`).css("display", "");
        } else {
          console.log("else");
          tr.removeClass("shown");
          $(`#detail-view-${this.title}`).css("display", "none");
          // $(`#detail-view-${this.title}`).remove();
        }
      });
    });
    
    $(document).ready(function() {
      $('a[data-toggle="tab"]').on( 'shown.bs.tab', function (e) {
          // var target = $(e.target).attr("href"); // activated tab
          // alert (target);
          // $($.fn.dataTable.tables( true ) ).css('width', '100%');
          $($.fn.dataTable.tables( true ) ).DataTable().columns.adjust().draw();
      } ); 
  });
  }, [props.deadlineList, props.clientdeadline]);

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div
          className="content-wrapper"
          id="content-window"
          style={{ minHeight: "607px" }}
        >
          <section className="content">
            {props.clientApiStatus ? (
              <div
                className={`alert ${props.clientApiStatus === 1 ? "alert-success" : ""
                  }`}
                role="alert"
              >
                {props.clientApiStatus === 1 ? props.message : ''}
              </div>
            ) : (
              ""
            )}
            <div
              className="box box-primary box_align_left"
              style={{ maxHeight: "100vh" }}
            >
              <div className="box-header">
                {" "}
                <i className="glyphicon glyphicon-list-alt" />
                <h3 className="box-title">Deadlines</h3>
                <div>
                  <nav className="navbar navbar-default" role="navigation">
                    <div className="container-fluid" id="navfluid">
                      <div className="navbar-header">
                        <button
                          type="button"
                          className="navbar-toggle"
                          data-toggle="collapse"
                          data-target="#navigationbar"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle navigation</span>
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                        </button>
                        <a className="navbar-brand" href="#" />
                      </div>
                      <div
                        className="collapse navbar-collapse"
                        id="navigationbar"
                      >
                        <ul className="nav navbar-nav">
                          <ul className="nav navbar-nav">
                            <li>
                              <a
                                href="#"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Export
                                <span className="caret" />
                              </a>
                              <ul className="dropdown-menu">
                                <li>
                                  <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => {
                                      props.sendValues("exportByCompanyModal", true);
                                    }}
                                  >
                                    Export XLS - All by Company
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="menuitem"
                                    tabIndex={-1}
                                    onClick={() => {props.sendValues("exportByDateModal", true);}}
                                  >
                                    Export XLS - Incomplete by Date
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <ul className="nav navbar-nav">
                              <li>
                                <a
                                  href="#"
                                  className="dropdown-toggle"
                                  data-toggle="dropdown"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  Filter
                                  <span className="caret" />
                                </a>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a href="#" 
                                      onClick={(e) => {
                                        e.preventDefault()
                                        props.sendValues("filterFlag","showall")
                                        props.getDeadlineList("flag", "showall");
                                      }}
                                    >
                                      Show All
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" 
                                      onClick={(e) => {
                                        e.preventDefault()
                                        props.sendValues("filterFlag","completed")
                                        props.getDeadlineList("flag", "completed");
                                      }}
                                    >
                                      Completed Deadline
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" 
                                      onClick={(e) => {
                                        e.preventDefault()
                                        props.sendValues("filterFlag","incomplete")
                                        props.getDeadlineList("flag", "incomplete");
                                      }}
                                    >
                                      Incomplete Deadline
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" 
                                      onClick={(e) => {
                                        e.preventDefault()
                                        props.sendValues("filterFlag","incompletebydate")
                                        props.getDeadlineList("flag", "incompletebydate");
                                      }}
                                    >
                                      Incomplete by Date
                                    </a>
                                  </li>
                                  <div
                                    className="dropdown-submenu"
                                    id="demoemnu"
                                  >
                                    <a
                                      tabIndex={-1}
                                      href="#"
                                      className="dropbtn"
                                      data-toggle="dropdown"
                                      role="button"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {" "}
                                      <span className="nav-label">
                                        Deadlines
                                      </span>
                                      <span className="caret" />
                                    </a>
                                    <div
                                      className="dropdown-content"
                                      id="demoitem"
                                    >
                                      {
                                        props.clientdeadline && props.clientdeadline.map(p => 
                                          <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            let txt = props.filterFlag === "showall" ? p.id : 
                                              `${p.id}&flag=${props.filterFlag}` 
                                            props.getDeadlineList("dtask", txt);
                                          }}>
                                            {p.Name} <br />
                                          </a>
                                        )
                                      }
                                    </div>
                                  </div>
                                </ul>
                              </li>
                              <ul className="nav navbar-nav">
                                <li>
                                  <a
                                    href="#"
                                    className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Approvals
                                    <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu">
                                    {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(55) ? (
                                      <li>
                                        <a
                                          className="button-approval"
                                          onClick={() => {
                                            if (props.deadline_id !== "") {
                                              props.sendValues(
                                                "approveModal",
                                                true
                                              );
                                            } else {
                                              props.setFieldValues(
                                                "nothing_select",
                                                true
                                              );
                                            }
                                          }}
                                        >
                                          Approve
                                      </a>
                                      </li>
                                    ) : null}
                                  </ul>
                                </li>
                              </ul>
                            </ul>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </nav>
                  <div className="box-tools pull-right" />
                </div>
                <div />
              </div>
              <div className="box-body" style={{ minHeight: "100vh" }}>
              <div
                        id="client-deadline_processing"
                        className="dataTables_processing panel panel-default"
                        style={{ display: props.processModal}}
                      >
                        Processing...
                      </div>
                <div className="table-responsive">
                  
                  <div className="row">
                    <div className="col-sm-12">
                     
                            <table
                              className="table table-striped table-bordered col-md-12 dataTable no-footer"
                              role="grid"
                              style={{ marginLeft: 0,padding:0}}
                              id="Deadline-summary"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className=" col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                                    rowSpan={1}
                                    colSpan={1}
                                    style={{ width: "50px" }}
                                    aria-label
                                  />
                                  <th
                                    className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                                    rowSpan={1}
                                    colSpan={1}
                                    style={{ width: "50px" }}
                                    aria-label="Select"
                                  >
                                    Select
                                  </th>
                                  <th
                                    className="col-xs-2 col-md-3 col-lg-3 sorting_desc"
                                    tabIndex={0}
                                    aria-controls="client-deadline"
                                    rowSpan={1}
                                    colSpan={1}
                                    style={{ width: "232px" }}
                                    aria-label="Deadline Date: activate to sort column ascending"
                                  >
                                    {props.filterFlag === "incompletebydate" ? "Company Name" : "Deadline Date"}
                                  </th>
                                  <th
                                    className="col-xs-1 col-md-1 col-lg-1 sorting"
                                    tabIndex={0}
                                    aria-controls="client-deadline"
                                    rowSpan={1}
                                    colSpan={1}
                                    style={{ width: "51px" }}
                                    aria-label="Finanical Year: activate to sort column ascending"
                                  >
                                    Finanical Year
                                  </th>
                                  <th
                                    className="col-xs-1 col-md-1 col-lg-1 sorting"
                                    tabIndex={0}
                                    aria-controls="client-deadline"
                                    rowSpan={1}
                                    colSpan={1}
                                    style={{ width: "51px" }}
                                    aria-label="Deadline: activate to sort column ascending"
                                  >
                                    Deadline
                                  </th>
                                  <th
                                    className="col-xs-1 col-md-1 col-lg-1 sorting"
                                    tabIndex={0}
                                    aria-controls="client-deadline"
                                    rowSpan={1}
                                    colSpan={1}
                                    style={{ width: "57px" }}
                                    aria-label="Date Submitted: activate to sort column ascending"
                                  >
                                    Date Submitted
                                  </th>
                                  <th
                                    className="col-xs-1 col-md-1 col-lg-1 sorting_disabled"
                                    rowSpan={1}
                                    colSpan={1}
                                    style={{ width: "60px" }}
                                    aria-label="Completed"
                                  >
                                    Completed
                                  </th>
                                  <th
                                    className="col-xs-2 col-md-2 col-lg-2 sorting_disabled"
                                    rowSpan={1}
                                    colSpan={1}
                                    style={{ width: "143px" }}
                                    aria-label="Actions"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                            </table>
                                              
                    </div>
                  </div>
                  
                 
                </div>
               
              </div>
              
              <div className="box-footer clearfix no-border"></div>
              
            </div>
          </section>
      
          
        </div>
       
        <Footer />
      </div>
      {/* ./wrapper */}
      <div id="myModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
              <h4 className="modal-title">Title</h4>
            </div>
            <div className="modal-body">Loading ...</div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      <AddDeadline />
      <EditDeadline />
      <Approve />
      <ExportByCompany />
      <ExportByDate />
      <Nothing />
      <Delete />
      <NewTask />
      <EditTask />
      <Attachment />
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { deadlineList, deadline_id, clientdeadline,filterFlag } = state.Deadline;
  return {
    filterFlag,
    deadlineList,
    deadline_id,
    clientdeadline,
    personalList: state.Personal.personalList,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    clientList: state.Client.clientList,
    processModal:state.Client.processModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(deadline(k, v)),
    getDeadlineList: (f=null, v=null) => dispatch(getDeadlineList({f, v})),
    setFieldValues: (f, v) => dispatch(personalActions.personal_data(f, v)),
    getPersonalList: () => dispatch(personalActions.getPersonalList()),
    getClientList: (data) => dispatch(client.getClientList(data)),
    getTaskSettingsList: () => dispatch(settings.getTaskSettingsList()),
    getReminderType: () => dispatch(getReminderType()),
    setValue: (f, v) => dispatch(client.changeClientInfo(f, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deadlinesummary);
