import { combineReducers } from "redux";
import { CompanySetups } from "./CompanySetups";
import { Login } from "./Login";
import { Client } from "./Client";
import { Common } from "./Common";
import { Personal } from "./clientDetails/Personal";
import { ShareReg } from "./clientDetails/ShareRegister";
import {Charges} from "./clientDetails/Charges";
import {Deadline} from './clientDetails/Deadline';
import {Settings} from './Settings';
import {Remainder} from './Remainder';
import {CompleteDeadline} from './clientDetails/CompleteDeadline';
import {UserDatas} from './UserManagement';
import {Profile} from './Profile';
import {Document} from './clientDetails/Document';



export default combineReducers({
  CompanySetups,
  Login,
  Client,
  Common,
  Personal,
  ShareReg,
  Charges,
  Deadline,
  Settings,
  Remainder,
  CompleteDeadline,
  UserDatas,
  Profile,
  Document,
});
