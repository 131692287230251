import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Document";
import * as settings from "../../../actions/Settings";
import { changeClientInfo } from "../../../actions/Client";
import $, { valHooks } from "jquery";
function AddDocumentSection(props) {
  useEffect(() => {
    //props.getDocumentTypeList();
    props.getDocumentList();
  }, []);
  const [documentCategoryField, setdocumentCategoryField] = useState("");
  const getalltypesbyid=(id)=>{
    props.changeClientInfo("selectedid", id);
    props.getDocumentListbyid(id);
  };
  console.log(props.selectedid,"selectedid")
  const saveDoc =()=>{
    var categoryname=$("#categoryname").val();
    var data={categoryname:categoryname};
    props.adddocumentcat(data);
    window.location.reload();
  
  }
  return (
    <Modal
      show={props.selectaddDocumentModal1}
      id="myModal"
      className="modal fade in"
      style={{ display: "block" }}
    >
      <div className="modal-content wd-100">
        <div className="modal-header text-center">
          {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> */}
          <h4 className="modal-title blue-text">Add Document Category</h4>
        </div>
        <div className="modal-body wd-100">
          {props.clientApiStatus ? (
            <div
              className={`alert ${
                props.clientApiStatus === 1 ? "" : "alert-danger"
              }`}
              role="alert"
            >
              {props.clientApiStatus === 1 ? "" : props.message}
            </div>
          ) : (
            ""
          )}
          <div className="wd-70">
          <div className="form-group">
              <label
                htmlFor="Document"
                className="control-label col-sm-4 required"
              >
                Document Category
              </label>
              <div className="col-sm-8">
              <input type="text" id="categoryname" className="form-control"/>
              </div>
            </div>
           
            <div className="button-wrapper mt-70 wd-100">
              <button
                className="btn gray-border-btn  "
                onClick={() => props.sendValues("selectaddDocumentModal1", false)}
              >
                Cancel
              </button>
              <button className="btn blue-btn ml-10" onClick={saveDoc}>
                save
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { selectaddDocumentModal1, documentTypeList, selectDocumentType } =
    state.Document;

  return {
    selectaddDocumentModal1,
    documentTypeList,
    selectDocumentType,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeListnew: state.Settings.DocumentTypeListnew,
    selectedid: state.Client.selectedid,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentList: () => dispatch(settings.getDocumentClassification_new()),
    getDocumentListbyid: (data) => dispatch(settings.getDocumentClassificationbyid(data)),
    getDocumentTypeList: () => dispatch(actions.getDocumentTypeList()),
    sendDocTypeAndGetDocument: (type) =>
      dispatch(actions.sendDocTypeAndGetDocument(type)),
      getDefaultPlaceHolder:(type) =>
      dispatch(actions.getDefaultPlaceHolder(type)),
    changeClientInfo: (k, d) => dispatch(changeClientInfo(k, d)),
    adddocumentcat: (data) => dispatch(actions.adddocumentcat(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentSection);
