import React, { Fragment, useEffect } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import $ from "jquery";
import { connect } from "react-redux";
import "../../styles/CommenStyle.css";
import Personal from "./clientList/Personal";
import Charges from "./clientList/Charges";
import Deadline from "./clientList/Deadlines";
import Document from "./clientList/Document";
import Remainder from "./clientList/Remainder";
import Resolution from "./clientList/Resolution";
import ShareRegister from "./clientList/ShareRegister";
import CompleteDeadline from "./clientList/CompleteDeadline";
import * as actions from "../../actions/Client";
import * as settings from "../../actions/Settings";

function Clientlist(props) {
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });
    props.allListDocuments(sessionStorage.getItem("personalId"))
  }, []);


  
  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div
          className="content-wrapper"
          id="content-window"
          style={{ minHeight: "607px" }}
        >
          <section className="content">
            {props.clientApiStatus ? (
              <div
                className={`alert ${
                  props.clientApiStatus === 1 ? "alert-success" : ""
                }`}
                role="alert"
              >
                {props.clientApiStatus === 1 ? props.message : ''}
              </div>
            ) : (
              ""
            )}
            <div>
              <div className="tab-content col-lg-12 col-lg">
                <ul className="nav nav-tabs" id="myTab">
                {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(18) ? (
                  <li
                    className={props.pageSelected == "personal" ? "active" : ""}
                  >
                    <a
                      data-toggle="pill"
                      onClick={() =>
                        props.setFieldvalues("pageSelected", "personal")
                      }
                    >
                      <i className />
                      Personal
                    </a>
                  </li>
                ):null}
                {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(23) ? (
                  <li className={props.pageSelected == "Share" ? "active" : ""}>
                    <a
                      data-toggle="pill"
                      onClick={() =>
                        props.setFieldvalues("pageSelected", "Share")
                      }
                    >
                      <i className />
                      Share Register
                    </a>
                  </li>
                ):null}
                {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(26) ? (
                  <li
                    className={
                      props.pageSelected == "Resolution" ? "active" : ""
                    }
                  >
                    <a
                      data-toggle="pill"
                      onClick={() =>
                        props.setFieldvalues("pageSelected", "Resolution")
                      }
                    >
                      <i className />
                      Resolutions for Share-register{" "}
                    </a>
                  </li>
                ):null}
                {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(30) ? (
                  <li
                    className={props.pageSelected == "charges" ? "active" : ""}
                  >
                    <a
                      data-toggle="pill"
                      onClick={() =>
                        props.setFieldvalues("pageSelected", "charges")
                      }
                    >
                      <i className />
                      Charges{" "}
                    </a>
                  </li>
                ):null}
                {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(35) ? (
                  <li
                    className={
                      props.pageSelected == "documents" ? "active" : ""
                    }
                  >
                    <a
                      data-toggle="pill"
                      onClick={() =>
                        props.setFieldvalues("pageSelected", "documents")
                      }
                    >
                      <i className />
                      Documents{" "}
                    </a>
                  </li>
                ):null}
                {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(39) ? (
                  <li
                    className={props.pageSelected == "deadline" ? "active" : ""}
                  >
                    <a
                      data-toggle="pill"
                      onClick={() =>
                        props.setFieldvalues("pageSelected", "deadline")
                      }
                    >
                      <i className />
                      Deadlines
                    </a>
                  </li>
                ):null}
                {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(44) ? (
                  <li
                    className={
                      props.pageSelected == "CompleteDeadline" ? "active" : ""
                    }
                  >
                    <a
                      data-toggle="pill"
                      onClick={() =>
                        props.setFieldvalues("pageSelected", "CompleteDeadline")
                      }
                    >
                      <i className />
                      Completed Deadline
                    </a>
                  </li>
                ):null}
                {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(45) ? (
                  <li
                    className={
                      props.pageSelected == "remainder" ? "active" : ""
                    }
                  >
                    <a
                      data-toggle="pill"
                      onClick={() =>
                        props.setFieldvalues("pageSelected", "remainder")
                      }
                    >
                      <i className />
                      Reminders
                    </a>
                  </li>
                ):null}
                </ul>
                {(() => {
                  if (props.pageSelected == "personal") {
                    return <Personal />;
                  } else if (props.pageSelected == "Share") {
                    return <ShareRegister />;
                  } else if (props.pageSelected == "Resolution") {
                    return <Resolution />;
                  } else if (props.pageSelected == "charges") {
                    return <Charges />;
                  } else if (props.pageSelected == "documents") {
                    return <Document />;
                  } else if (props.pageSelected == "deadline") {
                    return <Deadline />;
                  } else if (props.pageSelected == "remainder") {
                    return <Remainder />;
                  } else if (props.pageSelected == "CompleteDeadline") {
                    return <CompleteDeadline />;
                  }
                })()}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* ./wrapper */}
      <div id="myModal" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
              <h4 className="modal-title">Title</h4>
            </div>
            <div className="modal-body">Loading ...</div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      <div
        className="modal bootstrap-dialog type-primary fade size-normal "
        role="dialog"
        aria-hidden="true"
        id="confirmapprove"
        aria-labelledby="confirmapprove"
        tabIndex={-1}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="bootstrap-dialog-header">
                <div
                  className="bootstrap-dialog-close-button"
                  style={{ display: "none" }}
                >
                  <button className="close" aria-label="close">
                    ×
                  </button>
                </div>
                <div
                  className="bootstrap-dialog-title"
                  id="104fed43-4c83-4895-add8-9df518a9f9c3_title"
                >
                  Confirmation
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="bootstrap-dialog-body">
                <div className="bootstrap-dialog-message">
                  Proceed with Approval?
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="bootstrap-dialog-footer">
                <div className="bootstrap-dialog-footer-buttons">
                  <button
                    className="btn btn-default"
                    id="4997aa0e-751a-455d-8bac-07e55d9d7e2c"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button className="btn btn-default" id="btn-confirm">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal bootstrap-dialog type-primary fade size-normal "
        role="dialog"
        aria-hidden="true"
        id="editdeadline"
        aria-labelledby="editdeadline"
        tabIndex={-1}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bootstrap-dialog-draggable">
              <div className="bootstrap-dialog-header">
                <div
                  className="bootstrap-dialog-close-button"
                  style={{ display: "none" }}
                >
                  <button className="close" aria-label="close">
                    ×
                  </button>
                </div>
                <div
                  className="bootstrap-dialog-title"
                  id="ec746694-f16c-429a-ab2b-83b613d285d5_title"
                >
                  Edit Deadline
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div className="bootstrap-dialog-body">
                  <form
                    method="POST"
                    action="https://crm2.gbsc.geneasol-apps.info/client/clients/489/deadlines/2961"
                    acceptCharset="UTF-8"
                    encType="multipart/form-data"
                    id="form"
                    className="form-horizontal"
                  >
                    <input name="_method" type="hidden" defaultValue="PUT" />
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                    />
                    <div className="form-group">
                      <label
                        htmlFor="financialyear"
                        className="control-label col-sm-4 required"
                      >
                        Financial Year
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control datetimepicker"
                          style={{ backgroundColor: "#fff" }}
                          autoComplete="off"
                          required="required"
                          name="financialyear"
                          type="text"
                          defaultValue="30/09/2017"
                          id="financialyear"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="deadlinedate"
                        className="control-label col-sm-4 required"
                      >
                        Deadline Date
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control datetimepicker"
                          style={{ backgroundColor: "#fff" }}
                          autoComplete="off"
                          required="required"
                          name="deadlinedate"
                          type="text"
                          defaultValue="30/11/2018"
                          id="deadlinedate"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="datesubmitted"
                        className="control-label col-sm-4"
                      >
                        Date Submitted
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control datetimepicker"
                          style={{ backgroundColor: "#fff" }}
                          autoComplete="off"
                          name="datesubmitted"
                          type="text"
                          defaultValue="06/12/2018"
                          id="datesubmitted"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="description"
                        className="control-label col-sm-4"
                      >
                        Remarks
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          rows={3}
                          name="description"
                          cols={50}
                          id="description"
                          defaultValue={"Form C YA 2018 e-filed"}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div
                        className="icheckbox_flat-blue"
                        aria-checked="false"
                        aria-disabled="false"
                      >
                        <input
                          id="is_completed"
                          onChange="validationcheck('is_completed', 'errortext', '/client/clients/489-2961/deadlines/taskstatus')"
                          name="is_completed"
                          type="checkbox"
                          defaultValue={1}
                          style={{ position: "absolute", opacity: 0 }}
                        />
                        <ins
                          className="iCheck-helper"
                          style={{
                            position: "absolute",
                            top: "0%",
                            left: "0%",
                            display: "block",
                            width: "100%",
                            height: "100%",
                            margin: "0px",
                            padding: "0px",
                            background: "rgb(255, 255, 255)",
                            border: "0px",
                            opacity: 0,
                          }}
                        />
                        <p className="help-block">* Required </p>
                      </div>
                      <label
                        htmlFor="is_completed"
                        className="control-label col-sm-4"
                      >
                        Completed
                      </label>
                    </div>
                    <div className="form-group">
                      <div
                        className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        id="errortext"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="tolist[]"
                        className="control-label col-sm-4"
                      >
                        To
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          multiple="multiple"
                          onchange="component(['tolist\[\]','cclist\[\]'], '/api/deadline-reminder')"
                          id="tolist[]"
                          name="tolist[]"
                        >
                          <option value={1783}>
                            DATHATHRI BELUR SRINIVASA SHET
                          </option>
                          <option value={1805}>
                            ACCLETRADE TECHNOLOGIES PTE. LTD.
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="cclist[]"
                        className="control-label col-sm-4"
                      >
                        CC
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          multiple="multiple"
                          id="cclist[]"
                          name="cclist[]"
                        >
                          <option value={1783}>
                            DATHATHRI BELUR SRINIVASA SHET
                          </option>
                          <option value={1805}>
                            ACCLETRADE TECHNOLOGIES PTE. LTD.
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="durationtype"
                        className="control-label col-sm-4 required"
                      >
                        Frequency
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          required="required"
                          id="durationtype"
                          name="durationtype"
                        >
                          <option value={1}>Daily</option>
                          <option value={2}>Weekly</option>
                          <option value={3}>Monthly</option>
                          <option value={4}>Yearly</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="template_id"
                        className="control-label col-sm-4 required"
                      >
                        Reminder Type
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          required="required"
                          id="template_id"
                          name="template_id"
                        >
                          <option value={1}>
                            ACRA &amp; ECI Filing-1st reminder
                          </option>
                          <option value={2}>IRAS Filing - </option>
                          <option value={3}>TEST TEMPLATE</option>
                          <option value={4}>
                            ACRA &amp; ECI Filing-5th reminder
                          </option>
                          <option value={5}>
                            ACRA &amp; ECI Filing-2nd reminder
                          </option>
                          <option value={6}>
                            ACRA &amp; ECI Filing-3rd reminder
                          </option>
                          <option value={7}>
                            ACRA &amp; ECI Filing-4th reminder
                          </option>
                          <option value={8}>GST FILING</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <div
                        className="icheckbox_flat-blue"
                        aria-checked="false"
                        aria-disabled="false"
                      >
                        <input
                          id="isstopped"
                          onchange="component(['isstopped'], '/api/deadline-reminder-selection')"
                          name="isstopped"
                          type="checkbox"
                          defaultValue={1}
                          style={{ position: "absolute", opacity: 0 }}
                        />
                        <ins
                          className="iCheck-helper"
                          style={{
                            position: "absolute",
                            top: "0%",
                            left: "0%",
                            display: "block",
                            width: "100%",
                            height: "100%",
                            margin: "0px",
                            padding: "0px",
                            background: "rgb(255, 255, 255)",
                            border: "0px",
                            opacity: 0,
                          }}
                        />
                      </div>
                      <label
                        htmlFor="isstopped"
                        className="control-label col-sm-4"
                      >
                        Click to Start Reminder
                      </label>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="document_link"
                        className="control-label col-sm-4"
                      >
                        Document Link
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          onChange="component(['document_link'], '/api/document-dropdown')"
                          id="document_link"
                          name="document_link"
                        >
                          <option defaultValue selected="selected">
                          View from the drop-down list / Select New Doc to upload new
                          </option>
                          <option value={0}>New Document</option>
                          <option value={559}>
                            SIGNED RESOS.pdf - 2018-02-18 - RESIGNATION OF
                            RAJESH
                          </option>
                          <option value={1674}>
                            Form C-S FILED YA 2019.pdf
                          </option>
                          <option value={386}>
                            ALLOTMENT OF SHARES.pdf - 2017-11-28 - ALLOTMENT OF
                            SHARES
                          </option>
                          <option value={632}>
                            SIGNED RESOS_RESIGNATION_VIKAS.pdf - 2018-03-29 -
                            VIKAS_RESIGNATION
                          </option>
                          <option value={1048}>
                            FORM C YA 2018 e-filed.pdf - 2018-12-07 - Form C YA
                            2018 e-filed
                          </option>
                          <option value={492}>
                            SIGNED RESOS_APPT OF DR.pdf - 2018-01-19 - APPT OF
                            DR-NAINA
                          </option>
                          <option value={348}>
                            EGM_APPT OF AUDITOR.pdf - 2017-11-03 - APPT OF
                            AUDITOR
                          </option>
                          <option value={558}>
                            SIGNED RESOS.pdf - 2018-02-18 - RESIGNATION OF
                            RAJESH
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group hide">
                      <label
                        htmlFor="document_id"
                        className="control-label col-sm-4"
                      >
                        Document ID
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          name="document_id"
                          type="text"
                          id="document_id"
                        />
                        <p className="help-block">
                          Must be unique ID with this client{" "}
                        </p>
                      </div>
                    </div>
                    <div className="form-group hide">
                      <label
                        htmlFor="original_filename"
                        className="control-label col-sm-4"
                      >
                        Attachment
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          maxLength={4096}
                          accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                          name="original_filename"
                          type="file"
                          id="original_filename"
                        />
                        <p className="help-block">
                          * Uploading of file limited to 2MB only.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="form-group hide">
                      <label
                        htmlFor="keywords"
                        className="control-label col-sm-4"
                      >
                        Attachment Keyword for Searching Purposes
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          rows={3}
                          name="keywords"
                          cols={50}
                          id="keywords"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="form-group hide">
                      <label
                        htmlFor="document_type_id"
                        className="control-label col-sm-4"
                      >
                        Document Type
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          id="document_type_id"
                          name="document_type_id"
                        >
                          <option value selected="selected">
                            === Select ===
                          </option>
                          <option value={1}>Secreterial</option>
                          <option value={2}>Others</option>
                          <option value={3}>Financial statement</option>
                          <option value={4}>Tax computation</option>
                          <option value={5}>GST</option>
                          <option value={6}>CERTIFICATE OF RESIDENCE </option>
                          <option value={7}>PERSONAL TAXES</option>
                          <option value={8}>Vds</option>
                          <option value={9}>xyz</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group hide">
                      <label
                        htmlFor="document_date"
                        className="control-label col-sm-4"
                      >
                        Resolution/Document Date
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control datetimepicker"
                          style={{ backgroundColor: "#fff" }}
                          autoComplete="off"
                          name="document_date"
                          type="text"
                          id="document_date"
                        />
                        <p className="help-block">DD/MM/YYYY </p>
                      </div>
                    </div>
                    <div className="form-group hide">
                      <label
                        htmlFor="document_tag"
                        className="control-label col-sm-4"
                      >
                        Notes
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          rows={3}
                          name="document_tag"
                          cols={50}
                          id="document_tag"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="selectedtolist"
                        type="hidden"
                        defaultValue
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="selectedcclist"
                        type="hidden"
                        defaultValue
                      />
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      id="button"
                      value="update"
                      name="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control"
                  id="7af0c56b-c3f6-4ab6-a487-62e2d934d524"
                  data-dismiss="modal"
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal bootstrap-dialog type-primary fade size-normal "
        role="dialog"
        aria-hidden="true"
        id="deletedeadline"
        aria-labelledby="deletedeadline"
        tabIndex={-1}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="bootstrap-dialog-header">
                <div
                  className="bootstrap-dialog-close-button"
                  style={{ display: "none" }}
                >
                  <button className="close" aria-label="close">
                    ×
                  </button>
                </div>
                <div
                  className="bootstrap-dialog-title"
                  id="1d440a62-e34a-4ca1-9259-beadeadea7d3_title"
                >
                  Confirm Delete
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="bootstrap-dialog-body">
                <div className="bootstrap-dialog-message">
                  Confirm to delete Form C YA 2018 e-filed?
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="bootstrap-dialog-footer">
                <div className="bootstrap-dialog-footer-buttons">
                  <button
                    className="btn form-control btn-danger"
                    id="a2bf198e-564f-46f7-9969-cee092baaa2d"
                  >
                    <span className="bootstrap-dialog-button-icon glyphicon glyphicon-check" />
                    Delete
                  </button>
                  <button
                    className="btn form-control"
                    id="c7f02962-d633-4ca8-bf0a-b1afa5b11b41"
                    data-dismiss="modal"
                  >
                    <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    alldocumentclientList: state.Client.alldocumentclientList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    allListDocuments: (data) => dispatch(actions.allListDocuments(data)),
    setFieldvalues: (f, v) => dispatch(actions.changeClientInfo(f, v)),
    getDocumentList:()=> dispatch(settings.getDocumentClassification()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clientlist);
