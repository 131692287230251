import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Document";
import * as settings from "../../../actions/Settings";
import { changeClientInfo } from "../../../actions/Client";

function ChangeStatusDocument(props) {
  useEffect(() => {
    // props.getDocumentStatus(1)

  }, []);

  const rejectFunction = () => {
    if (props.reject_reason != "") {
    props.documentStatusUpdate({
        document_id: props.document_id,
        changedstatus: props.reject_reason,
      },props.tabSelected);
    } else {
      props.changeClientInfo("clientApiStatus", 2);
      props.changeClientInfo("message", "Please Select Changed Status");
      setTimeout(() => {
        props.changeClientInfo("clientApiStatus", 0);
        props.changeClientInfo("message", "");
      }, 2500);
    }
  };
console.log("documentList2",props.documentList2)
  return (
    <Modal
      show={props.changestatusDocumentModal}
      id="myModal"
      className="modal fade in"
      style={{ display: "block" }}
    >
      <div className="modal-content wd-100">
        <div className="modal-header text-center">
          {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> */}
          <h4 className="modal-title blue-text">Update Status</h4>
        </div>
        <div className="modal-body wd-100">
          {props.clientApiStatus ? (
            <div
              className={`alert ${
                props.clientApiStatus === 1 ? "alert-success" : "alert-danger"
              }`}
              role="alert"
            >
              {props.message}
            </div>
          ) : (
            ""
          )}
          <div className="wd-80 m-auto">
            <div className="form-group">
              <label htmlFor="Document" className="control-label required">
               Select Status To Update
              </label>
              <select
                              className="form-control"
                              value={props.documentType}
                              id="document_type_id"
                              name="document_type_id"
                              onChange={(e) =>
                                props.sendValues("reject_reason", e.target.value)
                              }
                            >
            { props.documentList2 && props.documentList2.map((val) => {
                return (
                  <option value={val.id}>{val.status_name}</option>
                )
              })}
            </select>
                {/* onChange={(e) => {
                  props.sendValues("reject_reason", e.target.value);
                }} */}
            </div>
            <div className="button-wrapper mt-20 wd-100">
              <button
                className="btn gray-border-btn"
                onClick={() => props.sendValues("changestatusDocumentModal", false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  rejectFunction(e);
                }}
              >
                Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { changestatusDocumentModal, documentList2,document_id, reject_reason } = state.Document;

  return {
    changestatusDocumentModal,
    documentList2,
    document_id,
    reject_reason,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    tabSelected: state.Document.tabSelected,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentTypeList: () => dispatch(actions.getDocumentTypeList()),
    getDocumentStatus: (data) =>
      dispatch(actions.getDocumentStatus(data)),
      documentStatusUpdate: (value,tab) => dispatch(actions.documentStatusUpdate(value,tab)),
    sendDocTypeAndGetDocument: (type) =>
      dispatch(actions.sendDocTypeAndGetDocument(type)),
    getDefaultPlaceHolder: (type) =>
      dispatch(actions.getDefaultPlaceHolder(type)),
    changeClientInfo: (k, d) => dispatch(changeClientInfo(k, d)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusDocument);
