const init = {
    docs : []
  };
  
  export const Common = (state = init, action) => {
    switch (action.type) {
      case "S3_DOCS":
        return { ...state, docs: action.data };
      default:
        return state;
    }
  };
  