import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
// import * as actions from "../../../../actions/clientDetails/ShareRegister";
import * as actions from "../../../actions/clientDetails/ShareRegister";

function DeleteResolution(props) {
  useEffect(() => { }, []);

  return (
    <Modal
      show={props.deleteShareModal}
      className="modal bootstrap-dialog type-primary fade size-normal in"
      role="dialog"
      aria-hidden="true"
      id="Confirmpersonal"
      aria-labelledby="Confirmpersonal"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="3113c4e9-48b3-4ff7-9296-8d60c502dc72_title"
              >
                Confirm Delete
              </div>
            </div>
          </div>
          <div className="modal-body">
            {props.clientApiStatus ? (
              <div
                className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                  }`}
                role="alert"
              >
                {props.clientApiStatus === 1 ? "" : props.message}
              </div>
            ) : (
              ""
            )}

            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">Confirm to delete this action record?</div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control btn-danger"
                  id="bac1d4e1-aded-4b83-b6da-85088ce2ae55"
                  onClick={() => props.deleteResolutionById(props.shareId, props.resolutionDate, props.editAction)}
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-check" />
                  Delete
                </button>
                <button
                  className="btn form-control"
                  id="d3d45ab4-88e6-44b8-b897-f967887fdaf2"
                  onClick={() => {
                    props.sendValues("deleteShareModal", false)
                    props.sendValues("shareId", '');
                  }}
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    deleteShareModal: state.ShareReg.deleteShareModal,
    shareId: state.ShareReg.shareId,
    resolutionDate: state.ShareReg.resolutionDate,
    editAction: state.ShareReg.editAction,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (f, v) => dispatch(actions.share_register(f, v)),
    deleteResolutionById: (id, rdate, actionType) => dispatch(actions.deleteResolutionById(id, rdate, actionType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteResolution);
