import config from "./Api_Links";
import { headers } from "./Headers";
import { ifTokenExpire } from "./TokenValidation";

export const S3_Docs = (data) => {
  return {
    type: "S3_DOCS",
    data,
  };
};

export const s3_file_upload = (data) => {
  var header = headers();
  console.log("clie", data);
  const { company_id, client_id, attachment, active_tab } = data;
  let formData = new FormData();
  formData.append("company_id", company_id);
  formData.append("upload_file", attachment);
  if (client_id && active_tab) {
    formData.append("client_id", client_id);
    formData.append("active_tab", active_tab);
  }

  //   return (dispatch) => {
  return fetch(config.document_upload_to_s3, {
    method: "POST",
    body: formData,
    headers: { Authorization: header.Authorization },
  })
    .then((response) => {
      if (response) {
        return response.json();
      }
    })
    .then((responseData) => {
      if (responseData) {
        ifTokenExpire(responseData);
        if (responseData.status === 1) {
          return responseData;
        } else {
          return responseData;
        }
      }
    });
  //   };
};

export const uploadFile = (event) => (dispatch) =>{
  var header = headers();
  const formData = new FormData();
  const fileData = event.target.files;
  for (let key in fileData) {
    formData.append("upload_file", fileData[key]);
  }
  return fetch(config.s3_upload_docs, {
    method: "POST",
    body: formData,
    headers: { Authorization: header.Authorization },
  })
    .then((response) => {
      if (response) {
        return response.json();
      }
    })
    .then((responseData) => {
      if (responseData) {
        ifTokenExpire(responseData);
        if (responseData.status === 1) {
          console.log("ResponseData", responseData)
          dispatch(S3_Docs(responseData.ResponseData));
          return responseData.ResponseData;
        } else {
          return responseData;
        }
      }
    });
};
